import AbstractStoreService from "@/store/framework/AbstractStoreService";
import { CookieBannerState } from "@/store/modules/cookieBanner/cookieBannerState";
import { RootState } from "@/store/RootState";
import { CookieBannerGetters } from "@/store/modules/cookieBanner/cookieBannerGetters";
import { CookieBannerAction } from "@/store/modules/cookieBanner/cookieBannerActions";

export class CookieBannerStoreService extends AbstractStoreService<CookieBannerState, RootState, CookieBannerGetters> {
  setVisible(value: boolean): Promise<void> {
    return this.dispatch(CookieBannerAction.setVisible, value);
  }

  getVisible(): boolean {
    return this._get("getVisible");
  }

  setNotificationVisible(value: boolean): Promise<void> {
    return this.dispatch(CookieBannerAction.setNotificationVisible, value);
  }

  getNotificationVisible(): boolean {
    return this._get("getNotificationVisible");
  }
}
