import { resolveComponent as _resolveComponent, mergeProps as _mergeProps, resolveDirective as _resolveDirective, createVNode as _createVNode, withDirectives as _withDirectives, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4e451e02"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "custom-overlay" }
const _hoisted_2 = { class: "d-flex align-self-center ma-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_mobile_attendee_card = _resolveComponent("mobile-attendee-card")!
  const _directive_click_outside = _resolveDirective("click-outside")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _withDirectives(_createVNode(_component_mobile_attendee_card, _mergeProps({
        attendee: _ctx.attendee,
        style: {"max-width":"calc(100vw - 270px)"}
      }, _ctx.$attrs), null, 16, ["attendee"]), [
        [_directive_click_outside, {
          handler: _ctx.unselectAttendee,
          closeConditional: _ctx.shouldUnselectAttendee
        }]
      ])
    ])
  ]))
}