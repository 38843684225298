
import { defineComponent } from "vue";
import UserPreview from "@/ui/components/user-preview/UserPreview.vue";
import { UserInfo } from "@/model/user-info";
import { cookieBannerStoreService, userStoreService, venueStoreService } from "@/store/module-services";
import { UserService } from "@/services/user-service";
import TrackingService, { ProfileEvent, ProfileType } from "@/services/tracking-service";

export default defineComponent({
  components: { UserPreview },
  computed: {
    user(): UserInfo | undefined {
      return userStoreService.getUser();
    },
    currentAreaId(): string {
      return this.$route.params.areaId as string;
    },
    cookieBannerEnabled(): boolean {
      return venueStoreService.isCookieBannerEnabled();
    }
  },
  methods: {
    async logOut() {
      await UserService.logOut();
    },
    async openCookieBanner(): Promise<void> {
      await cookieBannerStoreService.setVisible(true);
    },
    async openUserProfile(): Promise<void> {
      TrackingService.trackProfileEvent(this.currentAreaId, ProfileType.UserProfile, ProfileEvent.OpenProfile);
      await userStoreService.openProfileForCurrentUser();
    }
  }
});
