
import { defineComponent } from "vue";
import PresenceWidget from "@/ui/components/presence-widget/PresenceWidget.vue";
import { presenceStoreService, userStoreService } from "@/store/module-services";
import { AttendeeRef } from "@/model/attendee-ref";
import MobilePresenceWidget from "@/ui/components/presence-widget/MobilePresenceWidget.vue";
import startChatWith from "@/utility/start-chat-with";
import TrackingService, { ProfileType, ProfileEvent } from "@/services/tracking-service";
import { UserService } from "@/services/user-service";
import { DebouncedValue } from "@/utility/debounced-value";
import { MIN_ATTENDEE_UPDATE_INTERVAL } from "@/ui/components/presence-widget/constants";

export default defineComponent({
  emits: ["update:active", "update:ready", "select-attendee"],
  components: { MobilePresenceWidget, PresenceWidget },
  props: {
    mobile: {
      type: Boolean,
      default: false
    },
    selectedAttendeeId: { type: String }
  },
  data() {
    return {
      searchTerm: null as string | null,
      areaFilter: false,
      companyFilter: false,
      debouncedAttendeeList: [] as AttendeeRef[],
      attendeeUpdateDebouncer: new DebouncedValue<AttendeeRef[]>(
        presenceStoreService.getAttendees(),
        MIN_ATTENDEE_UPDATE_INTERVAL
      )
    };
  },
  computed: {
    ready(): boolean {
      const ready = this.isLoggedIn && this.inArea && this.fullAttendeeList.length > 0;
      this.$emit("update:active", ready);
      return ready;
    },
    // used in watcher below
    unfilteredAttendeeList(): AttendeeRef[] {
      return presenceStoreService.getAttendees();
    },
    fullAttendeeList(): AttendeeRef[] {
      /**
       * We want the list to be ordered by the area the attendees are in. Meaning that first we want to have the
       * attendees from the same area, then we want to see all other attendees. Additionally we want each sublist
       * (same-area and other-area) to be ordered alphabetically by the names of the attendees. The list we get
       * from "getAttendees" is already ordered alphabetically. Therefore, we only compare by area, i.e.,
       * (current area < other areas), and stable sorting guarantees that the alphabetical sorting in these
       * two clusters stay intact.
       *
       * From https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/sort:
       * Note: The ECMAScript Standard, 10th edition (2019) algorithm mandates stable sorting, which means
       * elements that compare equal must remain in their original order with respect to each other. This
       * behaviour may not be respected by older browsers.
       */

      const attendees = this.debouncedAttendeeList;
      attendees.sort((a1, a2) => this.compareByArea(a1, a2));
      return attendees.filter(this.byNotUid(this.userId));
    },
    attendees(): AttendeeRef[] {
      return this.fullAttendeeList
        .filter(this.byAreaId(this.currentAreaId))
        .filter(this.byCompany(this.company))
        .filter(this.bySearchTerm(this.searchTermModel));
    },
    currentAreaId(): string {
      return this.$route.params.areaId as string;
    },
    inArea(): boolean {
      return !!this.currentAreaId;
    },
    userId(): string {
      return userStoreService.getUser()?.userId ?? "";
    },
    isLoggedIn(): boolean {
      return userStoreService.isLoggedIn();
    },
    searchTermModel: {
      get(): string {
        return this.searchTerm ?? "";
      },
      set(value: string) {
        this.searchTerm = value ?? "";
      }
    },
    company(): string | undefined {
      return userStoreService.getUser()?.attributes.company;
    },
    isLoadingAttendeeProfile(): boolean {
      return userStoreService.isLoadingAttendeeProfile();
    },
    attendeeIdToLoad(): string | undefined {
      return userStoreService.getAttendeeIdToLoad();
    }
  },
  watch: {
    ready: {
      immediate: true,
      handler(isReady: boolean) {
        this.$emit("update:ready", isReady);
      }
    },
    unfilteredAttendeeList(newValue: AttendeeRef[]): void {
      this.attendeeUpdateDebouncer.setUpdatedValue(newValue);
    }
  },
  mounted() {
    this.attendeeUpdateDebouncer.addOnUpdateListener(
      (attendeeList: AttendeeRef[]) => (this.debouncedAttendeeList = [...attendeeList])
    );
  },
  beforeUnmount() {
    this.attendeeUpdateDebouncer.destroy();
  },
  methods: {
    selectAttendee(attendee: AttendeeRef): void {
      this.$emit("select-attendee", attendee);
    },
    async openProfileForAttendee(attendee: AttendeeRef): Promise<void> {
      TrackingService.trackProfileEvent(this.currentAreaId, ProfileType.AttendeeProfile, ProfileEvent.OpenProfile);
      await UserService.openProfileForAttendee(attendee.uid);
    },
    async startChat(attendee: AttendeeRef): Promise<void> {
      TrackingService.trackPresenceEvent({
        action: "Start Chat",
        areaId: this.currentAreaId,
        widgetName: "Presence",
        widgetType: "Presence"
      });
      await startChatWith(attendee);
      console.log(attendee);
    },
    /**
     * Compares the attendees by the area in which they are present.
     * Attendees in the current area are "less than" attendees in other areas.
     *
     * @param {AttendeeRef} att1 The first attendee.
     * @param {AttendeeRef} att2 The second attendee.
     * @return {number} A number less than, equal to, or greater than zero if the first attendee is
     *                  resp. less than, equal to, or greater than the second attendee.
     */
    compareByArea(att1: AttendeeRef, att2: AttendeeRef): number {
      if (att1.areaId === this.currentAreaId && att2.areaId !== att1.areaId) {
        // the first attendee is in the current area and the second attendee is not
        // => the first attendee is sorted before the second attendee
        return -1;
      } else if (att2.areaId === this.currentAreaId && att1.areaId !== att2.areaId) {
        // the second attendee is in the current area and the first attendee is not
        // => the second attendee is sorted before the first attendee
        return +1;
      } else {
        // either both attendees are in the current area or both attendees are in other areas
        // => both attendees are equal based on their area
        return 0;
      }
    },

    byNotUid(uid: string): (att: AttendeeRef) => boolean {
      return (att: AttendeeRef) => att.uid !== uid;
    },

    byAreaId(areaId: string | undefined): (att: AttendeeRef) => boolean {
      if (!this.areaFilter || areaId === undefined) {
        return () => true;
      } else {
        return (att: AttendeeRef) => att.areaId === areaId;
      }
    },

    byCompany(company: string | undefined): (att: AttendeeRef) => boolean {
      if (!this.companyFilter || !company) {
        return () => true;
      } else {
        return (att: AttendeeRef) => !!att.company && att.company === company;
      }
    },

    bySearchTerm(searchTerm: string): (att: AttendeeRef) => boolean {
      const normalisedSearchTerm = searchTerm.trim().toLowerCase();
      if (normalisedSearchTerm === "") {
        return () => true;
      } else {
        return (att: AttendeeRef) => {
          return [att.name, att.company, att.title]
            .map((val) => val?.toLowerCase())
            .some((val) => val?.includes(normalisedSearchTerm));
        };
      }
    }
  }
});
