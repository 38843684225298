import { ImageSizes } from "@/model/image-sizes";
import FetchAssetService from "@/services/fetch-asset-service";
import { uiStoreService } from "@/store/module-services";
import LogCapturingService from "@/services/log-capturing-service";

/**
 * The parameters that describe an asset.
 */
export interface AssetParams {
  /**
   * The unique ID of the asset in this context.
   */
  readonly id: string;
  /**
   * The Magnid asset ID.
   */
  readonly assetId: string;
  /**
   * The size of an image asset.
   */
  readonly imageSize?: ImageSizes;
}

/**
 * The information about an asset that was fetched from the backend and stored in an object URL.
 */
export interface AssetInfo extends AssetParams {
  /**
   * The object URL (aka blob URL) that contains the asset.
   */
  readonly objectUrl?: string;

  /**
   * The error that occurred while acquiring the asset.
   */
  readonly error?: Error;
}

/**
 * Manages a set of assets in either single-asset or multi-asset mode.
 *
 * In single-asset mode, the context always contains a single asset, which can
 * be set/replaced with `updateAsset(assetId, imageSize?)`. The asset's object
 * URL can be retrieved with `getObjectUrl()`. The single-asset mode provides
 * a simple API for the most common use-case required by most components.
 *
 * In multi-asset mode, the context contains a variable number of assets, which
 * can be set/replaced with `updateAssets(assetParams)`. Each set of asset
 * parameters gives a unique ID for the asset in this context, the Magnid asset
 * ID, and the optional size for image assets. The assets can be retrieved with
 * `getAssets()` or `getAssetById(id)` (Note, that this is the unique asset
 * context ID and not the Magnid asset ID!).
 *
 * Regardless of mode, if the context is no longer needed, then the assets must
 * be released by calling `releaseAssets` to free all allocated resources.
 */
export class FetchAssetContext {
  /**
   * The default asset context ID if this class is used in single-asset mode.
   */
  static readonly DEFAULT_ID = "default";

  /**
   * The category used to record errors with the log-capturing service.
   */
  static readonly LOG_CATEGORY = "ASSETS";

  private assetInfos: Promise<Map<string, AssetInfo>> = Promise.resolve(new Map<string, AssetInfo>());

  /**
   * Updates the single asset of the context in single-asset mode.
   *
   * @param {string} assetId The asset ID.
   * @param {ImageSizes|undefined} imageSize The image size (optional).
   */
  updateAsset(assetId: string, imageSize?: ImageSizes): void {
    this.updateAssets([{ id: FetchAssetContext.DEFAULT_ID, assetId, imageSize }]);
  }

  /**
   * Returns the object URL of the single asset of this context in single-asset mode.
   *
   * @return {string|Error|undefined} The object URL of the single asset of this context.
   */
  async getObjectUrl(): Promise<string | Error | undefined> {
    const assetInfo = await this.getAssetById(FetchAssetContext.DEFAULT_ID);
    return assetInfo?.objectUrl ?? assetInfo?.error;
  }

  /**
   * Updates all assets of this context in multi-asset mode.
   *
   * @param {AssetParams[]} assetParams The asset parameters.
   */
  updateAssets(assetParams: AssetParams[]): void {
    this.assetInfos = FetchAssetContext.replaceAssets(this.assetInfos, assetParams);
  }

  /**
   * Returns all assets of this context in multi-asset mode.
   *
   * @return {AssetInfo[]} The assets of this context.
   */
  async getAssets(): Promise<AssetInfo[]> {
    const context = await this.assetInfos;
    return [...context.values()];
  }

  /**
   * Returns the asset of this context with the given ID.
   * Note that this refers to the field `AssetParams.id` and not `AssetParams.assetId`!
   *
   * @param {string} id The asset context ID.
   * @return {AssetInfo|undefined} The asset of this context.
   */
  async getAssetById(id: string): Promise<AssetInfo | undefined> {
    const context = await this.assetInfos;
    return context.get(id);
  }

  /**
   * Releases all assets of this context.
   */
  releaseAssets(): void {
    this.assetInfos = FetchAssetContext.releaseAssets(this.assetInfos);
  }

  private static isModified(newAsset: AssetParams, oldAsset: AssetInfo): boolean {
    return (
      newAsset.id === oldAsset.id &&
      (newAsset.assetId !== oldAsset.assetId || newAsset.imageSize !== oldAsset.imageSize)
    );
  }

  /**
   * Updates the context to contain exactly the given new assets.
   *
   * 1. Removes and releases all asset in the context, that are no longer in the list of new assets.
   * 2. Keeps all assets in the context, that are also in the list of new asset.
   * 3. Acquires all new asset and adds them to the context.
   *
   * @param {Map<string, AssetInfo>} context The context.
   * @param {AssetParams[]} newAssets The new assets.
   * @return {Map<string, AssetInfo>} The updated context.
   * @private
   */
  private static async replaceAssets(
    context: Promise<Map<string, AssetInfo>>,
    newAssets: AssetParams[]
  ): Promise<Map<string, AssetInfo>> {
    const oldAssetInfos = await context;
    const newAssetInfos = new Map<string, AssetInfo>();
    for (const newAsset of newAssets) {
      const oldAsset = oldAssetInfos.get(newAsset.id);
      if (oldAsset === undefined) {
        // there is no old asset matching the current new asset => acquire the new asset
        await FetchAssetContext.acquireAsset(newAssetInfos, newAsset);
      } else if (FetchAssetContext.isModified(newAsset, oldAsset)) {
        // the asset parameters have changed => release the old asset and acquire the new asset
        await FetchAssetContext.releaseAsset(oldAssetInfos, oldAsset);
        await FetchAssetContext.acquireAsset(newAssetInfos, newAsset);
      } else {
        // the asset parameters have not changed => keep the old asset
        newAssetInfos.set(oldAsset.id, oldAsset);
        oldAssetInfos.delete(oldAsset.id);
      }
    }

    // all remaining old assets should be released from the context
    for (const oldAsset of oldAssetInfos.values()) {
      await FetchAssetContext.releaseAsset(oldAssetInfos, oldAsset);
    }

    return newAssetInfos;
  }

  /**
   * Releases all assets from the given context.
   *
   * @param {Map<string, AssetInfo>} context The context.
   * @return {Map<string, AssetInfo>} The updated context.
   * @private
   */
  private static async releaseAssets(context: Promise<Map<string, AssetInfo>>): Promise<Map<string, AssetInfo>> {
    const oldAssetInfos = await context;
    for (const oldAsset of oldAssetInfos.values()) {
      await FetchAssetContext.releaseAsset(oldAssetInfos, oldAsset);
    }
    return new Map<string, AssetInfo>();
  }

  /**
   * Acquires an asset and adds it to the given context.
   *
   * @param context The context.
   * @param newAsset The asset to acquire.
   * @private
   */
  private static async acquireAsset(context: Map<string, AssetInfo>, newAsset: AssetParams) {
    try {
      const objectUrl = await FetchAssetService.getAssetUrl(newAsset.assetId, newAsset.imageSize);
      context.set(newAsset.id, { ...newAsset, objectUrl });
    } catch (e) {
      const error = e as Error;
      context.set(newAsset.id, { ...newAsset, error });

      // don't show the error dialog; in some situations, we might want to try different ways
      // to cope with the error, e.g., retry to load an asset or display an alternative asset
      await uiStoreService.clearError();

      // try to log the error in the log-capturing service
      await this.tryToLogWarning(newAsset, error);
    }
  }

  /**
   * Tries to log the given asset-related error as a warning in the log-capturing service.
   * Failures to do so, will be logged to the console, but will not raise an error again.
   *
   * @param {AssetParams} asset The asset which caused the error.
   * @param {Error} error The error.
   * @private
   */
  private static async tryToLogWarning(asset: AssetParams, error: Error) {
    try {
      const imageSize = asset.imageSize ?? "undefined";
      const message = `Failed to load asset '${asset.assetId}' in size '${imageSize}': ${error.message}`;
      await LogCapturingService.captureWarning(message, error.stack, this.LOG_CATEGORY);
    } catch (e) {
      // only log the error to the console; don't fail again while logging an error
      console.log("Failed to capture error", e);
    }
  }

  /**
   * Releases an asset and removes it from the given context.
   *
   * @param context The context.
   * @param oldAsset The asset to release.
   * @private
   */
  private static async releaseAsset(context: Map<string, AssetInfo>, oldAsset: AssetInfo) {
    await FetchAssetService.releaseAssetUrl(oldAsset.objectUrl);
    context.delete(oldAsset.id);
  }
}
