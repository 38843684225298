/**
 * Helper function to remove an object's entries whose values are faulty (i.e. not truthy).
 * @param object
 */
export function removeFaultyValues(object: Record<string, unknown>): void {
  Object.keys(object).forEach((key: string) => {
    if (!object[key]) {
      delete object[key];
    }
  });
}

/**
 * Helper function to remove an object's entries whose values are undefined.
 * @param object
 */
export function removeUndefinedValues(object: Record<string, unknown>): void {
  Object.keys(object).forEach((key: string) => {
    if (object[key] === undefined) {
      delete object[key];
    }
  });
}
