import { NavigationGuardNext, RouteLocationNormalized } from "vue-router";
import { venueStoreService } from "@/store/module-services";
import { copyLocalStorageCookieProviders } from "@/router/copyLocalStorageCookieProviders";
import ensureVenue from "@/utility/ensure-venue";
import FetchUserProfileService from "@/services/fetch-user-profile-service";

/**
 * Ensures the venue is loaded. If the route ends in "ArchitectureView" without any child-route,
 * this guard will forward the current request to the default area within the given architecture.
 */
export async function ensureArchitectureBeforeGuard(
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
): Promise<void> {
  const routeName: string = to.name as string;
  const routeArchitectureId: string | undefined = to.params.architectureId as string;

  // Ensure that the venue is loaded, and if given, using the architecture from route, to select an architecture.
  await ensureVenue(routeArchitectureId);

  // make sure to copy the stored cookie preferences before we enter the architecture
  await copyLocalStorageCookieProviders();

  // Ensure that the desired area is in the store
  const venueArchitectureId = venueStoreService.getArchitectureId();

  // gather the remaining user data
  await FetchUserProfileService.fetchUserProfile();

  // in case the route is /architecture we redirect to architectures default area
  if (routeName === "ArchitectureView" && venueArchitectureId) {
    const venueDefaultAreaId = venueStoreService.getDefaultArea()?.id;
    return next({
      name: "Area",
      params: {
        architectureId: venueArchitectureId,
        areaId: venueDefaultAreaId || ""
      }
    });
  } else {
    return next();
  }
}
