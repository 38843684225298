import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_card_subtitle = _resolveComponent("v-card-subtitle")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_dialog = _resolveComponent("v-dialog")!

  return (_openBlock(), _createBlock(_component_v_dialog, {
    modelValue: _ctx.visible,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.visible) = $event)),
    width: "500",
    persistent: "",
    "overlay-color": "black",
    "overlay-opacity": "0.8"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_card, {
        "data-testid": "the-error-dialog",
        class: "secondary lighten-2"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_card_title, { class: "text-h5 secondary lighten-1" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t("error")), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_v_card_subtitle, { class: "my-2" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.code), 1)
            ]),
            _: 1
          }),
          (_ctx.message)
            ? (_openBlock(), _createBlock(_component_v_card_text, { key: 0 }, {
                default: _withCtx(() => [
                  _createElementVNode("strong", null, _toDisplayString(_ctx.message), 1)
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          _createVNode(_component_v_divider, { class: "mx-2" }),
          _createVNode(_component_v_card_actions, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_spacer),
              _createVNode(_component_v_btn, {
                "data-testid": "error-dialog-dismiss-button",
                onClick: _ctx.dismissError
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t("dismissError")), 1)
                ]),
                _: 1
              }, 8, ["onClick"])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["modelValue"]))
}