import AbstractStoreService from "@/store/framework/AbstractStoreService";
import { RootState } from "@/store/RootState";
import { PresenceState } from "@/store/modules/presence/PresenceState";
import { PresenceGetters } from "@/store/modules/presence/presenceGetters";
import { AttendeeUpdate, PresenceAction } from "@/store/modules/presence/presenceActions";
import { AttendeeRef } from "@/model/attendee-ref";
import { VvenueOpMode } from "@/services/backend/generated/model/vvenue-op-mode";

export class PresenceStoreService extends AbstractStoreService<PresenceState, RootState, PresenceGetters> {
  /**
   * Returns all attendees of current opmode (STAGE/LIVE), ordered by name
   */
  getAttendees(): AttendeeRef[] {
    return this._get("getAttendees");
  }

  async connect(venueOpMode: VvenueOpMode | undefined): Promise<void> {
    return this.dispatch(PresenceAction.CONNECT, venueOpMode);
  }

  async updateAttendee(attendeeUpdate: AttendeeUpdate): Promise<void> {
    return this.dispatch(PresenceAction.UPDATE_ATTENDEE, attendeeUpdate);
  }

  async removeAttendee(attendeeUpdate: AttendeeUpdate): Promise<void> {
    return this.dispatch(PresenceAction.REMOVE_ATTENDEE, attendeeUpdate);
  }
}
