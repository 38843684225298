import { FirebaseService } from "@/services/firebase-service";
import { TypedAction } from "@/store/framework/TypedAction";
import { RootState } from "@/store/RootState";
import { MediaBoardSocialContentGetters } from "./mediaBoardSocialContentGetters";
import { MediaBoardSocialContentState } from "./mediaBoardSocialContentState";
import { getLikeKey, MediaBoardLikeRef, MediaBoardLikeVuexRef } from "@/model/media-board-like-ref";
import firebase from "firebase/app";
import { BindMyLikesOptions } from "@/model/bind-my-likes-options";
import { createContentPieceLikeCountPath, createLikesPath } from "@/utility/media-board-social-content";
import { VvenueOpMode } from "@/services/backend/generated/model/vvenue-op-mode";
import { MediaBoardLikePayload } from "@/model/media-board-like-payload";
import { MediaBoardApi } from "@/services/backend-service";
import { MediaBoardUpdateRecommendationPayload } from "@/model/media-board-update-recommended-payload";
import { MediaBoardSocialContentMutation } from "./mediaBoardSocialContentMutations";

/**
 * Defines all action names in the media board social content store slice.
 */
export enum MediaBoardSocialContentAction {
  ADD_LIKE = "ADD_LIKE",
  BIND_MY_LIKES = "BIND_MY_LIKES",
  REMOVE_LIKE = "REMOVE_LIKE",
  UPDATE_MEDIA_BOARD_RECOMMENDATION = "UPDATE_MEDIA_BOARD_RECOMMENDATION"
}

/**
 * Defines the action types in the media board social content store slice.
 */
export interface MediaBoardSocialContentActions {
  [MediaBoardSocialContentAction.ADD_LIKE]: MediaBoardLikePayload;
  [MediaBoardSocialContentAction.BIND_MY_LIKES]: BindMyLikesOptions;
  [MediaBoardSocialContentAction.REMOVE_LIKE]: MediaBoardLikePayload;
  [MediaBoardSocialContentAction.UPDATE_MEDIA_BOARD_RECOMMENDATION]: MediaBoardUpdateRecommendationPayload;
}

/**
 * Defines types of all action functions in the media board social content store slice.
 */
type MediaBoardSocialContentActionFunctions = {
  [name in keyof MediaBoardSocialContentActions]: TypedAction<
    MediaBoardSocialContentState,
    RootState,
    MediaBoardSocialContentGetters,
    MediaBoardSocialContentActions[name]
  >;
};

/**
 * Action method implementations in the media board social content store slice.
 */
export const mediaBoardSocialContentActions: MediaBoardSocialContentActionFunctions = {
  /**
   * Add like
   * @param _: unused
   * @param like: likeRef object
   */
  async [MediaBoardSocialContentAction.ADD_LIKE](_, like: MediaBoardLikePayload) {
    const likeRef = FirebaseService.rtdb().ref(createLikePath(like.opMode, like.like));
    await likeRef.set(like.like);

    if (!like.like.commentId) {
      const contentPieceLikeCountRef = FirebaseService.rtdb().ref(
        createContentPieceLikeCountPath(like.opMode, like.like)
      );
      await contentPieceLikeCountRef.set(firebase.database.ServerValue.increment(1));
      await MediaBoardApi.like(like.like);
    }
  },
  /**
   * Connect the vuex store to the firebase rtdb.
   * @param venueOpMode The venue op mode to connect to (STAGE/LIVE).
   */
  [MediaBoardSocialContentAction.BIND_MY_LIKES]({ commit }, options: BindMyLikesOptions): void {
    const likesRef = FirebaseService.rtdb().ref(createLikesPath(options.opMode));
    // removing old listeners
    likesRef.off();

    likesRef
      .orderByChild("authorId")
      .equalTo(options.userId)
      .limitToLast(10_000_000)
      .on("value", (snap) => commit(MediaBoardSocialContentMutation.UPDATE_LIKES, serializeLike(snap)));
  },

  /**
   * Remove like
   * @param _: unused
   * @param like: likeRef object
   */
  async [MediaBoardSocialContentAction.REMOVE_LIKE](_, like: MediaBoardLikePayload) {
    const likeRef = FirebaseService.rtdb().ref(createLikePath(like.opMode, like.like));
    await likeRef.remove();

    if (!like.like.commentId) {
      const contentPieceLikeCountRef = FirebaseService.rtdb().ref(
        createContentPieceLikeCountPath(like.opMode, like.like)
      );
      await contentPieceLikeCountRef.set(firebase.database.ServerValue.increment(-1));

      await MediaBoardApi.unlike(like.like);
    }
  },
  /**
   * Add like
   * @param _: unused
   * @param like: likeRef object
   */
  async [MediaBoardSocialContentAction.UPDATE_MEDIA_BOARD_RECOMMENDATION](
    { commit },
    payload: MediaBoardUpdateRecommendationPayload
  ) {
    try {
      const recommendations = await MediaBoardApi.getRecommended(payload.mediaBoardId, payload.userId, payload.limit);
      commit(MediaBoardSocialContentMutation.UPDATE_RECOMMENDATION, [
        payload.mediaBoardId,
        Array.isArray(recommendations.data) ? [...recommendations.data] : []
      ]);
    } catch (error) {
      console.log("There was an error when setting accepted: ", error);
    }
  }
};

function createLikePath(opMode: VvenueOpMode, like: MediaBoardLikeRef) {
  return `${createLikesPath(opMode)}/${like.uuid}`;
}

function serializeLike(snapshot: firebase.database.DataSnapshot): MediaBoardLikeVuexRef[] {
  const values = Object.values(snapshot.val() ?? {}) as MediaBoardLikeVuexRef[];

  return values.map((obj: MediaBoardLikeVuexRef) => {
    const { uuid, contentPieceId, commentId } = obj;
    return {
      uuid,
      contentPieceId,
      commentId,
      key: getLikeKey(contentPieceId, commentId)
    };
  });
}
