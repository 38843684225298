/* tslint:disable */
/* eslint-disable */
/**
 * VVenue Services API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 53
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { ClientDetails } from './client-details';
import { MessageDetails } from './message-details';

/**
 * 
 * @export
 * @interface FrontendMessage
 */
export interface FrontendMessage {
    /**
     * 
     * @type {ClientDetails}
     * @memberof FrontendMessage
     */
    'clientDetails'?: ClientDetails;
    /**
     * 
     * @type {string}
     * @memberof FrontendMessage
     */
    'logCategory'?: string;
    /**
     * 
     * @type {string}
     * @memberof FrontendMessage
     */
    'loglevel'?: FrontendMessageLoglevelEnum;
    /**
     * 
     * @type {MessageDetails}
     * @memberof FrontendMessage
     */
    'messageDetails'?: MessageDetails;
    /**
     * 
     * @type {string}
     * @memberof FrontendMessage
     */
    'messageOrigin'?: FrontendMessageMessageOriginEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum FrontendMessageLoglevelEnum {
    INFO = 'INFO',
    WARN = 'WARN',
    ERROR = 'ERROR'
}
/**
    * @export
    * @enum {string}
    */
export enum FrontendMessageMessageOriginEnum {
    DESIGNER = 'DESIGNER',
    UI = 'UI'
}


