
import { ComponentPublicInstance, defineComponent, PropType } from "vue";
import { ImageSizes } from "@/model/image-sizes";
import { SourceImageSize } from "@/model/source-image-size";
import BaseAssetContainer from "@/ui/base/BaseAssetContainer.vue";
import { uiStoreService } from "@/store/module-services";
import { ErrorCode } from "@/store/modules/ui/UiState";

interface ImageRef {
  naturalWidth: number;
  naturalHeight: number;
  image: HTMLImageElement;
}

export default defineComponent({
  components: {
    BaseAssetContainer
  },
  props: {
    assetId: {
      type: String,
      required: true
    },
    dataTestid: {
      type: String,
      required: false
    },
    imageSize: {
      type: String as PropType<ImageSizes | undefined>,
      default: undefined
    },
    placeholder: {
      type: Boolean,
      default: true
    },
    imageStyle: {
      type: Object as PropType<Record<string, string | number>>,
      required: false
    },
    isCustomIcon: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      retryWithDefaultImageSize: false,
      loaded: false
    };
  },

  computed: {
    dataTestId(): string {
      return this.dataTestid ?? `base-image[${this.assetId}]`;
    },

    actualImageSize(): ImageSizes | undefined {
      return this.retryWithDefaultImageSize ? undefined : this.imageSize;
    }
  },

  mounted() {
    /**
     * If computedAspectRatio and naturalWidth of the image reference are present, then the
     * watcher will compute and emit the size of the source image.
     */
    this.$watch(
      () => {
        // watcher triggers when both, computedAspectRatio and naturalWidth are present.
        const ref = this.$refs.imageRef as (ComponentPublicInstance & ImageRef) | undefined;
        return ref?.naturalHeight && ref?.naturalWidth ? ref : undefined;
      },
      (imageRef: ImageRef | undefined) => {
        if (imageRef !== undefined) {
          this.$emit("resize-image", {
            aspectRatio: imageRef.naturalWidth / imageRef.naturalHeight,
            height: imageRef.naturalHeight,
            width: imageRef.naturalWidth
          } as SourceImageSize);
        }
      },
      { immediate: true }
    );
  },

  methods: {
    async retryOnError(error: Error) {
      // if there was an error loading the image with a non-default image size,
      // then try to load the image with the default image size again.
      if (this.imageSize !== undefined && !this.retryWithDefaultImageSize) {
        // setting retryWithDefaultImageSize will update the computed property "actualImageSize",
        // which will, in turn, update the BaseAssetContainer and its enclosed v-img... magic!
        this.retryWithDefaultImageSize = true;
      } else {
        const imageSize = this.imageSize ?? "undefined";
        await uiStoreService.setError(
          ErrorCode.WIDGET_ERROR,
          `Failed to load asset ${this.assetId} in size "${imageSize}": ${error.message}`
        );
      }
    }
  }
});
