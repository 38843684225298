/**
 * All user related information.
 */
import { LocalStorageCookieProvider } from "@/model/local-storage-cookie-provider";
import { UserInfo } from "@/model/user-info";
import { TypeImprovedAttendeeProfile } from "@/model/type-improved-attendee-profile";

/**
 * Instead of having multiple, possibly conflicting, fields to represent the state of profiles
 * (e.g. isUserProfileOpen, isLoadingAttendeeProfile, isAttendeeProfileOpen, attendeeProfileToShow), we try to represent
 * the state using the following types for more clarity.
 */
export enum ProfileStateType {
  NoProfile,
  ShowOwnProfile,
  LoadingAttendeeProfile,
  ShowAttendeeProfile
}
export interface NoProfile {
  type: ProfileStateType.NoProfile;
}
export interface ShowOwnProfile {
  type: ProfileStateType.ShowOwnProfile;
}
export interface LoadingAttendeeProfile {
  type: ProfileStateType.LoadingAttendeeProfile;
  userId: string;
}
export interface ShowAttendeeProfile {
  type: ProfileStateType.ShowAttendeeProfile;
  profile: TypeImprovedAttendeeProfile;
}
export type ProfileState = NoProfile | ShowOwnProfile | LoadingAttendeeProfile | ShowAttendeeProfile;

export type UserState = {
  // Whether there's a logged in user
  isLoggedIn: boolean;

  // Information about the user currently logged in
  user: UserInfo | undefined;

  // The authentication token used for backend and asset requests
  authToken: string | undefined;

  // Whether the user has given his consent on being tracked
  trackingConsentGiven: boolean | undefined;

  localStorageCookieProviders: LocalStorageCookieProvider[];

  // the state that the user/attendee profile currently is in
  profileState: ProfileState;
};
