import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_search_bar = _resolveComponent("search-bar")!
  const _component_attendee_list = _resolveComponent("attendee-list")!
  const _component_v_card = _resolveComponent("v-card")!

  return (_openBlock(), _createBlock(_component_v_card, {
    color: "background lighten-2",
    class: "pa-2 flex-row d-flex fill-height",
    "data-testid": "presence-widget"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_search_bar, {
        "area-filter": _ctx.areaFilter,
        "area-id": _ctx.areaId,
        "search-term": _ctx.searchTerm,
        "company-filter": _ctx.companyFilter,
        "data-testid": "presence-widget-search",
        "onUpdate:areaFilter": _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:areaFilter', $event))),
        "onUpdate:companyFilter": _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('update:companyFilter', $event))),
        "onUpdate:searchTerm": _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('update:searchTerm', $event)))
      }, null, 8, ["area-filter", "area-id", "search-term", "company-filter"]),
      _createVNode(_component_attendee_list, {
        "attendee-list": _ctx.attendeeList,
        "is-loading-attendee-profile": _ctx.isLoadingAttendeeProfile,
        "attendee-id-to-load": _ctx.attendeeIdToLoad,
        "attendee-update-debouncer": _ctx.attendeeUpdateDebouncer,
        onOpenProfileForAttendee: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('open-profile-for-attendee', $event))),
        onStartChat: _cache[4] || (_cache[4] = ($event: any) => (_ctx.$emit('start-chat', $event)))
      }, null, 8, ["attendee-list", "is-loading-attendee-profile", "attendee-id-to-load", "attendee-update-debouncer"])
    ]),
    _: 1
  }))
}