import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, normalizeStyle as _normalizeStyle, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-113f3a14"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = {
  class: "pa-1 flex-fill fill-height pb-2 align-self-center",
  style: {"min-width":"0"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_home_button = _resolveComponent("home-button")!
  const _component_presence_widget_container = _resolveComponent("presence-widget-container")!
  const _component_the_language_selection = _resolveComponent("the-language-selection")!
  const _component_user_preview_container = _resolveComponent("user-preview-container")!
  const _component_v_app_bar = _resolveComponent("v-app-bar")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_app_bar_nav_icon = _resolveComponent("v-app-bar-nav-icon")!
  const _component_app_drawer = _resolveComponent("app-drawer")!

  return (_ctx.architectureStyle)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (!_ctx.isMobile)
          ? (_openBlock(), _createBlock(_component_v_app_bar, {
              key: 0,
              "data-testid": "header",
              app: "",
              color: _ctx.backgroundColor,
              flat: "",
              "max-width": "100%",
              height: _ctx.heightValue,
              class: "px-1",
              dense: ""
            }, {
              default: _withCtx(() => [
                _createVNode(_component_home_button, {
                  "architecture-style": _ctx.architectureStyle,
                  class: "pa-2"
                }, null, 8, ["architecture-style"]),
                _createElementVNode("div", _hoisted_2, [
                  _createVNode(_component_presence_widget_container, {
                    active: _ctx.presenceReady,
                    "onUpdate:active": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.presenceReady) = $event)),
                    class: "ma-1 flex-fill fill-height"
                  }, null, 8, ["active"])
                ]),
                _createVNode(_component_the_language_selection, {
                  "data-testid": "language-selection",
                  class: "mx-1"
                }),
                (_ctx.isLoggedIn)
                  ? (_openBlock(), _createBlock(_component_user_preview_container, {
                      key: 0,
                      class: "mx-1"
                    }))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }, 8, ["color", "height"]))
          : (_openBlock(), _createBlock(_component_v_app_bar, {
              key: 1,
              flat: "",
              color: "background",
              "data-testid": "header",
              app: ""
            }, {
              default: _withCtx(() => [
                _createVNode(_component_home_button, { "architecture-style": _ctx.architectureStyle }, null, 8, ["architecture-style"]),
                _createVNode(_component_v_spacer),
                _createVNode(_component_v_app_bar_nav_icon, {
                  class: "align-self-end ma-0",
                  style: _normalizeStyle(`color: ${_ctx.architectureStyle.text}`),
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.drawer = !_ctx.drawer))
                }, null, 8, ["style"])
              ]),
              _: 1
            })),
        (_ctx.isMobile)
          ? (_openBlock(), _createBlock(_component_app_drawer, {
              key: 2,
              active: _ctx.drawer,
              "onUpdate:active": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.drawer) = $event)),
              "show-logout-button": _ctx.showLogoutButton,
              "architecture-id": _ctx.architectureId,
              onLogout: _ctx.logOut
            }, null, 8, ["active", "show-logout-button", "architecture-id", "onLogout"]))
          : _createCommentVNode("", true)
      ]))
    : _createCommentVNode("", true)
}