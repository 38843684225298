import { App } from "vue";
import VueMatomo from "vue-matomo";
import router from "@/router";
import {
  getPersistentVisitorId,
  trackerScriptUrl,
  trackerSiteId,
  trackerUrl
} from "@/backend/tracking/matomo-tracking-utils";
import { backOff } from "exponential-backoff";
import axios from "axios";

let scriptLoadAttemptNumber = 0;

export default function initMatomoPlugin(app: App<Element>) {
  // eslint-disable-next-line @typescript-eslint/no-floating-promises
  backOff(() => loadScript(trackerScriptUrl), {
    retry: (err: Error | undefined, attemptNumber: number) => {
      console.log(`Matomo script load attempt: ${attemptNumber}`);
      scriptLoadAttemptNumber = attemptNumber;
      return !!err;
    },
    numOfAttempts: 10,
    startingDelay: 1000
  })
    .then(() => {
      const visitorId = getPersistentVisitorId();
      initMatomo(app, visitorId);
      window._paq.push(["disableAlwaysUseSendBeacon"]); // required to catch failed http requests
    })
    .catch(() => {
      console.error("Failed to load Matomo script");
    });
}

function initMatomo(app: App<Element>, visitorId: string): void {
  app.use(VueMatomo, {
    host: `${window.location.protocol}//${window.location.host}/`,
    siteId: trackerSiteId,
    trackerUrl,
    trackerScriptUrl,
    router,
    disableCookies: true,
    enableLinkTracking: true,
    requireConsent: false,
    trackInitialView: false,
    enableHeartBeatTimer: true,
    heartBeatTimerInterval: 15,
    debug: false,
    preInitActions: [["setVisitorId", visitorId]]
  });
}

function removeLinkElements(dataTestId: string) {
  const elements = window.document.querySelectorAll(`head > link[data-testid="${dataTestId}"]`);
  if (elements?.length > 0) {
    elements.forEach((element) => element.remove());
  }
}

function loadScript(scriptUrl: string) {
  if (isPrefetchSupported()) {
    return loadScriptViaPrefetch(scriptUrl);
  } else {
    return loadScriptViaXhr(scriptUrl);
  }
}

function loadScriptViaPrefetch(scriptUrl: string) {
  const dataTestId = "matomo-preload";
  removeLinkElements(dataTestId);

  return new Promise((resolve, reject) => {
    const link = document.createElement("link");
    link.setAttribute("rel", "prefetch");
    link.setAttribute("as", "script");
    link.setAttribute("href", scriptUrl);
    link.setAttribute("data-testid", dataTestId);
    link.setAttribute("data-attempt", scriptLoadAttemptNumber.toString());
    link.onload = resolve;
    link.onerror = reject;

    const head = document.head || document.getElementsByTagName("head")[0];
    head.appendChild(link);
  });
}

function loadScriptViaXhr(scriptUrl: string) {
  return axios.create().get(scriptUrl);
}

function isPrefetchSupported(): boolean {
  const link = document.createElement("link");
  let isSupported = false;
  if (link.relList && typeof link.relList?.supports === "function") {
    isSupported = link.relList.supports("prefetch");
  }
  return isSupported;
}
