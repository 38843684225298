// Service to access the VVenue backend.

import { VenueUIApi } from "@/services/backend/generated/api/venue-uiapi";
import axios from "axios";
import { LoginInfoControllerApi } from "@/services/backend/generated/api/login-info-controller-api";
import { VenueVersionApi } from "@/services/backend/generated/api/venue-version-api";
import { addAuthToken } from "@/services/axios/addAuthToken";
import { synchronizeServerClock } from "@/services/server-time-service";
import { handleError } from "@/services/axios/handleError";
import { Configuration } from "@/services/backend/generated/configuration";
import { VideoChatControllerApi } from "@/services/backend/generated/api/video-chat-controller-api";
import { FrontendLoggingControllerApi } from "@/services/backend/generated/api/frontend-logging-controller-api";
import { CventLoginControllerApi } from "@/services/backend/generated/api/cvent-login-controller-api";
import { ZoomClientControllerApi } from "@/services/backend/generated/api/zoom-client-controller-api";
import { BizzaboLoginControllerApi } from "@/services/backend/generated/api/bizzabo-login-controller-api";
import { UserProfileControllerApi } from "@/services/backend/generated/api/user-profile-controller-api";
import { attach } from "retry-axios";
import { MediaBoardControllerApi } from "./backend/generated/api/media-board-controller-api";

const BASE_PATH = "/api"; // same for all environments
export const UI_ENDPOINTS_PATH = `${BASE_PATH}/ui`;
export const CHAT_ENDPOINT_PATH = `${BASE_PATH}/chat`;

// Register global axios interceptors
axios.interceptors.request.use(addAuthToken);
axios.interceptors.response.use(synchronizeServerClock, handleError);

// Retry if server returns error, status codes: 100 - 199, 429, 500 - 599.
axios.defaults.raxConfig = {
  retry: 2,
  retryDelay: 30_000,
  backoffType: "static",
  httpMethodsToRetry: ["GET", "HEAD", "POST", "DELETE", "PUT"]
};
export const raxInterceptorId = attach();

// Register global axios interceptors
axios.interceptors.request.use(addAuthToken);
/**
 * The backend API to fetch venue data and assets.
 */
export const VenueApi = new VenueUIApi(
  new Configuration({
    basePath: BASE_PATH
  })
);

/**
 * The backend API to fetch venue data and assets.
 */
export const VersionApi = new VenueVersionApi(
  new Configuration({
    basePath: BASE_PATH
  })
);

/**
 * The backend API to fetch login information.
 */
export const LoginInfoApi = new LoginInfoControllerApi(
  new Configuration({
    basePath: BASE_PATH
  })
);

/**
 * The backend API to fetch login information.
 */
export const CventLoginApi = new CventLoginControllerApi(
  new Configuration({
    basePath: BASE_PATH
  })
);

/**
 * The backend API to fetch login information.
 */
export const BizzaboLoginApi = new BizzaboLoginControllerApi(
  new Configuration({
    basePath: BASE_PATH
  })
);

/**
 * The chat API to create sessions and obtain tokens.
 */
export const VideoChatApi = new VideoChatControllerApi(
  new Configuration({
    basePath: CHAT_ENDPOINT_PATH
  })
);

/**
 * The zoom API to create sessions.
 */
export const ZoomClientApi = new ZoomClientControllerApi(
  new Configuration({
    basePath: CHAT_ENDPOINT_PATH
  })
);

/**
 * The backend API to capture frontend errors.
 */
export const LogCapturingApi = new FrontendLoggingControllerApi(
  new Configuration({
    basePath: BASE_PATH
  })
);

export const UserProfileApi = new UserProfileControllerApi(
  new Configuration({
    basePath: BASE_PATH
  })
);

export const MediaBoardApi = new MediaBoardControllerApi(
  new Configuration({
    basePath: BASE_PATH
  })
);

/**
 * Builds the URL where an authenticated user can download the given asset.
 *
 * The URL is intended for asset downloads and can not be used to display an asset in the UI,
 * because it returns the asset with "Content-Disposition: attachment"!
 *
 * @param {string} architectureId The architecture ID.
 * @param {string} assetId The asset ID.
 * @return {string} The download URL.
 */
export function buildDownloadUrl(architectureId: string, assetId: string): string {
  const architectureIdEnc = encodeURIComponent(architectureId);
  const assetIdEnc = encodeURIComponent(assetId);
  return `${UI_ENDPOINTS_PATH}/architecture/${architectureIdEnc}/assets/${assetIdEnc}/download`;
}
