
import { defineComponent, PropType } from "vue";
import { AttendeeRef } from "@/model/attendee-ref";
import AttendeeSummary from "@/ui/components/presence-widget/AttendeeSummary.vue";

export default defineComponent({
  components: { AttendeeSummary },
  props: {
    attendee: {
      type: Object as PropType<AttendeeRef>,
      required: true
    }
  },
  methods: {
    openAttendeeProfile(): void {
      this.$emit("open-attendee-profile", this.attendee);
    },
    openChat() {
      this.$emit("start-chat", this.attendee);
    }
  }
});
