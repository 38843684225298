
import { ComponentPublicInstance, defineComponent, PropType } from "vue";
import AttendeeCard from "@/ui/components/presence-widget/AttendeeCard.vue";
import { AttendeeRef } from "@/model/attendee-ref";
import { MIN_ATTENDEE_CARD_WIDTH } from "@/ui/components/presence-widget/constants";
import { DebouncedValue } from "@/utility/debounced-value";

type DynamicScroller = ComponentPublicInstance & { scrollToItem: (index: number) => void };

export default defineComponent({
  components: { AttendeeCard },
  emits: ["open-profile-for-attendee", "start-chat"],
  props: {
    attendeeList: {
      type: Array as PropType<Array<AttendeeRef>>
    },
    isLoadingAttendeeProfile: Boolean,
    attendeeIdToLoad: String,
    attendeeUpdateDebouncer: {
      type: DebouncedValue as PropType<DebouncedValue<Array<AttendeeRef>>>
    }
  },
  data() {
    return {
      MIN_ATTENDEE_CARD_WIDTH,
      scrollbarVisible: false,
      enableScrollerUpdates: true,
      preventUpdateHandler: () => {
        this.attendeeUpdateDebouncer?.resetNoUpdateTimeout();
      },
      sliderValue: 20,
      sliderMin: 0,
      scroller: {} as DynamicScroller,
      scrollerHtmlElement: undefined as HTMLElement | undefined
    };
  },
  computed: {
    sliderMax(): number {
      return this.attendeeList?.length || 0;
    }
  },
  beforeUnmount() {
    if (this.scrollerHtmlElement) {
      this.scrollerHtmlElement.removeEventListener("scroll", this.preventUpdateHandler);
    }
  },
  methods: {
    updateRefs() {
      this.scroller = this.$refs.dynamicScroller as DynamicScroller;
      this.scrollerHtmlElement = this.scroller.$el as HTMLElement;
      // prevent updates of attendee list when scrolling
      this.scrollerHtmlElement.addEventListener("scroll", this.preventUpdateHandler);
    },
    onResize(): void {
      if (this.scrollerHtmlElement) {
        this.scrollbarVisible = this.scrollerHtmlElement.scrollWidth > this.scrollerHtmlElement.clientWidth;
      }
    },
    onMenuToggle(menuOpen: boolean): void {
      if (menuOpen) {
        this.attendeeUpdateDebouncer?.addUpdatePreventer();
      } else {
        this.attendeeUpdateDebouncer?.removeUpdatePreventer();
      }
    },
    openProfileForAttendee(attendee: AttendeeRef): void {
      this.$emit("open-profile-for-attendee", attendee);
    },
    startChat(attendee: AttendeeRef): void {
      this.$emit("start-chat", attendee);
    },
    updateSliderPosition(startIndex: number, endIndex: number, visibleStartIndex: number, visibleEndIndex: number) {
      if (visibleEndIndex === this.sliderMax) {
        this.sliderValue = this.sliderMax;
      } else if (visibleStartIndex === 0) {
        this.sliderValue = 0;
      } else if (this.sliderValue < visibleStartIndex || this.sliderValue > visibleEndIndex) {
        const estimatedSliderPosition = Math.floor((visibleStartIndex + visibleEndIndex) / 2);
        this.sliderValue = estimatedSliderPosition;
      } else {
        // Sonar wants this, but is it needed? No, because we ignore the incoming values to stop the scroller jumping
      }
    },
    scrollToItem(itemIndex: number) {
      this.scroller.scrollToItem(Math.round(itemIndex));
    }
  }
});
