/* tslint:disable */
/* eslint-disable */
/**
 * VVenue Services API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 53
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */

export enum VvenueOpMode {
    STAGE = 'STAGE',
    LIVE = 'LIVE'
}


