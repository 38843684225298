/**
 * Handle all LocalStorage access, to ensure that no part of the code performs different clashing access.
 */

/**
 * The different types of data we store in localStorage.
 *
 * Every one of these must be unique, and may not contain `LocalStorageService.separator` (colon).
 */
enum LocalStoragePrefix {
  I18N = "lang",
  COOKIES = "cookies",
  WIDGET_SEEN = "widget-seen",
  TRACKING = "tracking"
}

enum TrackingData {
  VISITOR_ID = "visitor-id"
}

export enum CookiesLevel {
  ESSENTIAL = "essential",
  TRACKING = "tracking"
}

export default class LocalStorageService {
  private static readonly separator = ":";

  private static makeKey(prefix: LocalStoragePrefix, ...args: string[]): string {
    return [prefix, ...args].join(this.separator);
  }

  private static get(prefix: LocalStoragePrefix, ...args: string[]): string | null {
    return localStorage.getItem(this.makeKey(prefix, ...args));
  }

  private static set(value: string, prefix: LocalStoragePrefix, ...args: string[]): void {
    return localStorage.setItem(this.makeKey(prefix, ...args), value);
  }

  private static remove(prefix: LocalStoragePrefix, ...args: string[]): void {
    return localStorage.removeItem(this.makeKey(prefix, ...args));
  }

  /**
   * Get the language recorded in the browser, or null otherwise.
   */
  static getLang(): string | null {
    return this.get(LocalStoragePrefix.I18N) || null;
  }

  static setLang(lang: string): void {
    this.set(lang, LocalStoragePrefix.I18N);
  }

  static setCookiesPermission(cookieId: string, enabled: boolean): void {
    return this.set(enabled ? "true" : "false", LocalStoragePrefix.COOKIES, cookieId);
  }

  static getCookiesPermission(cookiesLevel: CookiesLevel): boolean {
    return this.get(LocalStoragePrefix.COOKIES, cookiesLevel) === "true";
  }

  static getRawCookiePermission(cookieId: string): string | null {
    return this.get(LocalStoragePrefix.COOKIES, cookieId);
  }

  static setWidgetShown(widgetID: string, configBegin: Date): void {
    this.set(configBegin.toISOString(), LocalStoragePrefix.WIDGET_SEEN, widgetID);
  }

  static hasWidgetBeenShown(widgetID: string, configBegin: Date): boolean {
    const lastConfigVersionShown = this.get(LocalStoragePrefix.WIDGET_SEEN, widgetID);
    if (!lastConfigVersionShown) {
      return false;
    }

    const expiredBegin = new Date(lastConfigVersionShown);
    // show widget if configured start time was increased
    return expiredBegin >= configBegin;
  }

  static getVisitorId(): string | null {
    return this.get(LocalStoragePrefix.TRACKING, TrackingData.VISITOR_ID);
  }

  static setVisitorId(visitorId: string): void {
    this.set(visitorId, LocalStoragePrefix.TRACKING, TrackingData.VISITOR_ID);
  }
}
