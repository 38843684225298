
import { defineComponent } from "vue";
import { Style } from "@/services/backend/generated/model/style";
import { venueStoreService } from "@/store/module-services";

export default defineComponent({
  computed: {
    style(): Style {
      return venueStoreService.getStyle();
    }
  }
});
