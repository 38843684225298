import "@/scss/vuetify.scss";
import "vue-virtual-scroller/dist/vue-virtual-scroller.css";
import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { initVuetify } from "./vuetify";
import VueVirtualScroller from "vue-virtual-scroller";
import { uiStoreService } from "./store/module-services";
import { applyStyling } from "./applyStyling";
import "@/app/loadFonts";
import VenuePollingService from "./services/venue-polling-service";
import initMatomoPlugin from "./backend/tracking/init-matomo-plugin";
import { createVueI18nAdapter } from "vuetify/lib/locale/adapters/vue-i18n.mjs";
import { getI18n, useI18n } from "./i18n/getI18n";

import "@ezra-virtualvenue-next/vvenue-chat/dist/style.css";
import VvenueChat from "@ezra-virtualvenue-next/vvenue-chat";
import { LocaleInstance } from "vuetify";

const app = createApp(App);
const i18n = getI18n();
const localeInstance = createVueI18nAdapter({ i18n, useI18n });

initMatomoPlugin(app);

app
  .use(store)
  .use(router)
  .use(VueVirtualScroller)
  .use(i18n)
  .use(VvenueChat)
  .use(initVuetify(localeInstance as unknown as LocaleInstance));

// trigger loading of various assets from the backend
// once the config is loaded, we can apply the styling defined in it and mount the app
uiStoreService
  .loadUiConfig()
  .then(() => applyStyling())
  .catch((err) => console.log("Failed to load UI config: ", err))
  .finally(() => app.mount("#app"));

VenuePollingService.watch();
