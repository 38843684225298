import { NavigationGuardNext, RouteLocationNormalized } from "vue-router";
import { venueStoreService } from "@/store/module-services";
import ensureVenue from "@/utility/ensure-venue";

/**
 * This guard redirects to the selected architecture and their default area.
 * This guard is usually defined on beforeEnter on the Application root "/".
 */
export async function toDefaultAreaRedirectGuard(
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
): Promise<void> {
  // ensure venue is loaded
  await ensureVenue();

  // Ensure that the desired architecture and area is in store available
  const venueArchitectureId = venueStoreService.getArchitectureId();
  const venueDefaultAreaId = venueStoreService.getDefaultArea()?.id;

  // We want to navigate to the area view, so we need a valid architectureId and areaId
  if (venueArchitectureId && venueDefaultAreaId) {
    // Go to desired area / page
    return next({
      name: "Area",
      params: {
        architectureId: venueArchitectureId,
        areaId: venueDefaultAreaId
      }
    });
  } else {
    // This should not happen with a valid architecture => routing is stopped
    console.error("Cannot navigate to default area without valid architecture & default area.");
    return next(false);
  }
}
