import { userStoreService, venueStoreService } from "@/store/module-services";
import { UserInfoAttributes } from "@/model/user-info";
import { UserProfileApi } from "@/services/backend-service";
import { AttendeeProfile } from "@/services/backend/generated/model/attendee-profile";
import LogCapturingService from "@/services/log-capturing-service";
import { generateUserNameFromEmail } from "@/utility/transform";
import { PresenceService } from "@/services/presence-service";
import router from "@/router";

export default class FetchUserProfileService {
  /**
   * Fetches and updates the userProfile for the current user
   */
  static async fetchUserProfile(): Promise<void> {
    const architectureId = venueStoreService.getArchitectureId();

    if (!architectureId) {
      return;
    }

    const user = userStoreService.getUser();
    if (!user?.userId) {
      return;
    }

    let attributes: UserInfoAttributes = {};
    try {
      const userProfile = (await UserProfileApi.getProfile(architectureId, user.userId)).data as AttendeeProfile;

      // using the typecast "woodhammer" (as unknown as),
      // since we know that the attributes are either string, boolean or missing(unknown)
      attributes = userProfile.attributes as unknown as UserInfoAttributes;
    } catch (e) {
      const error = e as Error;
      console.error("Could not fetch user profile. Continuing without.", error);
      await LogCapturingService.captureError(error.message, error.stack);
    }

    // ensure that at any time a user name is given
    // this is necessary for the presence widget and the user preview to look and work properly
    // if attributes can not deliver one, we create one from the email address
    if (!attributes.name) {
      attributes.name = generateUserNameFromEmail(user.email);
    }

    await userStoreService.setUser({
      ...user,
      attributes
    });
    const areaId = router.currentRoute.value.params.areaId as string;

    if (areaId) {
      await PresenceService.updateAttendee(areaId);
    }
  }
}
