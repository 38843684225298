import { RootState } from "@/store/RootState";
import { PresenceState } from "@/store/modules/presence/PresenceState";
import { TypedGetterFunctions } from "@/store/framework/TypedGetterFunctions";
import { AttendeeRef } from "@/model/attendee-ref";

/**
 * Defines all properties on the getters object that can be read from when working with the presence store slice.
 */
export interface PresenceGetters {
  readonly getAttendees: AttendeeRef[];
}

/**
 * Getter method implementations in the presence store slice.
 */
export const presenceGetters: TypedGetterFunctions<PresenceState, RootState, PresenceGetters> = {
  getAttendees: (state): AttendeeRef[] => {
    return state.attendees;
  }
};
