import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps } from "vue"

const _hoisted_1 = { class: "pl-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_list_item_avatar = _resolveComponent("base-list-item-avatar")!
  const _component_v_list_item_title = _resolveComponent("v-list-item-title")!
  const _component_v_list_item_subtitle = _resolveComponent("v-list-item-subtitle")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_list = _resolveComponent("v-list")!

  return (_openBlock(), _createBlock(_component_v_list, _mergeProps({
    dense: "",
    color: "background"
  }, _ctx.$attrs, { "data-testid": "user-summary" }), {
    default: _withCtx(() => [
      _createVNode(_component_v_list_item, null, {
        prepend: _withCtx(() => [
          _createVNode(_component_base_list_item_avatar, { initials: _ctx.initials }, null, 8, ["initials"])
        ]),
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_v_list_item_title, { class: "text--text font-weight-bold text-subtitle-2" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.user.attributes.name), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_v_list_item_subtitle, { class: "text--text font-weight-regular" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.user.email), 1)
              ]),
              _: 1
            }),
            (_ctx.user.attributes.title)
              ? (_openBlock(), _createBlock(_component_v_list_item_subtitle, {
                  key: 0,
                  class: "text--text font-weight-regular"
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", null, _toDisplayString(_ctx.user.attributes.title), 1)
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            (_ctx.user.attributes.company)
              ? (_openBlock(), _createBlock(_component_v_list_item_subtitle, {
                  key: 1,
                  class: "text--text font-weight-regular"
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", null, _toDisplayString(_ctx.user.attributes.company), 1)
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            (_ctx.areaId)
              ? (_openBlock(), _createBlock(_component_v_list_item_subtitle, {
                  key: 2,
                  class: "text--text font-italic font-weight-regular mt-2"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t("presence.contextMenu.inArea", { area: _ctx.getAreaName(_ctx.areaId) })), 1)
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true)
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 16))
}