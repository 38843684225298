import { Agent, load } from "@fingerprintjs/fingerprintjs";

let agent: Agent | null = null;

/**
 * Helper function to generate a browser fingerprint.
 * The generated fingerprints can look like this: f3958ba09c70cb9bc6856f37e7591d05
 */
export async function getFingerprint(): Promise<string> {
  if (!agent) {
    // initialize FingerprintJS
    agent = await load();
  }
  const result = await agent.get();

  return result.visitorId;
}
