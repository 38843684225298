/**
 * An enumeration of HTTP status codes.
 */

export enum HttpStatus {
  /**
   * Similar to 403 Forbidden, but specifically for use when authentication is required and has failed or
   * has not yet been provided. The response must include a WWW-Authenticate header field containing a
   * challenge applicable to the requested resource. 401 semantically means "unauthorised", the user does
   * not have valid authentication credentials for the target resource.
   */
  UNAUTHORIZED = 401,

  /**
   * The request contained valid data and was understood by the server, but the server is refusing action.
   * This may be due to the user not having the necessary permissions for a resource or needing an account
   * of some sort, or attempting a prohibited action (e.g. creating a duplicate record where only one is
   * allowed). This code is also typically used if the request provided authentication by answering the
   * WWW-Authenticate header field challenge, but the server did not accept that authentication. The request
   * should not be repeated.
   */
  FORBIDDEN = 403,

  /**
   * Indicates that the request could not be processed because of conflict in the current state of the
   * resource, such as an edit conflict between multiple simultaneous updates.
   */
  CONFLICT = 409
}
