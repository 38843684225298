import { uiStoreService } from "@/store/module-services";
import { getBaseTabTitle } from "./utility/base-tab-title";

/**
 * This function applies the styling options defined in the ui-config
 */
export function applyStyling(): void {
  // use the title that is defined in the ui-config or if missing fall back to the default -> 'magnid - Venue'
  document.title = getBaseTabTitle();

  // using the "woodhammer" to ensure that typescript understands that the href attribute exists
  // the href attribute holds the actual link to the favicon
  const favicon = document.getElementById("favicon") as unknown as {
    href: string;
  };
  if (favicon) {
    // and if the favicon is not set, we use the default, which is the magnid favicon
    favicon.href = `${window.location.origin}/${uiStoreService.getFavicon() ?? "img/magnid-favicon.png"}`;
  }
}
