import { RootState } from "@/store/RootState";
import { TypedAction } from "@/store/framework/TypedAction";
import { PresenceState } from "@/store/modules/presence/PresenceState";
import { PresenceGetters } from "@/store/modules/presence/presenceGetters";
import { FirebaseService } from "@/services/firebase-service";
import { VvenueOpMode } from "@/services/backend/generated/model/vvenue-op-mode";
import { AttendeeRef } from "@/model/attendee-ref";
import { makeSafeFirebaseKey } from "@/utility/make-safe-firebase-key";
import { PresenceMutation } from "./presenceMutations";

/**
 * An interface describing an update of an attendee.
 */
export interface AttendeeUpdate {
  /**
   * The current venue opmode (Stage/Live).
   */
  venueOpMode: VvenueOpMode | undefined;

  /**
   * The attendee that interacts with an area.
   */
  attendee: AttendeeRef;
}

/**
 * Defines all action names in the presence store slice.
 */
export enum PresenceAction {
  CONNECT = "CONNECT",
  UPDATE_ATTENDEE = "UPDATE_ATTENDEE",
  REMOVE_ATTENDEE = "REMOVE_ATTENDEE"
}

/**
 * Defines the action types in the presence store slice.
 */
interface PresenceActions {
  [PresenceAction.CONNECT]: VvenueOpMode | undefined;
  [PresenceAction.UPDATE_ATTENDEE]: AttendeeUpdate;
  [PresenceAction.REMOVE_ATTENDEE]: AttendeeUpdate;
}

/**
 * Defines types of all action functions in the presence store slice.
 */
type PresenceActionFunctions = {
  [name in keyof PresenceActions]: TypedAction<PresenceState, RootState, PresenceGetters, PresenceActions[name]>;
};

const OPMODE_UNKNOWN = "UNKNOWN";

function makeSafeKey(origKey: string): string {
  return makeSafeFirebaseKey(origKey);
}

function attendeesPath(venueOpMode: VvenueOpMode | undefined): string {
  return `opmode/${venueOpMode ?? OPMODE_UNKNOWN}`;
}

function attendeePath(areaUpdate: AttendeeUpdate): string {
  const prefix = attendeesPath(areaUpdate.venueOpMode);
  const safeUid = makeSafeKey(areaUpdate.attendee.uid);
  return `${prefix}/${safeUid}`;
}

/**
 * Action method implementations in the presence store slice.
 */
export const presenceActions: PresenceActionFunctions = {
  /**
   * Connect the vuex store to the firebase rtdb.
   * @param venueOpMode The venue op mode to connect to (STAGE/LIVE).
   */
  [PresenceAction.CONNECT]({ commit }, venueOpMode: VvenueOpMode | undefined): void {
    const attendeeRef = FirebaseService.rtdb().ref(attendeesPath(venueOpMode));
    // removing old listeners
    attendeeRef.off();
    attendeeRef.on("value", (snap) => commit(PresenceMutation.SET_ATTENDEES, Object.values(snap.val() ?? {})));
  },

  /**
   * Stores information about an attendee.
   *
   * @param _ Unused.
   * @param attendeeUpdate Information about attendee update.
   */
  async [PresenceAction.UPDATE_ATTENDEE](_, attendeeUpdate: AttendeeUpdate): Promise<void> {
    // move the attendee to the new area (remove on disconnect)
    const presenceRef = FirebaseService.rtdb().ref(attendeePath(attendeeUpdate));
    await presenceRef.onDisconnect().remove();
    await presenceRef.set({
      ...attendeeUpdate.attendee,
      timestamp: Date.now()
    });
  },

  /**
   * Removes an attendee from the presence database.
   *
   * @param _ unused.
   * @param attendeeUpdate Information about attendee update.
   */
  async [PresenceAction.REMOVE_ATTENDEE](_, attendeeUpdate: AttendeeUpdate): Promise<void> {
    // remove the attendee's presence from the venue
    const presenceRef = FirebaseService.rtdb().ref(attendeePath(attendeeUpdate));
    await presenceRef.remove();
  }
};
