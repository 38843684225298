
import { defineComponent, PropType } from "vue";
import BaseListItemAvatar from "@/ui/base/BaseListItemAvatar.vue";
import getInitials from "@/utility/get-initials";
import { AttendeeRef } from "@/model/attendee-ref";
import { getAreaName } from "@/utility/get-area-name";

export default defineComponent({
  components: { BaseListItemAvatar },
  props: {
    attendee: {
      type: Object as PropType<AttendeeRef>,
      required: true
    }
  },
  computed: {
    initials(): string {
      return getInitials(this.attendee.name);
    }
  },
  methods: {
    getAreaName
  }
});
