import { makeSafeFirebaseKey } from "@/utility/make-safe-firebase-key";
import { generateUUIDDatePrefix } from "@/utility/uuid-date-prefix";
import { ContentPiecePartRef } from "./content-piece-ref";
import { UserInfo } from "./user-info";

/**
 * Model of stored to firebase data
 */
export interface MediaBoardLikeRef extends ContentPiecePartRef {
  /**
   * uuid is automatically generated field during saving like to db
   */
  uuid: string;
  mediaBoardId: string;
  commentId?: string;
  authorId: string;
  creationTimestamp: number;
}

/**
 * Model of item of vuex store
 */
export interface MediaBoardLikeVuexRef extends ContentPiecePartRef {
  uuid: string;
  /**
   * Key is used to Map and Set creation
   */
  key: string;
  commentId?: string;
}

export function createLike(
  user: UserInfo,
  contentPieceId: string,
  mediaBoardId: string,
  commentId?: string
): MediaBoardLikeRef {
  const createDate = new Date();
  const like: MediaBoardLikeRef = {
    uuid: getLikeUuid(createDate, user.userId, contentPieceId, commentId),
    contentPieceId,
    mediaBoardId,
    creationTimestamp: Date.now(),
    authorId: user.userId
  };

  if (commentId) {
    like.commentId = commentId;
  }

  return like;
}

export function getLikeKey(contentPieceId: string, commentId?: string): string {
  let key = `CP_${contentPieceId}`;

  if (commentId) {
    key += `_CM_${commentId}`;
  }

  return key;
}

function getLikeUuid(createDate: Date, userId: string, contentPieceId: string, commentId?: string): string {
  // UUID has to start with date prefix for limitToLast() functionality
  return makeSafeFirebaseKey(
    `${generateUUIDDatePrefix(createDate)}_${getLikeKey(contentPieceId, commentId)}_U_${userId}`
  );
}
