/**
 * Provides functionality to capture frontend errors occurred clientside
 * and send them to the backend to be logged persistently.
 */
import { LogCapturingApi } from "@/services/backend-service";
import { ClientDetails } from "@/services/backend/generated/model/client-details";
import { uiStoreService, userStoreService, venueStoreService } from "@/store/module-services";
import {
  FrontendMessage,
  FrontendMessageLoglevelEnum,
  FrontendMessageMessageOriginEnum
} from "@/services/backend/generated/model/frontend-message";
import { CookiePreferencesMessage } from "@/services/backend/generated/model/cookie-preferences-message";
import { FrontendCookieProvider } from "@/model/frontend-cookie-provider";
import { LogCookieProvider } from "@/services/backend/generated/model/log-cookie-provider";
import { getFingerprint } from "@/utility/fingerprint";
import { getOS, getBrowserName, getBrowserVersion } from "@/utility/browser";

export default class LogCapturingService {
  /**
   * Captures an error, adds client metadata and sends it to the backend.
   *
   * @param message The error message
   * @param details The error details aka stacktrace
   * @param category The category to tag this error
   */
  static async captureError(message: string, details?: string, category?: string): Promise<void> {
    return this.sendCapture(FrontendMessageLoglevelEnum.ERROR, message, details, category);
  }

  /**
   * Captures a warning, adds client metadata and sends it to the backend.
   *
   * @param message The warning message
   * @param details The warning details
   * @param category The category to tag this warning
   */
  static async captureWarning(message: string, details?: string, category?: string): Promise<void> {
    return this.sendCapture(FrontendMessageLoglevelEnum.WARN, message, details, category);
  }

  /**
   * Captures an info, adds client metadata and sends it to the backend.
   *
   * @param message The info message
   * @param details The info details
   * @param category The category to tag this info
   */
  static async captureInfo(message: string, details?: string, category?: string): Promise<void> {
    return this.sendCapture(FrontendMessageLoglevelEnum.INFO, message, details, category);
  }

  /**
   * Tells backend to log billing relevant entry about an anonymous attendee accessing the venue.
   */
  static async captureAnonymousLogin(): Promise<void> {
    if (!uiStoreService.isLoginEnabled()) {
      await LogCapturingApi.captureAnonymousLogin();
    }
  }

  /**
   * Logs the selected cookie preferences in the backend.
   */
  static async captureCookiePreferences(): Promise<void> {
    // use user id or browser fingerprint if absent
    const userId = userStoreService.getUser()?.userId ?? (await getFingerprint());
    const email = userStoreService.getUser()?.email;
    const bannerType = venueStoreService.getCookieBannerConfig()?.type;
    const cookiePreferences = venueStoreService.getCookieProviders().map((p) => this.mapToLogProvider(p));
    const clientDetails = this.generateClientDetails();

    const logEntry: CookiePreferencesMessage = {
      userId,
      email,
      bannerType,
      cookiePreferences,
      clientDetails
    };
    await LogCapturingApi.captureCookiePreferences(logEntry);
  }

  /**
   * Helper function to strip the "attributes" field off of the cookie provider.
   * @param cookieProvider the cookie provider to map
   * @private
   */
  private static mapToLogProvider(cookieProvider: FrontendCookieProvider): LogCookieProvider {
    return {
      id: cookieProvider.id,
      name: cookieProvider.name,
      type: cookieProvider.type,
      accepted: cookieProvider.accepted,
      defaultProvider: cookieProvider.defaultProvider
    };
  }

  /**
   * Internal method to send captured log event to backend
   * @param level The level to log this log event in the backend
   * @param logCategory The category to tag this log event
   * @param message The log event message summary
   * @param details The log event message details
   * @private
   */
  private static async sendCapture(
    level: FrontendMessageLoglevelEnum,
    message: string,
    details?: string,
    logCategory?: string
  ): Promise<void> {
    if (this.isLogCapturingServiceAvailable()) {
      const messageObject: FrontendMessage = {
        messageDetails: {
          message,
          details
        },
        clientDetails: this.generateClientDetails(),
        loglevel: level,
        logCategory,
        messageOrigin: FrontendMessageMessageOriginEnum.UI
      };
      await LogCapturingApi.captureLog(messageObject);
    } else {
      switch (level) {
        case FrontendMessageLoglevelEnum.ERROR:
          console.error(message, details);
          break;
        case FrontendMessageLoglevelEnum.WARN:
          console.warn(message, details);
          break;
        case FrontendMessageLoglevelEnum.INFO:
        default:
          console.info(message, details);
          break;
      }
    }
    return new Promise((resolve) => resolve());
  }

  /**
   * Checks whether the log-capturing service can be invoked.
   *
   * @return {boolean} True if the backend service can be called; false otherwise.
   * @private
   */
  private static isLogCapturingServiceAvailable(): boolean {
    return (
      uiStoreService.isLoginEnabled() === true &&
      userStoreService.isLoggedIn() &&
      userStoreService.getAuthToken() !== undefined
    );
  }

  /**
   * Internal method to determine client details like browser specs, operating system, current URL, enabled cookies, etc.
   * @private
   */
  private static generateClientDetails(): ClientDetails {
    return {
      browser: getBrowserName(),
      browserVersion: getBrowserVersion(),
      os: getOS(),
      userAgent: navigator.userAgent,
      browserLanguage: navigator.language,
      cookiesEnabled: navigator.cookieEnabled,
      venueLanguage: uiStoreService.getDefaultLocale(),
      architectureId: venueStoreService.getArchitectureId(),
      clientUsername: uiStoreService.isLoginEnabled() ? userStoreService.getUser()?.email ?? "ANONYM" : "ANONYM",
      clientURL: window.location.pathname,
      clientVersion: uiStoreService.getAppVersion()
    };
  }
}
