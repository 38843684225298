import { Module } from "vuex";
import { RootState } from "@/store/RootState";
import { uiMutations } from "@/store/modules/ui/uiMutations";
import { uiActions } from "@/store/modules/ui/uiActions";
import { uiGetters } from "@/store/modules/ui/uiGetters";
import { UiState } from "@/store/modules/ui/UiState";
import { APP_BAR_HEIGHT } from "@/ui/components/constants";

function initialUiState(): UiState {
  return {
    error: {
      globalError: { hasErrors: false },
      suppressDefaultErrorDialog: false
    },
    appVersion: process.env.VUE_APP_VERSION || "0",
    headerHeight: APP_BAR_HEIGHT,
    uiConfig: undefined,
    venueVersion: undefined,
    loginEnabled: undefined,
    loginConfigurations: [],
    lastAreaId: undefined,
    serverTime: new Date(),
    activeDialogStack: [],
    chat: {
      opened: false,
      expanded: false,
      roomChats: {},
      attendeeToChatUid: undefined
    },
    widgetsWithNavigationGuards: [],
    wantToNavigate: false,
    routerGuardsChecking: false
  };
}

const uiModule: Module<UiState, RootState> = {
  namespaced: true,
  state: initialUiState(),
  mutations: uiMutations,
  actions: uiActions,
  getters: uiGetters
};

export default uiModule;
