import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-c9ecc5b2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "d-flex flex-column scroll-container" }
const _hoisted_2 = {
  key: 0,
  class: "scroller"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_attendee_card = _resolveComponent("attendee-card")!
  const _component_dynamic_scroller_item = _resolveComponent("dynamic-scroller-item")!
  const _component_dynamic_scroller = _resolveComponent("dynamic-scroller")!
  const _component_v_slider = _resolveComponent("v-slider")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_dynamic_scroller, {
      ref: "dynamicScroller",
      items: _ctx.attendeeList,
      "key-field": "uid",
      "min-item-size": _ctx.MIN_ATTENDEE_CARD_WIDTH,
      direction: "horizontal",
      "data-testid": "attendee-list",
      class: "flex-grow-1 align-center",
      "emit-update": _ctx.enableScrollerUpdates,
      onResize: _ctx.onResize,
      onUpdate: _ctx.updateSliderPosition,
      onVisible: _ctx.updateRefs
    }, {
      default: _withCtx(({ item, index, active }) => [
        _createVNode(_component_dynamic_scroller_item, {
          item: item,
          "data-index": index,
          active: active,
          class: "d-flex flex-column"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_attendee_card, {
              attendee: item,
              "is-loading-attendee-profile": _ctx.isLoadingAttendeeProfile,
              "attendee-id-to-load": _ctx.attendeeIdToLoad,
              active: active,
              onMenuToggled: _ctx.onMenuToggle,
              onOpenProfileForAttendee: _ctx.openProfileForAttendee,
              onStartChat: _ctx.startChat
            }, null, 8, ["attendee", "is-loading-attendee-profile", "attendee-id-to-load", "active", "onMenuToggled", "onOpenProfileForAttendee", "onStartChat"])
          ]),
          _: 2
        }, 1032, ["item", "data-index", "active"])
      ]),
      _: 1
    }, 8, ["items", "min-item-size", "emit-update", "onResize", "onUpdate", "onVisible"]),
    (_ctx.scrollbarVisible)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_v_slider, {
            "thumb-size": "10",
            "track-size": "2",
            density: "compact",
            modelValue: _ctx.sliderValue,
            "onUpdate:modelValue": [
              _cache[0] || (_cache[0] = ($event: any) => ((_ctx.sliderValue) = $event)),
              _ctx.scrollToItem
            ],
            class: "slider",
            max: _ctx.sliderMax,
            min: _ctx.sliderMin
          }, null, 8, ["modelValue", "max", "min", "onUpdate:modelValue"])
        ]))
      : _createCommentVNode("", true)
  ]))
}