// Service to access the tracking client.
import { venueStoreService, uiStoreService } from "@/store/module-services";
import TrackingClientFactory from "@/backend/tracking/tracking-client-factory";
import TrackingClient from "@/backend/tracking/tracking-client";
import { RootState } from "@/store/RootState";
import store from "@/store";
import { venueGetters } from "@/store/modules/venue/venueGetters";
import { userGetters } from "@/store/modules/user/userGetters";
import isEqual from "@/utility/is-equal";
import { StoreModuleName } from "@/store/StoreModuleName";
import { UserInfo } from "@/model/user-info";
import { getAreaName } from "@/utility/get-area-name";

export type TrackingEvent<T = unknown> = T & {
  areaId: string;
  widgetType: string;
  widgetName: string;
  sciName?: string;
};

export type UserPresenceTrackingEvent = TrackingEvent<{
  widgetType: "Presence";
  widgetName: "Presence";
  action: string;
}>;

export enum ProfileType {
  UserProfile = "UserProfile",
  AttendeeProfile = "AttendeProfile"
}

export enum ProfileEvent {
  OpenProfile = "Open Profile",
  UpdateProfile = "Update Profile",
  CloseProfile = "Close Profile"
}

export type SCITrackingEvent<T = unknown> = TrackingEvent<T & { sciName: string }>;

interface TrackingContext {
  personalizedTrackingEnabled: boolean;
  trackingConsentGiven: boolean;
  userId?: string; // typically the users email address
}

// typing the signature of store.watch()
type WatchBuilder<T> = (state: RootState, getters: Record<string, unknown>) => T;
type WatchCallback<T> = (newVal: T, oldVal: T) => void;
type UnwatchFn = () => void;
type StoreWatch<T> = (fn: WatchBuilder<T>, callback: WatchCallback<T>) => UnwatchFn;

/**
 * Builds a tracking context from the given root state and root getters.
 *
 * This function can be used as the first argument to `store.watch`.
 *
 * @param {RootState} state The root state.
 * @param {Record<string, never>} getters The root getters.
 * @return {TrackingContext} The tracking context.
 */
function buildTrackingContext(state: RootState, getters: Record<string, unknown>): TrackingContext {
  const isPersonalizedTrackingEnabled = `${StoreModuleName.venue}/${venueGetters.isPersonalizedTrackingEnabled.name}`;
  const isTrackingConsentGiven = `${StoreModuleName.user}/${userGetters.isTrackingConsentGiven.name}`;
  const getUser = `${StoreModuleName.user}/${userGetters.getUser.name}`;

  return {
    personalizedTrackingEnabled:
      !!uiStoreService.isLoginEnabled() && (getters[isPersonalizedTrackingEnabled] as boolean),
    trackingConsentGiven: getters[isTrackingConsentGiven] as boolean,
    userId: (getters[getUser] as UserInfo | undefined)?.email
  };
}

/**
 * Provides tracking functionality.
 */
export default class TrackingService {
  private static readonly trackingClient: TrackingClient = TrackingClientFactory.createTrackingClient();
  private static unwatchFn?: UnwatchFn = undefined;

  /**
   * Returns the current venue's architecture id.
   *
   * @return Architecture id.
   */
  private static getArchitectureId(): string {
    return venueStoreService.getArchitectureId() ?? "";
  }

  /**
   * Tracks a successful login of a user (personalized or anonymous).
   *
   * @param emailAddress The email address or 'undefined' for anonymous.
   */
  static trackLoginEvent(emailAddress: string | undefined): void {
    this.trackingClient.trackLogin(uiStoreService.getOpMode(), emailAddress);
  }

  /**
   * Tracks the event of opening an area widget.
   *
   * @param event The metadata describing the event.
   */
  static trackOpenAreaWidgetEvent(event: SCITrackingEvent): void {
    const areaName = getAreaName(event.areaId);
    this.trackingClient.trackEvent(
      `${this.getArchitectureId()} ${event.widgetType}`,
      `${areaName} - ${event.widgetName} - ${event.sciName}`,
      `${event.widgetName} - ${event.sciName}`
    );
  }

  /**
   * Tracks the event of opening a global widget.
   *
   * @param event The metadata describing the event.
   */
  static trackOpenGlobalWidgetEvent(event: TrackingEvent): void {
    const areaName = getAreaName(event.areaId);
    this.trackingClient.trackEvent(
      `${this.getArchitectureId()} ${event.widgetType}`,
      `${areaName} - ${event.widgetName} - Global`,
      `${event.widgetName} - Global`
    );
  }

  /**
   * Tracks the event of accessing an url.
   *
   * @param event The metadata describing the event.
   */
  static trackAccessUrlEvent(event: SCITrackingEvent<{ targetUrl: string }>): void {
    const areaName = getAreaName(event.areaId);
    this.trackingClient.trackEvent(
      `${this.getArchitectureId()} ${event.widgetType}`,
      `${areaName} - ${event.widgetName} - ${event.sciName}`,
      `Content: ${event.targetUrl}`
    );
  }

  /**
   * Tracks the event of accessing an asset.
   *
   * @param event The metadata describing the event.
   */
  static trackAccessAssetEvent(event: SCITrackingEvent<{ assetId: string }>): void {
    const areaName = getAreaName(event.areaId);
    const assetName = venueStoreService.getAssetById(event.assetId)?.displayName ?? "";
    this.trackingClient.trackEvent(
      `${this.getArchitectureId()} ${event.widgetType}`,
      `${areaName} - ${event.widgetName} - ${event.sciName}`,
      `Content: ${assetName}`
    );
  }

  /**
   * Tracks the event of starting to play a vimeo video.
   *
   * @param event The metadata describing the event.
   */
  static trackAccessPlayVimeoEvent(event: SCITrackingEvent<{ videoTitle: string; videoUrl: string }>): void {
    const areaName = getAreaName(event.areaId);
    this.trackingClient.trackEvent(
      `${this.getArchitectureId()} ${event.widgetType}`,
      `${areaName} - ${event.widgetName} - ${event.sciName}`,
      `Content: ${event.videoTitle && event.videoTitle + " - "}${event.videoUrl}`
    );
  }

  /**
   * Tracks the event of accessing an area chat widget.
   *
   * @param event The metadata describing the event.
   */
  static trackAccessAreaChatEvent(event: SCITrackingEvent<{ channelNames: string[] }>): void {
    const areaName = getAreaName(event.areaId);
    this.trackingClient.trackEvent(
      `${this.getArchitectureId()} ${event.widgetType}`,
      `${areaName} - ${event.widgetName} - ${event.sciName}`,
      `Content: Chat ${JSON.stringify(event.channelNames)}`
    );
  }

  /**
   * Tracks the event of accessing a global chat widget.
   *
   * @param event The metadata describing the event.
   */
  static trackAccessGlobalChatEvent(event: TrackingEvent): void {
    const areaName = getAreaName(event.areaId);
    this.trackingClient.trackEvent(
      `${this.getArchitectureId()} ${event.widgetType}`,
      `${areaName} - ${event.widgetName}`,
      "Content: Chat Global"
    );
  }

  /**
   * Tracks the event of accessing an area navigation within the floorplan widget.
   *
   * @param event The metadata describing the event.
   */
  static trackAccessFloorplanNavigationEvent(event: TrackingEvent<{ targetAreaId: string }>): void {
    const areaName = getAreaName(event.areaId);
    const targetAreaName = getAreaName(event.targetAreaId);
    this.trackingClient.trackEvent(
      `${this.getArchitectureId()} ${event.widgetType}`,
      `${areaName} - ${event.widgetName}`,
      `Content: ${targetAreaName}`
    );
  }

  /**
   * Tracks the event of downloading an asset through a widget.
   *
   * @param event The metadata describing the event.
   */
  static trackDownloadEvent(event: SCITrackingEvent<{ assetId: string; fileName: string }>): void {
    const areaName = getAreaName(event.areaId);
    this.trackingClient.trackEvent(
      `Download - ${this.getArchitectureId()} ${event.widgetType}`,
      `${areaName} - ${event.widgetName} - ${event.sciName}`,
      `Content: ${event.assetId} - FileName: ${event.fileName}`
    );
  }

  static trackPresenceEvent(event: UserPresenceTrackingEvent): void {
    const areaName = getAreaName(event.areaId);
    this.trackingClient.trackEvent(
      `${this.getArchitectureId()} ${event.widgetType}`,
      `${areaName} - ${event.widgetName} - Global`,
      `Content: ${event.action}`
    );
  }

  static trackProfileEvent(areaId: string, type: ProfileType, event: ProfileEvent): void {
    const areaName = getAreaName(areaId);
    this.trackingClient.trackEvent(`${this.getArchitectureId()} Profile`, `${areaName} - ${type}`, `Content: ${event}`);
  }

  /**
   * Tracks the event of opening a content Card.
   *
   * @param event The metadata describing the event.
   */
  static trackOpenContentCardEvent(event: SCITrackingEvent<{ categoryTitle: string; contentCard: string }>): void {
    const areaName = getAreaName(event.areaId);
    this.trackingClient.trackEvent(
      `${this.getArchitectureId()} ${event.widgetType}`,
      `${areaName} - ${event.widgetName} - ${event.sciName}`,
      `Category: ${event.categoryTitle} - ContentCard: - ${event.contentCard}`
    );
  }

  /**
   * Tracks the event of opening a main content Card (name of asset or link).
   *
   * @param event The metadata describing the event.
   */
  static trackOpenAssetOrLinkContentCardEvent(
    event: SCITrackingEvent<{
      categoryTitle: string;
      contentCard: string;
      contentValue: string;
      contentType: string;
      additionalInformation: string;
    }>
  ): void {
    const areaName = getAreaName(event.areaId);
    this.trackingClient.trackEvent(
      `${this.getArchitectureId()} ${event.widgetType}`,
      `${areaName} - ${event.widgetName} - ${event.sciName} - Category: ${event.categoryTitle} - ContentCard: ${event.contentCard} ${event.additionalInformation}`,
      `${event.contentType}: - ${event.contentValue}`
    );
  }

  /**
   * Tracks the event of opening a main content Card (name of asset or link).
   *
   * @param event The metadata describing the event.
   */
  static trackLikeOrDislikeContentCardEvent(
    event: SCITrackingEvent<{
      categoryTitle: string;
      contentCard: string;
      likeType: string;
    }>
  ): void {
    const areaName = getAreaName(event.areaId);
    this.trackingClient.trackEvent(
      `${this.getArchitectureId()} ${event.widgetType} ${event.likeType}s`,
      `${areaName} - ${event.widgetName} - ${event.sciName} - Category: ${event.categoryTitle}`,
      `${event.likeType} ContentCard: - ${event.contentCard}`
    );
  }

  static getUserId(): Promise<string | undefined> {
    return this.timeoutCall(this.trackingClient.getUserId(), undefined);
  }

  static getVisitorId(): Promise<string | undefined> {
    return this.timeoutCall(this.trackingClient.getVisitorId(), undefined);
  }

  // The container that hosts the analytics service can sleep, so we retry the loading of it.
  // The knock on effect is that there is a small possiblilty the tracker does not load. To
  // guard against a long wait, this funciton will return quickly to allow components to render.
  static timeoutCall<T>(fn: Promise<T>, defaultValue: T): Promise<T> {
    const timeout: Promise<T> = new Promise((resolve) => setTimeout(() => resolve(defaultValue), 250));
    return Promise.race([fn, timeout]);
  }

  /**
   * Start watching the store for tracking-relevant updates.
   *
   * @param {StoreWatch<TrackingContext>} watchFn Only for testing: allows mocking the store.watch function.
   */
  static watch(watchFn: StoreWatch<TrackingContext> = (fn, cb) => store.watch(fn, cb)): void {
    if (!this.unwatchFn) {
      this.unwatchFn = watchFn(buildTrackingContext, (newVal, oldVal) => this.updateTracker(newVal, oldVal));
    }
  }

  /**
   * Stop watching the store for tracking-relevant updates.
   */
  static unwatch(): void {
    if (this.unwatchFn) {
      this.unwatchFn();
      this.unwatchFn = undefined;
    }
  }

  /**
   * Updates the current tracker based on the old and new tracking context.
   *
   * @param newVal The new tracking context.
   * @param oldVal The old tracking context.
   * @private
   */
  private static updateTracker(newVal: TrackingContext, oldVal: TrackingContext): void {
    // if there are no changes between old and new tracking context, then exit early
    if (isEqual(newVal, oldVal)) {
      return;
    }

    if (newVal.personalizedTrackingEnabled && newVal.trackingConsentGiven && newVal.userId !== undefined) {
      console.info(`Enabling personalized tracking for ${newVal.userId}.`);
      this.trackingClient.setUserId(newVal.userId);
    } else if (oldVal.userId !== undefined && newVal.userId === undefined) {
      console.info("Disabling personalized tracking: user has logged out.");
      this.trackingClient.resetUserId();
    } else if (oldVal.trackingConsentGiven && !newVal.trackingConsentGiven) {
      console.info("Disabling personalized tracking: user has revoked tracking consent.");
      this.trackingClient.resetUserId();
    } else if (oldVal.personalizedTrackingEnabled && !newVal.personalizedTrackingEnabled) {
      console.info("Disabling personalized tracking: venue has disabled personalized tracking.");
      this.trackingClient.resetUserId();
    } else {
      console.debug("No changes to personalized tracking.");
    }
  }
}
