import { AxiosError, AxiosResponse } from "axios";
import { ApiError } from "@/services/backend/generated/model/api-error";
import { ErrorCode } from "@/store/modules/ui/UiState";
import { uiStoreService } from "@/store/module-services";
import { UserService } from "@/services/user-service";
import { HttpStatus } from "@/services/axios/http-status";

/**
 * Global handler for Axios errors.
 *
 * @param error the error to handle
 */
export async function handleError(error: AxiosError<ApiError>): Promise<AxiosResponse<unknown>> {
  const errorCode: ErrorCode = getErrorCodeForResponse(error.response);

  // Update state
  await uiStoreService.setError(errorCode, error.response?.data?.errMsg ?? "");

  // Log out user on auth related errors because likely the session is invalid
  const status = error.response?.status;
  if (status === HttpStatus.UNAUTHORIZED || status === HttpStatus.FORBIDDEN) {
    console.warn(`Got response code ${status}. Logging out user.`);
    await UserService.logOut();
  }

  return Promise.reject(error);
}

/**
 * Maps the given backend response to a business-level {@link ErrorCode}.
 *
 * @param response The response object to inspect.
 */
function getErrorCodeForResponse(response: AxiosResponse<unknown> | undefined): ErrorCode {
  if (response) {
    return getErrorCodeForStatusCode(response.status);
  } else {
    return ErrorCode.NOT_REACHABLE;
  }
}

/**
 * Maps the backend response's status code to a business-level {@link ErrorCode}.
 *
 * @param statusCode The HTTP status code to inspect.
 */
function getErrorCodeForStatusCode(statusCode: number): ErrorCode {
  switch (statusCode) {
    case HttpStatus.UNAUTHORIZED:
      return ErrorCode.UNAUTHORIZED;
    case HttpStatus.FORBIDDEN:
      return ErrorCode.FORBIDDEN;
    default:
      return ErrorCode.PROCESSING_ERROR;
  }
}
