// Activates the Vue router and defines all the routes.

import {
  createRouter,
  createWebHistory,
  NavigationGuardNext,
  RouteLocationNormalized,
  RouteRecordRaw
} from "vue-router";
import { MandatoryPagesEnum } from "@/model/mandatory-pages";
import { ensureArchitectureBeforeGuard } from "@/router/ensureArchitectureBeforeGuard";
import { toDefaultAreaRedirectGuard } from "@/router/toDefaultAreaRedirectGuard";
import { ensureAreaBeforeGuard } from "@/router/ensureAreaBeforeGuard";
import { workInProgressGuard } from "@/router/workInProgressGuard";
import { loginGuard } from "@/router/loginGuard";
import { cookieBannerGuard } from "@/router/cookieBannerGuard";
import { uiStoreService } from "@/store/module-services";

export const routes: Array<RouteRecordRaw> = [
  {
    path: "/work-in-progress",
    name: "WorkInProgress",
    // dynamically load component and compile it into named chunk
    component: () => import(/* webpackChunkName: "WorkInProgressView" */ "@/ui/views/WorkInProgressView.vue"),
    meta: { requiresAuth: false, analyticsIgnore: true }
  },
  {
    path: "/login",
    name: "login",
    component: () => import(/* webpackChunkName: "LoginView" */ "@/ui/views/LoginView.vue"),
    meta: { requiresAuth: false, analyticsIgnore: true }
  },
  {
    path: "/",
    name: "FallbackToDefaultArea",
    // this guard will redirect to the selected (or active) area and the default area.
    beforeEnter: toDefaultAreaRedirectGuard,
    component: { template: "<div />" }
  },
  {
    path: "/v/:architectureId",
    name: "ArchitectureView",
    // it's not possible to have an abstract route without component, so we render inline a router-view for all children
    component: { template: "<router-view />" },
    // this guard will ensure a architecture is set and redirect to AREA
    beforeEnter: ensureArchitectureBeforeGuard,
    children: [
      {
        path: "imprint",
        name: MandatoryPagesEnum.IMPRINT,
        component: () => import(/* webpackChunkName: "MandatoryPageView" */ "@/ui/views/MandatoryPageView.vue"),
        props: { page: MandatoryPagesEnum.IMPRINT }
      },
      {
        path: "privacy-policy",
        name: MandatoryPagesEnum.PRIVACY_POLICY,
        component: () => import(/* webpackChunkName: "MandatoryPageView" */ "@/ui/views/MandatoryPageView.vue"),

        props: { page: MandatoryPagesEnum.PRIVACY_POLICY }
      },
      {
        path: "area/:areaId?",
        name: "Area",
        component: () => import(/* webpackChunkName: "AreaView" */ "@/ui/views/AreaView.vue"),
        props: true,
        beforeEnter: ensureAreaBeforeGuard
      },
      {
        path: "area-whereby-meeting/:areaId/:widgetId/:configId",
        name: "AreaWherebyMeeting",
        component: () =>
          import(
            /* webpackChunkName: "WherebyMeetingWidgetLinkContainer" */ "@/components/widgets/whereby-meeting/WherebyMeetingWidgetLinkContainer.vue"
          ),
        props: true,
        beforeEnter: ensureAreaBeforeGuard
      }
    ]
  },
  {
    path: "/:architectureId/area/:areaId",
    redirect: (to) => {
      return {
        name: "Area",
        params: { architectureId: to.params.architectureId, areaId: to.params.areaId }
      };
    }
  },
  {
    path: "/:architectureId/imprint",
    redirect: (to) => {
      return {
        name: MandatoryPagesEnum.IMPRINT,
        params: { architectureId: to.params.architectureId }
      };
    }
  },
  {
    path: "/:architectureId/privacy-policy",
    redirect: (to) => {
      return {
        name: MandatoryPagesEnum.PRIVACY_POLICY,
        params: { architectureId: to.params.architectureId }
      };
    }
  }
];

const router = createRouter({
  // Activate history mode to get rid of # in urls - see https://router.vuejs.org/guide/essentials/history-mode.html
  history: createWebHistory(),
  routes
});

// the global before guards are called in creation order, whenever a navigation is triggered
router.beforeEach((to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
  // eslint-disable-next-line @typescript-eslint/no-floating-promises
  uiStoreService.waitRouterGuards();
  next();
});
router.beforeEach(workInProgressGuard);
router.beforeEach(loginGuard);
router.beforeEach(cookieBannerGuard);
router.afterEach(() => uiStoreService.routerGuardsFinished());

export default router;
