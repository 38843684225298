
import { defineComponent, PropType } from "vue";
import { UserInfo } from "@/model/user-info";
import UserSummary from "@/ui/components/user-preview/UserSummary.vue";

export default defineComponent({
  components: { UserSummary },
  props: {
    user: {
      type: Object as PropType<UserInfo>,
      required: true
    },
    areaId: {
      type: String,
      required: true
    },
    cookieBannerEnabled: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    openUserProfile() {
      this.close();
      this.$emit("open-user-profile");
    },
    openPrivacyPreferences() {
      this.close();
      this.$emit("open-privacy-preferences");
    },
    close() {
      this.$emit("close");
    }
  }
});
