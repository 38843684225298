import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_attendee_summary = _resolveComponent("attendee-summary")!
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_v_list_item_title = _resolveComponent("v-list-item-title")!
  const _component_v_progress_circular = _resolveComponent("v-progress-circular")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_list = _resolveComponent("v-list")!
  const _component_v_card = _resolveComponent("v-card")!

  return (_openBlock(), _createBlock(_component_v_card, {
    color: "background",
    "data-testid": `attendee-context-menu-content[${_ctx.attendee.uid}]`
  }, {
    default: _withCtx(() => [
      _createVNode(_component_attendee_summary, { attendee: _ctx.attendee }, null, 8, ["attendee"]),
      _createVNode(_component_v_divider),
      _createVNode(_component_v_list, {
        density: "compact",
        color: "background"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_list_item, {
            "data-testid": "user-profile-link",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.openAttendeeProfile()))
          }, {
            append: _withCtx(() => [
              (_ctx.isLoadingAttendeeProfile)
                ? (_openBlock(), _createBlock(_component_v_progress_circular, {
                    key: 0,
                    indeterminate: "",
                    class: "align-self-center"
                  }))
                : (_openBlock(), _createBlock(_component_v_icon, {
                    key: 1,
                    class: "text--text"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("mdi-cog")
                    ]),
                    _: 1
                  }))
            ]),
            default: _withCtx(() => [
              _createVNode(_component_v_list_item_title, { class: "text--text" }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t("presence.contextMenu.actions.goToProfile")), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_v_list_item, { onClick: _ctx.startChat }, {
            append: _withCtx(() => [
              _createVNode(_component_v_icon, { class: "text--text" }, {
                default: _withCtx(() => [
                  _createTextVNode("mdi-chat")
                ]),
                _: 1
              })
            ]),
            default: _withCtx(() => [
              _createVNode(_component_v_list_item_title, { class: "text--text" }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t("presence.contextMenu.actions.startChat")), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["onClick"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["data-testid"]))
}