import { TypedMutation } from "@/store/framework/TypedMutation";
import { CookieBannerState } from "@/store/modules/cookieBanner/cookieBannerState";

export enum CookieBannerMutation {
  SET_VISIBLE = "SET_VISIBLE",
  SET_NOTIFICATION_VISIBLE = "SET_NOTIFICATION_VISIBLE"
}

interface CookieBannerMutations {
  [CookieBannerMutation.SET_VISIBLE]: boolean;
  [CookieBannerMutation.SET_NOTIFICATION_VISIBLE]: boolean;
}

type CookieBannerMutationFunctions = {
  [name in keyof CookieBannerMutations]: TypedMutation<CookieBannerState, CookieBannerMutations[name]>;
};

export const cookieBannerMutations: CookieBannerMutationFunctions = {
  [CookieBannerMutation.SET_VISIBLE](state: CookieBannerState, value: boolean) {
    state.visible = value;
  },

  [CookieBannerMutation.SET_NOTIFICATION_VISIBLE](state: CookieBannerState, value: boolean) {
    state.notificationVisible = value;
  }
};
