
import { defineComponent } from "vue";
import { MandatoryPagesEnum } from "@/model/mandatory-pages";
import { AttendeeRef } from "@/model/attendee-ref";
import { cookieBannerStoreService, userStoreService, venueStoreService } from "@/store/module-services";
import { UserInfo } from "@/model/user-info";
import { CookieBannerType } from "@/services/backend/generated/model/cookie-banner-type";
import startChatWith from "@/utility/start-chat-with";
import { PresenceService } from "@/services/presence-service";
import TrackingService, { ProfileType, ProfileEvent } from "@/services/tracking-service";
import { UserService } from "@/services/user-service";
import FloatingAttendeeCard from "@/ui/components/presence-widget/FloatingAttendeeCard.vue";
import AppDrawerContent from "@/ui/components/AppDrawerContent.vue";

export default defineComponent({
  components: {
    AppDrawerContent,
    FloatingAttendeeCard
  },
  props: {
    active: {
      type: Boolean,
      required: true
    },
    showLogoutButton: {
      type: Boolean,
      required: true
    },
    architectureId: {
      type: String
    }
  },
  data() {
    return {
      selectedAttendee: undefined as undefined | AttendeeRef,
      MandatoryPagesEnum,
      CookieBannerType
    };
  },
  computed: {
    showDrawer: {
      get(): boolean {
        return this.active;
      },
      set(value: boolean): void {
        this.$emit("update:active", value);
        this.selectedAttendee = undefined;
      }
    },

    currentAreaId(): string | undefined {
      return this.$route.params.areaId as string | undefined;
    },

    currentUser(): UserInfo | undefined {
      return userStoreService.getUser();
    },

    isCookieBannerEnabled(): boolean {
      return venueStoreService.isCookieBannerEnabled();
    },

    presenceEnabled(): boolean {
      // disable presence on imprint pages (i.e. without area)
      return PresenceService.userPresenceActive() && !!this.currentAreaId;
    }
  },
  methods: {
    logOut() {
      this.$emit("logout");
    },
    async openUserProfile() {
      if (!this.currentAreaId) {
        return;
      }
      TrackingService.trackProfileEvent(this.currentAreaId, ProfileType.UserProfile, ProfileEvent.OpenProfile);
      await userStoreService.openProfileForCurrentUser();
      this.showDrawer = false;
    },
    async openCookieBanner(): Promise<void> {
      await cookieBannerStoreService.setVisible(true);
      this.showDrawer = false;
    },
    async openAttendeeProfile(attendee: AttendeeRef) {
      if (!this.currentAreaId) {
        return;
      }
      TrackingService.trackProfileEvent(this.currentAreaId, ProfileType.AttendeeProfile, ProfileEvent.OpenProfile);
      await UserService.openProfileForAttendee(attendee.uid);
      this.showDrawer = false;
    },
    async startChat(attendee: AttendeeRef) {
      if (!this.currentAreaId) {
        return;
      }
      TrackingService.trackPresenceEvent({
        action: "Start Chat",
        areaId: this.currentAreaId,
        widgetName: "Presence",
        widgetType: "Presence"
      });
      await startChatWith(attendee);
    },
    selectAttendee(attendee: AttendeeRef) {
      // click the same attendee again -> close card
      if (attendee.uid === this.selectedAttendee?.uid) {
        this.unselectAttendee();
        return;
      }
      this.selectedAttendee = attendee;
    },
    unselectAttendee() {
      this.selectedAttendee = undefined;
    }
  }
});
