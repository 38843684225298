import { NavigationGuardNext, RouteLocationNormalized } from "vue-router";
import { uiStoreService, userStoreService } from "@/store/module-services";
import { LoginInfoApi } from "@/services/backend-service";
import { LoginConfiguration } from "@/services/backend/generated/model/login-configuration";
import { LoginProvider } from "@/services/backend/generated/model/login-provider";
import LoginTrackingService from "@/services/login-tracking-service";
import LogCapturingService from "@/services/log-capturing-service";

type VvenueRouteMeta = {
  requiresAuth: boolean | undefined;
};

/**
 * Checks whether the given route requires authentication.
 *
 * @param route The route.
 * @return True if the route requires authentication; false otherwise.
 */
function requiresAuth(route: RouteLocationNormalized): boolean {
  return route.matched.some((record) => {
    const meta = record.meta as VvenueRouteMeta;
    return meta.requiresAuth ?? true; // if nothing is configured, then require authentication by default
  });
}

/**
 * Show the login view if login is enabled and the current user is not logged in.
 *
 * @param to The route being navigated to.
 * @param from The route being navigated away from.
 * @param next The function called to resolve the hook.
 */
export async function loginGuard(
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
): Promise<void> {
  // Make sure we know if we need a login
  if (uiStoreService.isLoginEnabled() === undefined) {
    try {
      const response = await LoginInfoApi.loginOptions();
      const loginConfigs = response?.data as LoginConfiguration[];
      await uiStoreService.setLoginConfigurations(loginConfigs);
      const loginEnabled = !loginConfigs?.some((loginConfig) => loginConfig.loginProvider === LoginProvider.ANONYMOUS);
      await uiStoreService.setLoginEnabled(loginEnabled);
      await LoginTrackingService.logAnonymousLogin();
    } catch (e) {
      const error = e as Error;
      console.error("Could not load login options. Aborting navigation.", error);
      await LogCapturingService.captureError(error.message, error.stack);
      next(false);
      return;
    }
  }

  if (uiStoreService.isLoginEnabled()) {
    // If login is enabled, route logged out users to the login view and logged in users to the content
    if (to.name === "login" && userStoreService.isLoggedIn()) {
      next({ path: "/" });
    } else if (requiresAuth(to) && !userStoreService.isLoggedIn()) {
      next({
        name: "login",
        query: { redirect: to.fullPath }
      });
    } else {
      next();
    }
  } else {
    // If login is disabled, prevent users from reaching the login view
    if (to.name === "login") {
      const redirect = to.query.redirect as string;
      next({ path: redirect || "/" });
    } else {
      next();
    }
  }
}
