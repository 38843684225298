
import { defineComponent, PropType } from "vue";
import { AttendeeRef } from "@/model/attendee-ref";
import BaseToggleButton from "@/ui/base/BaseToggleButton.vue";
import getInitials from "@/utility/get-initials";
import TrackingService from "@/services/tracking-service";
import { userStoreService } from "@/store/module-services";
import { isMobile } from "@/utility/browser";

export default defineComponent({
  components: { BaseToggleButton },
  emits: [
    "open-profile-for-attendee",
    "start-chat",
    "update:areaFilter",
    "update:companyFilter",
    "update:searchTerm",
    "select-attendee"
  ],
  props: {
    attendeeList: {
      type: Array as PropType<AttendeeRef[]>,
      required: true
    },
    areaId: {
      type: String,
      required: false
    },
    searchTerm: {
      type: String
    },
    areaFilter: {
      type: Boolean,
      required: true
    },
    companyFilter: {
      type: Boolean,
      required: true
    },
    selectedAttendeeId: {
      type: String
    }
  },
  data() {
    return {
      selectedCard: undefined as undefined | number
    };
  },
  computed: {
    hasCompany() {
      return !!userStoreService.getUser()?.attributes.company;
    },
    filterArea: {
      get(): boolean {
        return this.areaFilter;
      },
      set(value: boolean) {
        this.$emit("update:areaFilter", value);
      }
    },
    filterCompany: {
      get(): boolean {
        return this.companyFilter;
      },
      set(value: boolean) {
        this.$emit("update:companyFilter", value);
      }
    },
    searchValue: {
      get(): string | undefined {
        return this.searchTerm;
      },
      set(value: string | undefined) {
        this.$emit("update:searchTerm", value);
      }
    }
  },
  methods: {
    getInitials,
    selectAttendee(attendee: AttendeeRef): void {
      this.$emit("select-attendee", attendee);
    },
    attendeeCardColor(attendee: AttendeeRef) {
      if (attendee.uid === this.selectedAttendeeId) {
        return "lighten2";
      } else {
        return "background";
      }
    },
    trackFilterByText() {
      TrackingService.trackPresenceEvent({
        action: "Filter by Text",
        areaId: this.areaId ?? "undefined",
        widgetName: "Presence",
        widgetType: "Presence"
      });
    },
    trackFilterByArea() {
      TrackingService.trackPresenceEvent({
        action: "Filter by Area",
        areaId: this.areaId ?? "undefined",
        widgetName: "Presence",
        widgetType: "Presence"
      });
    },
    trackFilterByCompany() {
      TrackingService.trackPresenceEvent({
        action: "Filter by Company",
        areaId: this.areaId ?? "undefined",
        widgetName: "Presence",
        widgetType: "Presence"
      });
    },
    blurInputOnMobile(event: Event): void {
      // On mobile devices, the keyboard might stay open even though the "done" button (checkmark) is pressed.
      // This causes the search results to be hidden by the keyboard. To mitigate, we blur the input on enter.
      if (isMobile() && event.target && event.target instanceof HTMLElement) {
        event.target.blur();
      }
    }
  }
});
