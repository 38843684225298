// Encapsulates browser specific API functions and provides them in a browser neutral way
import bowser from "bowser";

// Safari’s fullscreen methods are prefixed.
type SafariElement = Element & {
  webkitRequestFullscreen?: () => Promise<void>;
  onwebkitfullscreenchange?: () => void;
};
type SafariDocument = Document & {
  webkitExitFullscreen?: () => Promise<void>;
  webkitFullscreenElement?: SafariElement;
};

export function isMobile(): boolean {
  return bowser.mobile || bowser.ipad || false;
}

export function isSafari(): boolean {
  return bowser.safari;
}

export async function requestFullscreen(el: Element): Promise<void> {
  if (el.requestFullscreen) {
    await el.requestFullscreen();
  } else {
    await (el as SafariElement).webkitRequestFullscreen?.();
  }
}

export async function exitFullscreen(): Promise<void> {
  if (document.exitFullscreen) {
    await document.exitFullscreen();
  } else {
    await (document as SafariDocument).webkitExitFullscreen?.();
  }
}

export function isWebkitFullscreen(): boolean {
  // document.webkitIsFullscreen seems to be broken in Safari but not in chrome
  return !!(document as SafariDocument).webkitFullscreenElement;
}

export function onFullscreenChange(
  el: Element,
  callback: (error: Error | undefined, fullscreen: boolean) => void
): void {
  el.onfullscreenchange = () => {
    callback(undefined, !!document.fullscreenElement);
  };
  (el as SafariElement).onwebkitfullscreenchange = () => {
    callback(undefined, !!(document as SafariDocument).webkitFullscreenElement);
  };
}

/**
 * method to determine the client operating system.
 */
export function getOS(): string {
  return bowser.osname ?? "Unknown";
}

/**
 * method to extract browser name.
 */
export function getBrowserName(): string {
  return bowser.name ?? "Unknown";
}

/**
 * method to extract browser version.
 */
export function getBrowserVersion(): string {
  return `${bowser.version ?? "Unknown"}`;
}
