/**
 * Escape forbidden symbols of firebase
 * @param origKey
 * @returns escaped key
 */
export function makeSafeFirebaseKey(origKey: string): string {
  // If you create your own keys, they must be UTF-8 encoded, can be a maximum of 768 bytes,
  // and cannot contain ., $, #, [, ], /, or ASCII control characters 0-31 or 127.
  // Source: https://firebase.google.com/docs/database/web/structure-data

  // Note that we also encode the character "%" in the original key, because we use it to
  // start an encoded token in the safe key.
  // eslint-disable-next-line no-control-regex
  return origKey.replace(/[\x00-\x1F#$%./[\]\x7F]/g, function (c) {
    return "%" + c.charCodeAt(0).toString(16).toUpperCase();
  });
}
