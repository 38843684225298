import { NavigationGuardNext, RouteLocationNormalized } from "vue-router";
import { uiStoreService } from "@/store/module-services";
import { PublishState } from "@/store/modules/ui/UiState";
import LogCapturingService from "@/services/log-capturing-service";

/**
 * Show the work-in-progress view if the venue is unpublished.
 *
 * @param to The route being navigated to.
 * @param from The route being navigated away from.
 * @param next The function called to resolve the hook.
 */
export async function workInProgressGuard(
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
): Promise<void> {
  // ensure that client has the information to decide if a venue is published;
  // this will return fast if the venue version is already available
  if (uiStoreService.isPublished() === PublishState.UNKNOWN) {
    try {
      await uiStoreService.loadVenueVersion();
    } catch (e) {
      const error = e as Error;
      console.error("Could not load venue version. Aborting navigation.", error);
      await LogCapturingService.captureError(error.message, error.stack);
      next(false);
      return;
    }
  }

  const isPublished: boolean = uiStoreService.isPublished() === PublishState.PUBLISHED;
  if (to.name === "WorkInProgress" && isPublished) {
    // The WIP view is inaccessible on published venues,
    // i.e., direct attendees to / instead
    const redirect = to.query.redirect as string;
    next({ path: redirect || "/" });
  } else if (to.name !== "WorkInProgress" && !isPublished) {
    // Every view except the WIP view is inaccessible on unpublished venue,
    // i.e., direct attendees to the WIP view
    next({ name: "WorkInProgress", query: { redirect: to.fullPath } });
  } else {
    // Everything else is ok
    next();
  }
}
