import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-37be0c42"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  id: "mobile-indicator",
  class: "d-flex flex-column justify-center d-md-none"
}
const _hoisted_2 = { class: "wiggling-icon-wrapper text-center" }
const _hoisted_3 = { class: "font-weight-black text-center text-uppercase text-h5 text-sm-h4 text-md-h3 my-4" }
const _hoisted_4 = { class: "text-center text-h6 text-sm-h5 text-md-h4 my-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!

  return (_ctx.visible)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_v_icon, {
            size: _ctx.iconSize,
            class: "wiggling-icon my-8",
            color: "white"
          }, {
            default: _withCtx(() => [
              _createTextVNode("mdi-cellphone-iphone")
            ]),
            _: 1
          }, 8, ["size"])
        ]),
        _createElementVNode("h1", _hoisted_3, _toDisplayString(_ctx.$t("mobileIndicator.heading")), 1),
        _createElementVNode("h2", _hoisted_4, _toDisplayString(_ctx.$t("mobileIndicator.blockText")), 1)
      ]))
    : _createCommentVNode("", true)
}