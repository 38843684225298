import { CookieBannerState } from "@/store/modules/cookieBanner/cookieBannerState";
import { RootState } from "@/store/RootState";
import { Module } from "vuex";
import { cookieBannerMutations } from "@/store/modules/cookieBanner/cookieBannerMutations";
import { cookieBannerActions } from "@/store/modules/cookieBanner/cookieBannerActions";
import { cookieBannerGetters } from "@/store/modules/cookieBanner/cookieBannerGetters";

const cookieBannerModule: Module<CookieBannerState, RootState> = {
  namespaced: true,
  mutations: cookieBannerMutations,
  actions: cookieBannerActions,
  getters: cookieBannerGetters,
  state: {
    visible: false,
    notificationVisible: false
  }
};

export default cookieBannerModule;
