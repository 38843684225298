import LogCapturingService from "@/services/log-capturing-service";
import { uiStoreService } from "@/store/module-services";
import TrackingService from "@/services/tracking-service";

/**
 * A service that bills and tracks user logins.
 */
export default class LoginTrackingService {
  /**
   * Bills and tracks a successful personalized login.
   *
   * @param emailAddress The email address.
   */
  static async logPersonalizedLogin(emailAddress: string): Promise<void> {
    await LogCapturingService.captureInfo("Successfully logged in user", undefined, "BILLING");
    TrackingService.trackLoginEvent(emailAddress);
  }

  /**
   * Bills and tracks a successful anonymous login.
   */
  static async logAnonymousLogin(): Promise<void> {
    if (!uiStoreService.isLoginEnabled()) {
      await LogCapturingService.captureAnonymousLogin();
      TrackingService.trackLoginEvent(undefined);
    }
  }
}
