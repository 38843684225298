import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_drawer_content = _resolveComponent("app-drawer-content")!
  const _component_v_navigation_drawer = _resolveComponent("v-navigation-drawer")!
  const _component_floating_attendee_card = _resolveComponent("floating-attendee-card")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_v_navigation_drawer, {
      modelValue: _ctx.showDrawer,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.showDrawer) = $event)),
      height: "100%",
      app: "",
      location: "right",
      "disable-resize-watcher": "",
      color: "background"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_app_drawer_content, {
          "architecture-id": _ctx.architectureId,
          "current-user": _ctx.currentUser,
          "current-area-id": _ctx.currentAreaId,
          "presence-enabled": _ctx.presenceEnabled,
          "is-cookie-banner-enabled": _ctx.isCookieBannerEnabled,
          "show-logout-button": _ctx.showLogoutButton,
          "selected-attendee-id": _ctx.selectedAttendee ? _ctx.selectedAttendee.uid : '',
          onOpenUserProfile: _ctx.openUserProfile,
          onOpenCookieBanner: _ctx.openCookieBanner,
          onLogout: _ctx.logOut,
          onSelectAttendee: _ctx.selectAttendee
        }, null, 8, ["architecture-id", "current-user", "current-area-id", "presence-enabled", "is-cookie-banner-enabled", "show-logout-button", "selected-attendee-id", "onOpenUserProfile", "onOpenCookieBanner", "onLogout", "onSelectAttendee"])
      ]),
      _: 1
    }, 8, ["modelValue"]),
    (_ctx.selectedAttendee)
      ? (_openBlock(), _createBlock(_component_floating_attendee_card, {
          key: 0,
          attendee: _ctx.selectedAttendee,
          onOpenAttendeeProfile: _ctx.openAttendeeProfile,
          onUnselectAttendee: _ctx.unselectAttendee,
          onStartChat: _ctx.startChat
        }, null, 8, ["attendee", "onOpenAttendeeProfile", "onUnselectAttendee", "onStartChat"]))
      : _createCommentVNode("", true)
  ]))
}