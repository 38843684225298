import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_image = _resolveComponent("base-image")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_ctx.logoAssetId)
    ? (_openBlock(), _createBlock(_component_router_link, {
        key: 0,
        to: { name: 'FallbackToDefaultArea' },
        class: "home-link"
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", {
            style: _normalizeStyle(_ctx.iconStyle)
          }, [
            _createVNode(_component_base_image, {
              "asset-id": _ctx.logoAssetId,
              class: "logo-image",
              contain: "",
              onResizeImage: _ctx.applyResizing
            }, null, 8, ["asset-id", "onResizeImage"])
          ], 4)
        ]),
        _: 1
      }))
    : _createCommentVNode("", true)
}