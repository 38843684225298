import { TypedGetterFunctions } from "@/store/framework/TypedGetterFunctions";
import { CookieBannerState } from "@/store/modules/cookieBanner/cookieBannerState";
import { RootState } from "@/store/RootState";

export interface CookieBannerGetters {
  getVisible: boolean;
  getNotificationVisible: boolean;
}

export const cookieBannerGetters: TypedGetterFunctions<CookieBannerState, RootState, CookieBannerGetters> = {
  getVisible: (state: CookieBannerState): boolean => state.visible,

  getNotificationVisible: (state: CookieBannerState): boolean => state.notificationVisible
};
