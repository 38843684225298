import AbstractStoreService from "@/store/framework/AbstractStoreService";
import LocalStorageService, { CookiesLevel } from "@/services/local-storage-service";
import { UserState } from "@/store/modules/user/UserState";
import { RootState } from "@/store/RootState";
import { UserGetters } from "@/store/modules/user/userGetters";
import { UserAction } from "@/store/modules/user/userActions";
import { LocalStorageCookieProvider } from "@/model/local-storage-cookie-provider";
import { UserInfo } from "@/model/user-info";
import { TypeImprovedAttendeeProfile } from "@/model/type-improved-attendee-profile";

export class UserStoreService extends AbstractStoreService<UserState, RootState, UserGetters> {
  isLoggedIn(): boolean {
    return this._get("isLoggedIn");
  }

  async setLoggedOut(): Promise<void> {
    return this.dispatch(UserAction.setLoggedOut);
  }

  getUser(): UserInfo | undefined {
    return this._get("getUser");
  }

  async setUser(userInfo: UserInfo): Promise<void> {
    return this.dispatch(UserAction.setUser, userInfo);
  }

  async updateUser(architectureId: string, userInfo: UserInfo): Promise<void> {
    return this.dispatch(UserAction.updateUser, { architectureId, userInfo });
  }

  async setLoggedInAs(userInfo: UserInfo): Promise<void> {
    return this.dispatch(UserAction.setLoggedInAs, userInfo);
  }

  getAuthToken(): string | undefined {
    return this._get("getAuthToken");
  }

  async setAuthToken(authToken: string | undefined): Promise<void> {
    return this.dispatch(UserAction.setAuthToken, authToken);
  }

  isTrackingConsentGiven(): boolean {
    return this._get("isTrackingConsentGiven");
  }

  async setTrackingConsentGiven(consent: boolean): Promise<void> {
    await this.dispatch(UserAction.setTrackingConsentGiven, consent);
  }

  getLocalStorageCookieProviders(): LocalStorageCookieProvider[] {
    return this._get("getLocalStorageCookieProviders");
  }

  async setLocalStorageCookieProvider(provider: LocalStorageService): Promise<void> {
    await this.dispatch(UserAction.setLocalStorageCookieProvider, provider);
  }

  /**
   * Reads out all consents stored into the browsers local storage and populates the application state with them.
   */
  async readStoredConsents(): Promise<void> {
    const trackingCookieConsent = LocalStorageService.getCookiesPermission(CookiesLevel.TRACKING);
    await this.setTrackingConsentGiven(trackingCookieConsent);
  }

  async openProfileForCurrentUser(): Promise<void> {
    return this.dispatch(UserAction.openProfileForCurrentUser);
  }

  async openProfileForAttendee(architectureId: string, userId: string): Promise<void> {
    return this.dispatch(UserAction.openProfileForAttendee, {
      architectureId,
      userId
    });
  }

  async closeProfile(): Promise<void> {
    return this.dispatch(UserAction.closeProfile);
  }

  isShowingOwnProfile(): boolean {
    return this._get("isShowingOwnProfile");
  }

  isLoadingAttendeeProfile(): boolean {
    return this._get("isLoadingAttendeeProfile");
  }

  getAttendeeIdToLoad(): string | undefined {
    return this._get("getAttendeeIdToLoad");
  }

  isShowingAttendeeProfile(): boolean {
    return this._get("isShowingAttendeeProfile");
  }

  getAttendeeProfileToShow(): TypeImprovedAttendeeProfile | undefined {
    return this._get("getAttendeeProfileToShow");
  }
}
