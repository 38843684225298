import { LocalizedText } from "@/services/backend/generated/model/localized-text";
import { LocalizedURI } from "@/services/backend/generated/model/localized-uri";
import { LocalizedContent } from "@/services/backend/generated/model/localized-content";
import { getI18n } from "@/i18n/getI18n";
const i18n = getI18n();

/**
 * Extracts the desired text from a {@link LocalizedText} array and returns it
 *
 * @param names the {@link LocalizedText} array
 * @param fallbackText static translation to fallback to
 * locale the code of the desired language
 */
export function fromLocalizedText(names: Array<LocalizedText>, fallbackText?: string): string {
  const fallback = fallbackText ?? names[0]?.text ?? "";
  return (
    // still need ?. and ?? operators here, since the desired locale is probably not listed, therefore we then
    // try to use the english name and if this one is also not listed, we return a default value
    names.find((name) => name.locale === i18n.global.locale.value)?.text ??
    names.find((name) => name.locale === i18n.global.fallbackLocale.value)?.text ??
    fallback
  );
}

/**
 * Extracts the desired URI from a {@link LocalizedURI} array and returns it
 *
 * @param uris the {@link LocalizedURI} array
 */
export function fromLocalizedURI(uris: Array<LocalizedURI>): string {
  if (uris.length === 0) {
    console.trace("Cannot localize URI, no locales given");
    return "";
  }
  return (
    // still need ?. and ?? operators here, since the desired locale is probably not listed, therefore we then
    // try to use the english URI and if this one is also not listed, we return the first URI given.

    uris.find((uri) => uri.locale === i18n.global.locale.value)?.uri ??
    uris.find((uri) => uri.locale === i18n.global.fallbackLocale.value)?.uri ??
    uris[0].uri
  );
}

/**
 * Extracts the desired asset from a {@link LocalizedContent} array and returns it
 *
 * @param assets the {@link LocalizedContent} array
 */
export function fromLocalizedContent(assets: Array<LocalizedContent>): string {
  if (assets.length === 0) {
    console.log("Cannot localize asset, no locales given");
    return "";
  }
  return (
    // still need ?. and ?? operators here, since the desired locale is probably not listed, therefore we then
    // try to use the English content and if this one is also not listed, we return the first content given.

    assets.find((asset) => asset.locale === i18n.global.locale.value)?.assetId ??
    assets.find((asset) => asset.locale === i18n.global.fallbackLocale.value)?.assetId ??
    assets[0].assetId
  );
}
