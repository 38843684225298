import { normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, mergeProps as _mergeProps, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-41041d29"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["alt", "src", "data-testid"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_progress_circular = _resolveComponent("v-progress-circular")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_img = _resolveComponent("v-img")!
  const _component_base_asset_container = _resolveComponent("base-asset-container")!

  return (_openBlock(), _createBlock(_component_base_asset_container, {
    "asset-id": _ctx.assetId,
    "image-size": _ctx.actualImageSize,
    onError: _ctx.retryOnError
  }, {
    default: _withCtx((asset) => [
      (_ctx.isCustomIcon)
        ? (_openBlock(), _createElementBlock("img", {
            key: 0,
            alt: asset.alt,
            src: asset.objectUrl,
            "data-testid": _ctx.dataTestId,
            style: _normalizeStyle(_ctx.imageStyle),
            class: _normalizeClass({ 'mg-hide': !_ctx.loaded }),
            onLoad: _cache[0] || (_cache[0] = ($event: any) => (_ctx.loaded = true))
          }, null, 46, _hoisted_1))
        : (_openBlock(), _createBlock(_component_v_img, _mergeProps({
            key: 1,
            ref: "imageRef",
            alt: asset.alt,
            src: asset.objectUrl,
            "data-testid": _ctx.dataTestId
          }, _ctx.$attrs, { style: _ctx.imageStyle }), {
            default: _withCtx(() => [
              _renderSlot(_ctx.$slots, "default", {}, undefined, true)
            ]),
            placeholder: _withCtx(() => [
              _renderSlot(_ctx.$slots, "placeholder", {}, () => [
                (_ctx.placeholder)
                  ? (_openBlock(), _createBlock(_component_v_row, {
                      key: 0,
                      class: "fill-height ma-0",
                      align: "center",
                      justify: "center"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_progress_circular, {
                          indeterminate: "",
                          color: "grey lighten-5"
                        })
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true)
              ], true)
            ]),
            _: 2
          }, 1040, ["alt", "src", "data-testid", "style"]))
    ]),
    _: 3
  }, 8, ["asset-id", "image-size", "onError"]))
}