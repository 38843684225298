/* tslint:disable */
/* eslint-disable */
/**
 * VVenue Services API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 53
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ApiError } from '../model';
// @ts-ignore
import { AssetSource } from '../model';
// @ts-ignore
import { SignedUrlResponse } from '../model';
// @ts-ignore
import { Venue } from '../model';
/**
 * VenueUIApi - axios parameter creator
 * @export
 */
export const VenueUIApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * If the response identifies the asset as an internal asset, then use the getAssetFile endpoint to retrieve the content of the asset. Otherwise, use the return direct URL to retrieve the asset. The direct URL is only valid for a short period of time and must be used immediately. It can\'t be stored for later use. If the optional \'attachment\' parameter is set to \'true\' in case of direct urls, the server is asked to return the \'Content-Disposition: attachment\' header when clients call the direct url to support file downloads in Browsers.
         * @summary Returns the source where the asset can be downloaded
         * @param {string} architectureID 
         * @param {string} assetID 
         * @param {boolean} [attachment] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAssetSource: async (architectureID: string, assetID: string, attachment?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'architectureID' is not null or undefined
            assertParamExists('getAssetSource', 'architectureID', architectureID)
            // verify required parameter 'assetID' is not null or undefined
            assertParamExists('getAssetSource', 'assetID', assetID)
            const localVarPath = `/ui/architecture/{architectureID}/assets/{assetID}/download`
                .replace(`{${"architectureID"}}`, encodeURIComponent(String(architectureID)))
                .replace(`{${"assetID"}}`, encodeURIComponent(String(assetID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (attachment !== undefined) {
                localVarQueryParameter['attachment'] = attachment;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Sign asset url to show images and content on ui
         * @summary Return signed asset url
         * @param {string} url 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSignedAssetUrl: async (url: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'url' is not null or undefined
            assertParamExists('getSignedAssetUrl', 'url', url)
            const localVarPath = `/ui/sign-asset-url`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (url !== undefined) {
                localVarQueryParameter['url'] = url;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Reads the venue configuration for the published/preview architecture, including all areas. The service filters the global content according to the requesting user’s role.
         * @summary VenueUI configuration
         * @param {string} [architectureId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVenue: async (architectureId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/ui/venue`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (architectureId !== undefined) {
                localVarQueryParameter['architectureId'] = architectureId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * VenueUIApi - functional programming interface
 * @export
 */
export const VenueUIApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = VenueUIApiAxiosParamCreator(configuration)
    return {
        /**
         * If the response identifies the asset as an internal asset, then use the getAssetFile endpoint to retrieve the content of the asset. Otherwise, use the return direct URL to retrieve the asset. The direct URL is only valid for a short period of time and must be used immediately. It can\'t be stored for later use. If the optional \'attachment\' parameter is set to \'true\' in case of direct urls, the server is asked to return the \'Content-Disposition: attachment\' header when clients call the direct url to support file downloads in Browsers.
         * @summary Returns the source where the asset can be downloaded
         * @param {string} architectureID 
         * @param {string} assetID 
         * @param {boolean} [attachment] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAssetSource(architectureID: string, assetID: string, attachment?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AssetSource>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAssetSource(architectureID, assetID, attachment, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Sign asset url to show images and content on ui
         * @summary Return signed asset url
         * @param {string} url 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSignedAssetUrl(url: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SignedUrlResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSignedAssetUrl(url, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Reads the venue configuration for the published/preview architecture, including all areas. The service filters the global content according to the requesting user’s role.
         * @summary VenueUI configuration
         * @param {string} [architectureId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getVenue(architectureId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Venue>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getVenue(architectureId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * VenueUIApi - factory interface
 * @export
 */
export const VenueUIApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = VenueUIApiFp(configuration)
    return {
        /**
         * If the response identifies the asset as an internal asset, then use the getAssetFile endpoint to retrieve the content of the asset. Otherwise, use the return direct URL to retrieve the asset. The direct URL is only valid for a short period of time and must be used immediately. It can\'t be stored for later use. If the optional \'attachment\' parameter is set to \'true\' in case of direct urls, the server is asked to return the \'Content-Disposition: attachment\' header when clients call the direct url to support file downloads in Browsers.
         * @summary Returns the source where the asset can be downloaded
         * @param {string} architectureID 
         * @param {string} assetID 
         * @param {boolean} [attachment] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAssetSource(architectureID: string, assetID: string, attachment?: boolean, options?: any): AxiosPromise<AssetSource> {
            return localVarFp.getAssetSource(architectureID, assetID, attachment, options).then((request) => request(axios, basePath));
        },
        /**
         * Sign asset url to show images and content on ui
         * @summary Return signed asset url
         * @param {string} url 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSignedAssetUrl(url: string, options?: any): AxiosPromise<SignedUrlResponse> {
            return localVarFp.getSignedAssetUrl(url, options).then((request) => request(axios, basePath));
        },
        /**
         * Reads the venue configuration for the published/preview architecture, including all areas. The service filters the global content according to the requesting user’s role.
         * @summary VenueUI configuration
         * @param {string} [architectureId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVenue(architectureId?: string, options?: any): AxiosPromise<Venue> {
            return localVarFp.getVenue(architectureId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * VenueUIApi - object-oriented interface
 * @export
 * @class VenueUIApi
 * @extends {BaseAPI}
 */
export class VenueUIApi extends BaseAPI {
    /**
     * If the response identifies the asset as an internal asset, then use the getAssetFile endpoint to retrieve the content of the asset. Otherwise, use the return direct URL to retrieve the asset. The direct URL is only valid for a short period of time and must be used immediately. It can\'t be stored for later use. If the optional \'attachment\' parameter is set to \'true\' in case of direct urls, the server is asked to return the \'Content-Disposition: attachment\' header when clients call the direct url to support file downloads in Browsers.
     * @summary Returns the source where the asset can be downloaded
     * @param {string} architectureID 
     * @param {string} assetID 
     * @param {boolean} [attachment] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VenueUIApi
     */
    public getAssetSource(architectureID: string, assetID: string, attachment?: boolean, options?: AxiosRequestConfig) {
        return VenueUIApiFp(this.configuration).getAssetSource(architectureID, assetID, attachment, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Sign asset url to show images and content on ui
     * @summary Return signed asset url
     * @param {string} url 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VenueUIApi
     */
    public getSignedAssetUrl(url: string, options?: AxiosRequestConfig) {
        return VenueUIApiFp(this.configuration).getSignedAssetUrl(url, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Reads the venue configuration for the published/preview architecture, including all areas. The service filters the global content according to the requesting user’s role.
     * @summary VenueUI configuration
     * @param {string} [architectureId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VenueUIApi
     */
    public getVenue(architectureId?: string, options?: AxiosRequestConfig) {
        return VenueUIApiFp(this.configuration).getVenue(architectureId, options).then((request) => request(this.axios, this.basePath));
    }
}
