
import UserSummary from "./user-preview/UserSummary.vue";
import PresenceWidgetContainer from "./presence-widget/PresenceWidgetContainer.vue";
import TheLanguageSelection from "../../components/TheLanguageSelection.vue";
import LogoutButton from "./LogoutButton.vue";
import { defineComponent, PropType } from "vue";
import { UserInfo } from "@/model/user-info";
import { MandatoryPagesEnum } from "@/model/mandatory-pages";
import { AttendeeRef } from "@/model/attendee-ref";

export default defineComponent({
  components: {
    UserSummary,
    PresenceWidgetContainer,
    TheLanguageSelection,
    LogoutButton
  },
  props: {
    selectedAttendeeId: { type: String },
    architectureId: { type: String },
    currentUser: { type: Object as PropType<UserInfo> },
    currentAreaId: { type: String },
    presenceEnabled: { type: Boolean, default: false },
    isCookieBannerEnabled: { type: Boolean, default: false },
    showLogoutButton: { type: Boolean, default: false }
  },
  data() {
    return {
      presenceReady: true,
      MandatoryPagesEnum
    };
  },
  methods: {
    openUserProfile() {
      this.$emit("open-user-profile");
    },
    openCookieBanner() {
      this.$emit("open-cookie-banner");
    },
    logOut() {
      this.$emit("logout");
    },
    selectAttendee(attendee: AttendeeRef) {
      console.error("AppDrawer select-attendee");
      this.$emit("select-attendee", attendee);
    }
  }
});
