import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_list_item_avatar = _resolveComponent("base-list-item-avatar")!
  const _component_v_list_item_title = _resolveComponent("v-list-item-title")!
  const _component_v_list_item_subtitle = _resolveComponent("v-list-item-subtitle")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_list = _resolveComponent("v-list")!

  return (_openBlock(), _createBlock(_component_v_list, {
    density: "compact",
    color: "background"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_list_item, null, {
        prepend: _withCtx(() => [
          _createVNode(_component_base_list_item_avatar, { initials: _ctx.initials }, null, 8, ["initials"])
        ]),
        default: _withCtx(() => [
          _createVNode(_component_v_list_item_title, { class: "text--text text-subtitle-2 font-weight-bold" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.attendee.name), 1)
            ]),
            _: 1
          }),
          (_ctx.attendee.title)
            ? (_openBlock(), _createBlock(_component_v_list_item_subtitle, {
                key: 0,
                class: "text--text text-truncate font-weight-regular"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.attendee.title), 1)
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          (_ctx.attendee.company)
            ? (_openBlock(), _createBlock(_component_v_list_item_subtitle, {
                key: 1,
                class: "text--text text-truncate font-weight-regular"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.attendee.company), 1)
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          _createVNode(_component_v_list_item_subtitle, { class: "text--text font-weight-regular font-italic mt-2" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t("presence.contextMenu.inArea", {
          area: _ctx.getAreaName(_ctx.attendee.areaId)
        })), 1)
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}