import { AttendeeRef } from "@/model/attendee-ref";
import { uiStoreService } from "@/store/module-services";

/**
 * Utility method for opening the chat-widget and starting a chat with someone.
 *
 * @param attendee the attendee one wants to start a chat with
 */
export default async function startChatWith(attendee: AttendeeRef): Promise<void> {
  await uiStoreService.openChat();
  await uiStoreService.setAttendeeToChatUid(attendee.uid);
  // After setting the uid we also need to reset it, such that it is possible to start a chat with the same attendee
  // again. This due to the fact, that this value is fed into a prop that another component has a watcher on. If the
  // value is not changing (for example if the user wants to open a chat with the same user two times in a row) then the
  // watcher is not called and therefore the chat will not be opened. Have a look at VenueChatContainer, to see how this
  // value is used further.
  await uiStoreService.setAttendeeToChatUid(undefined);
}
