
import { defineComponent } from "vue";
import { uiStoreService } from "@/store/module-services";
import { ErrorCode, ErrorState } from "@/store/modules/ui/UiState";
import { assertNever } from "@/utility/assert-never";
import { TranslateResult } from "vue-i18n";

export default defineComponent({
  computed: {
    errorState(): ErrorState {
      return uiStoreService.getErrorState();
    },

    visible(): boolean {
      return this.errorState.globalError.hasErrors && !uiStoreService.isDefaultErrorDialogSuppressed();
    },

    code(): string | TranslateResult {
      if (!this.errorState.globalError.hasErrors) {
        return "";
      }

      switch (this.errorState.globalError.code) {
        case ErrorCode.FORBIDDEN:
          return this.$t("globalError.forbidden");

        case ErrorCode.PROCESSING_ERROR:
          return this.$t("globalError.processingError");

        case ErrorCode.UNAUTHORIZED:
          return this.$t("globalError.unauthorized");

        case ErrorCode.NOT_REACHABLE:
          return this.$t("globalError.notReachable");

        case ErrorCode.FUNCTION_UNSUPPORTED:
          return this.$t("globalError.functionUnsupported");

        case ErrorCode.WIDGET_ERROR:
          return this.$t("globalError.widgetError");

        default:
          return assertNever(this.errorState.globalError.code);
      }
    },

    message(): string {
      if (this.errorState.globalError.hasErrors) {
        return this.errorState.globalError.message;
      } else {
        return "";
      }
    }
  },

  methods: {
    async dismissError() {
      await uiStoreService.clearError();
    }
  }
});
