import firebase from "firebase/app";
import "firebase/auth";
import { userStoreService, venueStoreService } from "@/store/module-services";
import { FirebaseService } from "@/services/firebase-service";
import { UserInfo } from "@/model/user-info";
import { PresenceService } from "@/services/presence-service";
import FirebaseError = firebase.FirebaseError;

const TOKEN_REFRESH_INTERVAL_IN_MS = 900000; // every 15 min

export class UserNotFoundError extends Error {
  constructor(m: string) {
    super(m);
    // Set the prototype explicitly.
    Object.setPrototypeOf(this, UserNotFoundError.prototype);
  }
}

export class UserInactiveError extends Error {
  constructor(m: string) {
    super(m);
    // Set the prototype explicitly.
    Object.setPrototypeOf(this, UserInactiveError.prototype);
  }
}

export class WrongPasswordError extends Error {
  constructor(m: string) {
    super(m);
    // Set the prototype explicitly.
    Object.setPrototypeOf(this, WrongPasswordError.prototype);
  }
}

export class TooManyRequestsError extends Error {
  constructor(m: string) {
    super(m);
    // Set the prototype explicitly.
    Object.setPrototypeOf(this, TooManyRequestsError.prototype);
  }
}

export class DuplicateAccountError extends Error {
  constructor(m: string) {
    super(m);
    // Set the prototype explicitly.
    Object.setPrototypeOf(this, DuplicateAccountError.prototype);
  }
}

export class LoginAbortedError extends Error {
  constructor(m: string) {
    super(m);
    // Set the prototype explicitly.
    Object.setPrototypeOf(this, LoginAbortedError.prototype);
  }
}

export interface UserServiceConfiguration {
  // Function called every time the user logs in
  loggedInCallback: (userInfo: UserInfo) => Promise<void>;
  // Function called every time the user logs out
  loggedOutCallback: () => Promise<void>;
  // Function called every time after successful authentication to test if the user is authorized to use the app.
  // If he is not (function returns false), he will be logged out again immediately.
  authorityCallback: () => Promise<boolean>;
}

/**
 * Provides firebase user authentication.
 */
export class UserService {
  private static tokenRefreshIntervalHandle?: number;

  /**
   * Sets up the UserService. Call this only once during application startup.
   *
   * @param config configuration
   */
  static setUp(config: UserServiceConfiguration): void {
    // This firebase callback is called on log-in and log-out
    this.auth().onAuthStateChanged(async (user) => {
      // log-in trigger
      if (user) {
        // Store an up-to-date auth token
        const idTokenResult = await this.fetchAuthToken();
        await this.storeAuthToken(idTokenResult);
        // Check authorization
        if (await config.authorityCallback()) {
          // Authorized
          this.setTokenRefreshInterval();
          await config.loggedInCallback(this.getUserInfo(user));
        } else {
          // Not authorized
          await this.auth().signOut(); // will trigger onAuthStateChanged again (log-out trigger)
        }
      } else {
        // log-out trigger
        this.clearTokenRefreshInterval();
        await this.clearAuthToken();
        await config.loggedOutCallback();
      }
    });
  }

  /**
   * Extracts information about the user from its Firebase object.
   * @param user
   */
  private static getUserInfo(user: firebase.User): UserInfo {
    const email = this.sanitizeEmailAddress(user.email);
    return { userId: user.uid, email, attributes: {} };
  }

  /**
   * Logs in a user.
   *
   * @param email the user's e-mail address
   * @param password the user's password
   * @throws {UserNotFoundError}
   * @throws {WrongPasswordError}
   * @throws {TooManyRequestsError}
   * @throws {FirebaseError}
   */
  static async logIn(email: string, password: string): Promise<void> {
    try {
      await this.auth().signInWithEmailAndPassword(email, password);
    } catch (e) {
      this.handleAuthError(e as FirebaseError);
    }
  }

  /**
   * Logs in a user via a Firebase OpenIdConnect provider.
   *
   * @param firebaseProviderID The IDP provider ID.
   */
  static async logInOIDC(firebaseProviderID: string): Promise<void> {
    try {
      let provider;
      if (firebaseProviderID.startsWith("saml.")) {
        provider = new firebase.auth.SAMLAuthProvider(firebaseProviderID);
      } else {
        provider = new firebase.auth.OAuthProvider(firebaseProviderID);
      }
      await this.auth().signInWithPopup(provider);
    } catch (e) {
      this.handleAuthError(e as FirebaseError);
    }
  }

  /**
   * Logs out the currently logged in user.
   */
  static async logOut(): Promise<void> {
    // we need to remove the attendee from the Firebase database,
    // *before* we sign out of the Firebase library
    await PresenceService.removeAttendee();

    // sign out of Firebase
    await this.auth().signOut();

    // after logging out, the user should no longer have access to venue data
    // this cross-service call here is required as the user can either logout
    // manually (via the logout button in the header) or automatically (e.g.
    // through failed backend calls)
    await venueStoreService.setVenue(undefined);
  }

  /**
   * Triggers a password reset by sending an e-mail to the user.
   *
   * @param email the user's e-mail address
   * @param continueUrl the URL used as "continue URL" in the password reset e-mail
   * @throws UserNotFoundError
   */
  static async triggerPasswordReset(email: string, continueUrl: string): Promise<void> {
    try {
      await this.auth().sendPasswordResetEmail(email, { url: continueUrl });
    } catch (e) {
      const error = e as FirebaseError;
      if (error?.code === "auth/user-not-found") {
        throw new UserNotFoundError(error.message);
      } else {
        throw error;
      }
    }
  }

  private static auth(): firebase.auth.Auth {
    return FirebaseService.auth();
  }

  private static handleAuthError(error: FirebaseError): void {
    if (
      error.code === "auth/account-exists-with-different-credential" ||
      error.code === "auth/credential-already-in-use" ||
      error.code === "auth/email-already-in-use"
    ) {
      throw new DuplicateAccountError(error.message);
    } else if (error.code === "auth/user-not-found") {
      throw new UserNotFoundError(error.message);
    } else if (error.code === "auth/wrong-password") {
      throw new WrongPasswordError(error.message);
    } else if (error.code === "auth/too-many-requests") {
      throw new TooManyRequestsError(error.message);
    } else if (error.code === "auth/popup-closed-by-user") {
      throw new LoginAbortedError(error.message);
    } else {
      throw error;
    }
  }

  private static async fetchAuthToken(): Promise<firebase.auth.IdTokenResult> {
    const idTokenResult = await this.auth().currentUser?.getIdTokenResult(true);
    if (!idTokenResult) {
      throw Error("Could unexpectedly not fetch auth token");
    } else {
      return idTokenResult;
    }
  }

  // Note: we regularly refresh the ID token ourselves, because Firebase's refresh
  // mechanism seems to depend on user's actually interacting with the browser. We,
  // however, want users to stay logged in, even if they're only watching a 5 hour
  // video without ever touching anything else in the venue
  private static setTokenRefreshInterval(): void {
    this.clearTokenRefreshInterval();
    this.tokenRefreshIntervalHandle = window.setInterval(() => {
      this.fetchAuthToken().then(
        (idTokenResult) => this.storeAuthToken(idTokenResult),
        (rejectedReason) => console.error(rejectedReason)
      );
    }, TOKEN_REFRESH_INTERVAL_IN_MS);
  }

  private static clearTokenRefreshInterval(): void {
    clearInterval(this.tokenRefreshIntervalHandle);
  }

  private static async storeAuthToken(idTokenResult: firebase.auth.IdTokenResult): Promise<void> {
    console.debug(`Updating ID token:
      authAt:    ${idTokenResult.authTime}
      issuedAt:  ${idTokenResult.issuedAtTime}
      expiresAt: ${idTokenResult.expirationTime}`);
    await userStoreService.setAuthToken(idTokenResult.token);
  }

  private static async clearAuthToken(): Promise<void> {
    await userStoreService.setAuthToken(undefined);
  }

  /**
   * Sanitize the email address returned by Firebase before we use it as the user ID.
   *
   * The sanitization is necessary, because for the Cvent/Bizzabo login the services generate
   * an artificial email address using the Cvent/Bizzabo confirmation number (confidential!)
   * and the real email address (if known!). To prevent the confirmation number from
   * appearing in tracking and chat interface, we need to remove the confirmation number.
   *
   * @param emailAddress The Firebase email address.
   */
  static sanitizeEmailAddress(emailAddress: string | null): string {
    if (emailAddress) {
      // ticket numbers like from the email address, email address containing ticket numbers have pattern like
      // "user.name+ticket-number@email.provider"
      const match = /([^+]+)\+?.*(@.+)/.exec(emailAddress);
      if (!!match && match.length === 3) {
        emailAddress = match[1].concat(match[2]);
      }
      return emailAddress;
    } else {
      return "";
    }
  }

  static async openProfileForAttendee(userId: string): Promise<void> {
    const architectureId = venueStoreService.getArchitectureId();
    if (!architectureId) {
      return;
    }
    await userStoreService.openProfileForAttendee(architectureId, userId);
  }

  /**
   * Updates a user's information both in user store and in the real time database.
   * @param userInfo the updated information of the user.
   * @param currentAreaId the id of the area the user currently is in.
   */
  static async updateUserInfo(userInfo: UserInfo, currentAreaId: string): Promise<void> {
    const architectureId = venueStoreService.getArchitectureId();
    if (!architectureId) {
      return;
    }

    await userStoreService.updateUser(architectureId, userInfo);
    await PresenceService.updateAttendee(currentAreaId);
  }
}
