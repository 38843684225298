import { StoreOptions } from "vuex";
import { RootState } from "@/store/RootState";
import { StoreModuleName } from "@/store/StoreModuleName";
import uiModule from "@/store/modules/ui/uiModule";
import userModule from "@/store/modules/user/userModule";
import venueModule from "@/store/modules/venue/venueModule";
import cookieBannerModule from "@/store/modules/cookieBanner/cookieBannerModule";
import presenceModule from "@/store/modules/presence/presenceModule";
import mediaBoardSocialContentModule from "@/store/modules/mediaBoardSocialContent/mediaBoardSocialContentModule";

/**
 * Configuration object used to create store instances.
 */
export const storeOptions: StoreOptions<RootState> = {
  modules: {
    [StoreModuleName.ui]: uiModule,
    [StoreModuleName.venue]: venueModule,
    [StoreModuleName.user]: userModule,
    [StoreModuleName.cookieBanner]: cookieBannerModule,
    [StoreModuleName.presence]: presenceModule,
    [StoreModuleName.mediaBoardSocialContent]: mediaBoardSocialContentModule
  }
};
