import { TypedMutation } from "@/store/framework/TypedMutation";
import { MediaBoardSocialContentState } from "./mediaBoardSocialContentState";
import { MediaBoardLikeVuexRef } from "@/model/media-board-like-ref";

export enum MediaBoardSocialContentMutation {
  UPDATE_RECOMMENDATION = "UPDATE_RECOMMENDATION",
  UPDATE_LIKES = "UPDATE_LIKES"
}

interface MediaBoardSocialContentMutations {
  [MediaBoardSocialContentMutation.UPDATE_RECOMMENDATION]: [string, string[]];
  [MediaBoardSocialContentMutation.UPDATE_LIKES]: MediaBoardLikeVuexRef[];
}

type MediaBoardSocialContentFunctions = {
  [name in keyof MediaBoardSocialContentMutations]: TypedMutation<
    MediaBoardSocialContentState,
    MediaBoardSocialContentMutations[name]
  >;
};

export const mediaBoardSocialContentMutations: MediaBoardSocialContentFunctions = {
  [MediaBoardSocialContentMutation.UPDATE_RECOMMENDATION](
    state: MediaBoardSocialContentState,
    [key, recommendations]: [string, string[]]
  ): void {
    // @typescript-eslint/no-unsafe-member-access
    state.mediaBoardRecommendations = {
      ...state.mediaBoardRecommendations,
      [key]: recommendations
    };
  },
  [MediaBoardSocialContentMutation.UPDATE_LIKES](
    state: MediaBoardSocialContentState,
    likes: MediaBoardLikeVuexRef[]
  ): void {
    state.myLikes = likes;
  }
};
