import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, mergeProps as _mergeProps, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "text--text text-h5" }
const _hoisted_2 = { class: "d-flex flex-column" }
const _hoisted_3 = { class: "font-weight-bold" }
const _hoisted_4 = { class: "font-weight-light" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_avatar = _resolveComponent("v-avatar")!
  const _component_user_context_menu_content = _resolveComponent("user-context-menu-content")!
  const _component_base_context_menu = _resolveComponent("base-context-menu")!

  return (_openBlock(), _createBlock(_component_base_context_menu, {
    modelValue: _ctx.isMenuOpen,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.isMenuOpen) = $event)),
    "max-width": "400px"
  }, {
    activator: _withCtx(({ props }) => [
      _createVNode(_component_v_avatar, _mergeProps({
        size: "40",
        color: "primary",
        class: "cursor-pointer ml-2",
        "data-testid": "user-preview"
      }, props), {
        default: _withCtx(() => [
          _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.initials), 1)
        ]),
        _: 2
      }, 1040)
    ]),
    tooltip: _withCtx(() => [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.user.attributes.name), 1),
        _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.user.email), 1)
      ])
    ]),
    default: _withCtx(() => [
      _createVNode(_component_user_context_menu_content, _mergeProps({
        user: _ctx.user,
        "area-id": _ctx.areaId,
        "cookie-banner-enabled": _ctx.cookieBannerEnabled
      }, _ctx.$attrs, {
        onClose: _cache[0] || (_cache[0] = ($event: any) => (_ctx.isMenuOpen = false))
      }), null, 16, ["user", "area-id", "cookie-banner-enabled"])
    ]),
    _: 1
  }, 8, ["modelValue"]))
}