import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_user_preview = _resolveComponent("user-preview")!

  return (_ctx.user && _ctx.currentAreaId)
    ? (_openBlock(), _createBlock(_component_user_preview, {
        key: 0,
        user: _ctx.user,
        "area-id": _ctx.currentAreaId,
        "cookie-banner-enabled": _ctx.cookieBannerEnabled,
        onLogout: _ctx.logOut,
        onOpenPrivacyPreferences: _ctx.openCookieBanner,
        onOpenUserProfile: _ctx.openUserProfile
      }, null, 8, ["user", "area-id", "cookie-banner-enabled", "onLogout", "onOpenPrivacyPreferences", "onOpenUserProfile"]))
    : _createCommentVNode("", true)
}