import { ActionTree, Module } from "vuex";
import { RootState } from "@/store/RootState";
import { PresenceState } from "@/store/modules/presence/PresenceState";
import { presenceGetters } from "@/store/modules/presence/presenceGetters";
import { presenceActions } from "@/store/modules/presence/presenceActions";
import { presenceMutations } from "./presenceMutations";

function initialPresenceState(): PresenceState {
  return {
    attendees: []
  };
}

const presenceModule: Module<PresenceState, RootState> = {
  namespaced: true,
  state: initialPresenceState(),
  actions: presenceActions as ActionTree<PresenceState, RootState>,
  getters: presenceGetters,
  mutations: presenceMutations
};

export default presenceModule;
