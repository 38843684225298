import { createVuetify, LocaleInstance } from "vuetify";
import * as components from "vuetify/components";
import * as directives from "vuetify/directives";
import { defaultDarkTheme, defaultLightTheme } from "./default-style";

export function initVuetify(localeAdapter?: LocaleInstance) {
  return createVuetify({
    locale: {
      adapter: localeAdapter
    },
    components,
    directives,
    theme: {
      defaultTheme: "dark",
      themes: {
        dark: {
          dark: false,
          colors: defaultDarkTheme,
          variables: {
            "medium-emphasis-opacity": 0.8
          }
        },
        light: {
          dark: false,
          colors: defaultLightTheme,
          variables: {
            "medium-emphasis-opacity": 0.8
          }
        }
      }
    }
  });
}
