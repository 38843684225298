import { presenceStoreService, uiStoreService, userStoreService } from "@/store/module-services";
import LogCapturingService from "@/services/log-capturing-service";
import { AttendeeRef } from "@/model/attendee-ref";
import { UserInfo } from "@/model/user-info";
import { removeUndefinedValues } from "@/utility/clean-objects";

export class PresenceService {
  /**
   * Connects local store to Firebase's rtdb.
   */
  static async connect(): Promise<void> {
    // don't show possibly stored attendees if user presence is not activated
    if (!PresenceService.userPresenceActive()) {
      return;
    }

    try {
      await presenceStoreService.connect(uiStoreService.getOpMode());
    } catch (e) {
      // catch firebase errors, e.g. permission denied
      const error = e as Error;
      await LogCapturingService.captureError(error.message, error.stack);
    }
  }

  /**
   * Update an attendee and set new active area.
   *
   * @param {string|undefined} newAreaId The area which the attendee is moving into.
   */
  static async updateAttendee(newAreaId: string): Promise<void> {
    // get attendee reference with the new area ID
    const attendee = await this.getAttendeeRef(newAreaId);
    if (!attendee) {
      return;
    }

    // update attendee reference in Firebase RTDB
    try {
      await presenceStoreService.updateAttendee({
        venueOpMode: uiStoreService.getOpMode(),
        attendee
      });
    } catch (e) {
      // catch firebase errors, e.g. permission denied
      const error = e as Error;
      await LogCapturingService.captureError(error.message, error.stack);
    }
  }

  /**
   * Removes the current attendee's presence from the venue.
   */
  static async removeAttendee(): Promise<void> {
    // get attendee reference; the area id is irrelevant as the user is removed
    const attendee = await this.getAttendeeRef("");
    if (!attendee) {
      return;
    }

    // remove attendee reference in store and Firebase RTDB
    try {
      await presenceStoreService.removeAttendee({
        venueOpMode: uiStoreService.getOpMode(),
        attendee
      });
    } catch (e) {
      // catch firebase errors, e.g. permission denied
      const error = e as Error;
      await LogCapturingService.captureError(error.message, error.stack);
    }
  }

  /**
   * Builds the attendee reference if the current user for the given area.
   * Returns `undefined`, if tracking is disabled or no current user is available.
   *
   * @param {string} areaId The area ID.
   * @return {AttendeeRef|undefined} The attendee reference if available.
   */
  private static async getAttendeeRef(areaId: string): Promise<AttendeeRef | undefined> {
    // don't track attendee if user presence not activated
    if (!PresenceService.userPresenceActive()) {
      return undefined;
    }

    // don't track attendee if user details are not available
    const user = userStoreService.getUser();
    if (!user) {
      const error = new Error("Failed to update presence of attendee - User details missing");
      await LogCapturingService.captureError(error.message, error.stack);
      return undefined;
    }

    // create attendee reference
    return this.makeAttendee(user, areaId);
  }

  /**
   * Whether or not the user presence feature is activated.
   */
  static userPresenceActive(): boolean {
    const featureFlagSet = uiStoreService.getUiConfig()?.features?.userPresence ?? false;
    const loginEnabled = uiStoreService.isLoginEnabled() ?? false;
    return featureFlagSet && loginEnabled;
  }

  /**
   * Transforms the current user into an AttendeeRef, storable in the firebase real time database.
   *
   * @param {UserInfo} user The user details.
   * @param {string} areaId The area ID.
   * @return {AttendeeRef} The attendee reference.
   */
  private static makeAttendee(user: UserInfo, areaId: string): AttendeeRef {
    const attendee = {
      uid: user.userId,
      areaId,
      name: user.attributes.name,
      company: user.attributes.company,
      title: user.attributes.title
    };
    // firebase doesn't allow entries with a value of undefined
    removeUndefinedValues(attendee);
    return attendee;
  }
}
