import { AxiosRequestConfig } from "axios";
import { userStoreService } from "@/store/module-services";

/**
 * Adds the user's authorization token to the request config.
 *
 * @param {AxiosRequestConfig} config The request config.
 * @return {AxiosRequestConfig} The modified request config.
 */
export function addAuthToken(config: AxiosRequestConfig): Promise<AxiosRequestConfig> {
  const authToken = userStoreService.getAuthToken();
  if (requiresAuthToken(config) && authToken) {
    const headers = config.headers ?? {};
    headers.Authorization = `Bearer ${authToken}`;
    config.headers = headers;
  }

  return Promise.resolve(config);
}

/**
 * Checks whether the given request is for a backend, which requires the authorization token.
 *
 * @param {AxiosRequestConfig} config The request config.
 * @return {boolean} True if the request requires an authorization token; false otherwise.
 */
function requiresAuthToken(config: AxiosRequestConfig): boolean {
  return [isMagnidServicesRequest, isGoogleCloudStorageRequest].some((fn) => fn(config));
}

/* Invoking a service of one of the Magnid backends requires an authorization token. */
function isMagnidServicesRequest(config: AxiosRequestConfig): boolean {
  // if the URL specifies no explicit host, then it is for one of the the Magnid backend services
  return !!config.url && config.url.startsWith("/");
}

/* Retrieving assets from the GCS bucket via Firebase requires an authorization token. */
function isGoogleCloudStorageRequest(config: AxiosRequestConfig): boolean {
  return !!config.url && config.url.startsWith("https://firebasestorage.googleapis.com");
}
