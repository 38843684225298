import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-8e6c01ca"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["data-testid"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    "data-testid": `base-asset-container[${_ctx.assetId}]`,
    class: "base-asset-container"
  }, [
    _renderSlot(_ctx.$slots, "default", {
      alt: _ctx.alt,
      assetKey: _ctx.assetKey,
      objectUrl: _ctx.assetObjectUrl
    }, undefined, true)
  ], 8, _hoisted_1))
}