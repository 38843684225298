import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_select = _resolveComponent("v-select")!

  return (_ctx.availableLanguages.length > 1)
    ? (_openBlock(), _createBlock(_component_v_select, {
        key: 0,
        items: _ctx.availableLanguages,
        "model-value": _ctx.$i18n.locale,
        label: _ctx.$t('languageSelection'),
        "item-value": "locale",
        "item-title": "name",
        variant: "underlined",
        "hide-details": "auto",
        class: "language-selection",
        color: _ctx.architectureStyle.primary,
        "data-testid": "language-selection",
        "onUpdate:modelValue": _ctx.setLanguage
      }, null, 8, ["items", "model-value", "label", "color", "onUpdate:modelValue"]))
    : _createCommentVNode("", true)
}