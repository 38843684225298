import { uiStoreService, venueStoreService } from "@/store/module-services";
import store from "@/store";
import { RootState } from "@/store/RootState";
import router from "@/router";
import { VvenueOpMode } from "@/services/backend/generated/model/vvenue-op-mode";
import { PublishState } from "@/store/modules/ui/UiState";
import loadVenue from "@/utility/load-venue";
import FetchUserProfileService from "@/services/fetch-user-profile-service";

const POLLING_FREQUENCY_MS = 120_000; // 2 minutes

/**
 * A type containing all relevant properties required to
 * decide whether a venue must be reloaded from the backend.
 */
type VenueVersionUpdate = {
  loginEnabled: boolean;
  loggedIn: boolean;
  oldVersion: number;
  newVersion: number;
};

/**
 * Handles all re-/loading of the venue configuration.
 */
export default class VenuePollingService {
  private static retryPolling = true;
  private static disablePollingFlag = false;
  // see https://vuex.vuejs.org/api/#watch
  private static venueVersionUpdateUnwatchFunction: (() => void) | null;

  /**
   * Starts reloading the venue if the latest published version increases.
   *
   * @param withBackendPolling If true, then enables regular polling of the venue version from the backend;
   *                            if false, then only watches changes in the store.
   */
  static watch(withBackendPolling = true): void {
    // watch for changes to the current venue version
    if (!this.venueVersionUpdateUnwatchFunction) {
      this.venueVersionUpdateUnwatchFunction = store.watch(
        (state) => this.buildVenueVersionUpdate(state),
        (newValue) => this.onVenueVersionUpdate(newValue)
      );
    }

    // regularly fetch the latest published venue version from the backend;
    // start polling *after* the watcher have been registered to avoid missing an update!!
    if (withBackendPolling) {
      const handler = async function () {
        // eslint-disable-next-line no-constant-condition
        while (true) {
          if (VenuePollingService.disablePollingFlag) {
            VenuePollingService.disablePollingFlag = false;
            break;
          }
          try {
            await Promise.all([FetchUserProfileService.fetchUserProfile(), VenuePollingService.refreshVenueVersion()]);
          } finally {
            // wait a certain time before running the loop again
            await new Promise((resolve) => window.setTimeout(resolve, POLLING_FREQUENCY_MS));
          }
        }
      };
      // should never happen
      handler().catch((reason) => console.log(reason));
    }
  }

  /**
   * Stop reloading the venue automatically.
   */
  static unwatch(): void {
    this.disablePollingFlag = true;
    if (this.venueVersionUpdateUnwatchFunction) {
      this.venueVersionUpdateUnwatchFunction();
      this.venueVersionUpdateUnwatchFunction = null;
    }
  }

  /**
   * Resets the disable polling flag.
   */
  static resetDisablePollingFlag(): void {
    this.disablePollingFlag = false;
  }

  /**
   * Loads the venue version from the backend into the UI store.
   * @private
   */
  private static async refreshVenueVersion() {
    try {
      // only temporarily disable global errorDialog if there is no error present to prevent it from flickering
      if (!uiStoreService.getErrorState().globalError.hasErrors) {
        await uiStoreService.suppressDefaultErrorDialog();
      }

      await uiStoreService.loadVenueVersion();
      this.retryPolling = true;
    } catch (err) {
      // if polling fails for the first time, clear error and retry once time
      if (this.retryPolling) {
        await uiStoreService.clearError();
        this.retryPolling = false;
      } else {
        console.error("Failed to load venue version: ", err);
      }
    } finally {
      await uiStoreService.enableDefaultErrorDialog();
    }
  }

  private static buildVenueVersionUpdate(state: RootState): VenueVersionUpdate {
    const loginEnabled = state.ui.loginEnabled ?? true;
    const loggedIn = state.user.isLoggedIn ?? false;

    const venue = state.venue.venue; // from the current local venue model
    const venueVersion = state.ui.venueVersion; // from the latest /version call

    let oldVersion: number | undefined = -1;
    let newVersion = -1;
    if (venue && venueVersion) {
      // In stage Venue UI, the "version" is relevant. In live Venue UI, the "publishedVersion" is relevant
      if (venueVersion.mode === VvenueOpMode.STAGE) {
        oldVersion = venue.version;
        newVersion = venueVersion.version;
      } else if (venueVersion.mode === VvenueOpMode.LIVE) {
        oldVersion = venue.publishInfo?.publishedVersion ?? PublishState.UNPUBLISHED;
        newVersion = venueVersion.publishedVersion;
      } else {
        // fall back to default for unknown OpMode
      }
    }

    return {
      loginEnabled,
      loggedIn,
      oldVersion,
      newVersion
    } as VenueVersionUpdate;
  }

  /**
   * Triggers a reload of the venue if the version on the server has changed.
   *
   * @param update The latest update of the venue version.
   * @private
   */
  private static onVenueVersionUpdate(update: VenueVersionUpdate) {
    // Don't load the venue if the user is not logged in
    if (update.loginEnabled && !update.loggedIn) {
      return;
    }

    // Don't load the venue if the version has not changed
    if (update.oldVersion === update.newVersion) {
      return;
    }

    if (update.newVersion === -1) {
      console.log("Venue was unpublished on the server. Redirecting to root page.");
      venueStoreService.setVenue(undefined).catch((err) => console.error("Failed to unset venue: ", err));
      router.push("/").catch((err: Error) => console.error("Failed to redirect to root page: ", err));
    } else if (update.newVersion < update.oldVersion) {
      /**
       * If the venue version retrieved from the /version endpoint is older than the venue that is currently loaded,
       * the version is outdated and needs to be refreshed.
       * This could happen during login, as the version gets loaded before the login, but the venue is loaded after
       * the login. If the version changes during that time, an endless loop is triggered that constantly
       * requests the venue because the versions from the (outdated) /version endpoint and the version of the venue
       * mismatch.
       */
      console.log("The Venue version is outdated. Reloading the version.");
      VenuePollingService.refreshVenueVersion().catch((err) => console.error("Failed to load venue version: ", err));
    } else {
      console.log("The Venue version changed on the server. Reloading the venue.");
      loadVenue().catch((err) => console.error("Failed to load venue: ", err));
    }
  }
}
