import AbstractStoreService from "@/store/framework/AbstractStoreService";
import { RootState } from "@/store/RootState";
import { Venue } from "@/services/backend/generated/model/venue";
import { Architecture } from "@/services/backend/generated/model/architecture";
import { Area } from "@/services/backend/generated/model/area";
import { ViewPoint } from "@/services/backend/generated/model/view-point";
import { GlobalWidget } from "@/services/backend/generated/model/global-widget";
import { LocalizedText } from "@/services/backend/generated/model/localized-text";
import { Floorplan } from "@/services/backend/generated/model/floorplan";
import { Asset } from "@/services/backend/generated/model/asset";
import { Icon } from "@/services/backend/generated/model/icon";
import { CventEvent } from "@/services/backend/generated/model/cvent-event";
import { VenueState } from "@/store/modules/venue/VenueState";
import { CookieBannerConfig } from "@/services/backend/generated/model/cookie-banner-config";
import { FrontendCookieProvider } from "@/model/frontend-cookie-provider";
import { Style } from "@/services/backend/generated/model/style";
import { VenueGetters } from "@/store/modules/venue/venueGetters";
import { VenueAction } from "@/store/modules/venue/venueActions";
import { defaultDarkStyle } from "@/default-style";
import { UserProfileAttribute } from "@/services/backend/generated/model/user-profile-attribute";

/**
 * Wraps interaction with the "venue" store module.
 */
export class VenueStoreService extends AbstractStoreService<VenueState, RootState, VenueGetters> {
  /**
   * Loads the venue from the backend into the store and try to select
   * the architecture with given architectureId.
   *
   * @param architectureId the architecture id to select
   */
  loadVenue(architectureId?: string): Promise<void> {
    return this.dispatch(VenueAction.loadVenue, architectureId);
  }

  setVenue(venue: Venue | undefined): Promise<void> {
    return this.dispatch(VenueAction.setVenue, venue);
  }

  isLoaded(): boolean {
    return this._get("isLoaded");
  }

  getArchitecture(): Architecture | undefined {
    return this._get("getArchitecture");
  }

  getArchitectureId(): string | undefined {
    return this._get("getArchitectureId");
  }

  getDefaultArea(): Area | undefined {
    return this._get("getDefaultArea");
  }

  getAreaById(id: string): Area | undefined {
    return this._get("getAreaById")(id);
  }

  getDefaultViewPointByAreaId(id: string): ViewPoint | undefined {
    return this._get("getDefaultViewPointByAreaId")(id);
  }

  getGlobalWidgets(): GlobalWidget[] | undefined {
    return this._get("getGlobalWidgets");
  }

  getImprint(): LocalizedText[] | undefined {
    return this._get("getImprint");
  }

  getPrivacyNotice(): LocalizedText[] | undefined {
    return this._get("getPrivacyNotice");
  }

  getFloorplan(): Floorplan | undefined {
    return this._get("getFloorplan");
  }

  getAssetById(id: string): Asset | undefined {
    return this._get("getAssetById")(id);
  }

  getIconByType(type: string): Icon | undefined {
    return this._get("getIconByType")(type);
  }

  getIcons(): Icon[] {
    return this._get("getIcons");
  }

  getCventLoginEnabled(): boolean | undefined {
    return this._get("getCventLoginEnabled");
  }

  getCventEvent(): CventEvent | undefined {
    return this._get("getCventEvent");
  }

  getPublishedVersion(): number {
    return this._get("getPublishedVersion");
  }

  getCookieBannerConfig(): CookieBannerConfig | undefined {
    return this._get("getCookieBannerConfig");
  }

  isCookieBannerEnabled(): boolean {
    return this._get("isCookieBannerEnabled");
  }

  isPersonalizedTrackingEnabled(): boolean {
    return this._get("isPersonalizedTrackingEnabled");
  }

  getCookieProviders(): FrontendCookieProvider[] {
    return this._get("getCookieProviders");
  }

  getVideoBackgroundCookieIds(): string[] {
    return this._get("getVideoBackgroundCookieIds");
  }

  setAccepted(provider: FrontendCookieProvider): Promise<void> {
    return this.dispatch(VenueAction.setAccepted, provider);
  }

  isAllExternalMediaAccepted(): boolean {
    return this._get("isAllExternalMediaAccepted");
  }

  setAllExternalMediaAccepted(value: boolean): Promise<void> {
    return this.dispatch(VenueAction.setAllExternalMediaAccepted, value);
  }

  acceptAllProviders(): Promise<void> {
    return this.dispatch(VenueAction.acceptAllProviders);
  }

  getNotAcceptedCookies(): string[] {
    return this._get("getNotAcceptedCookies");
  }

  getStyle(): Style {
    //  in the rare case of the venue not providing a style, we use the default one
    return this._get("getStyle") ?? defaultDarkStyle;
  }

  getUserProfileAttributes(): Array<UserProfileAttribute> | undefined {
    return this._get("getUserProfileAttributes");
  }
}
