import { NavigationGuardNext, RouteLocationNormalized } from "vue-router";
import { venueStoreService } from "@/store/module-services";
import ensureVenue from "@/utility/ensure-venue";

/**
 * Ensures a venue and the architecture is loaded.
 * If the route ends in /area a redirect to default area will be performed.
 * If the route defines a non existing area a redirect to default area will be performed.
 * Otherwise the given architecture and area is opened.
 */
export async function ensureAreaBeforeGuard(
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
): Promise<void> {
  const routeAreaId: string | undefined = to.params.areaId as string;
  const routeArchitectureId: string | undefined = to.params.architectureId as string;

  // Ensure that the venue is loaded
  await ensureVenue(routeArchitectureId);

  const venueArchitectureId = venueStoreService.getArchitectureId();
  const defaultAreaId = venueStoreService.getDefaultArea()?.id;

  // there is no areaId defined in route, use default area for redirect
  if (!routeAreaId && venueArchitectureId && defaultAreaId) {
    return next({
      name: "Area",
      params: {
        areaId: defaultAreaId,
        architectureId: venueArchitectureId
      }
    });
  } else {
    // there is an area id defined, but let's first try if the area exist. If not, redirect to default area
    const areaByRouteId = venueStoreService.getAreaById(routeAreaId);
    if (!areaByRouteId && venueArchitectureId && defaultAreaId) {
      return next({
        name: "Area",
        params: {
          areaId: defaultAreaId,
          architectureId: venueArchitectureId
        }
      });
    } else {
      // everything is fine, specified architecture and area is available
      return next();
    }
  }
}
