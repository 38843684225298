import stringify from "json-stable-stringify";

/**
 * Performs a deep equals on the two given values/objects.
 * Can also handle reactive Vue objects.
 *
 * @param a the first object/value
 * @param b the second object/value
 */
export default function isEqual(a: unknown, b: unknown): boolean {
  // We can not use Lodash isEqual reliably because the compared Objects can be reactive Vue objects. Instead of normal
  // data attributes, they have functions, which in some cases lead to different equality results than
  // comparing the actual values (resolved from the functions).
  // JSON.stringify seems to be the recommended way to deep equal reactive Vue objects.
  // See https://stackoverflow.com/questions/58097556/how-to-convert-a-vue-wrapped-object-to-a-normal-object
  // However JSON.stringify does not sort the JSON object properties, resulting in differences if the same properties
  // are present in a different order. Which brings us to json-stable-stringify, a stringify version with sorting
  // that also handles deep objects correctly.
  // See https://stackoverflow.com/questions/16167581/sort-object-properties-and-json-stringify
  return stringify(a) === stringify(b);
}
