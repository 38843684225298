
import { defineComponent, PropType } from "vue";
import { ImageSizes } from "@/model/image-sizes";
import { FetchAssetContext } from "@/utility/fetch-asset-context";
import { venueStoreService } from "@/store/module-services";
import { fromLocalizedText } from "@/i18n/localized-string";

export default defineComponent({
  props: {
    assetId: {
      type: String,
      required: true
    },
    imageSize: {
      type: String as PropType<ImageSizes | undefined>,
      default: undefined
    },
    // Allows the parent component to provide an existing object URL for the asset.
    // We will use the given object URL instead of fetching the asset ourselves, but
    // releasing it is the responsibility of the parent component.
    objectUrl: {
      type: String as PropType<string | undefined>,
      default: undefined
    }
  },

  data() {
    return {
      fetchAssetContext: new FetchAssetContext(),
      // The object URL managed by this component; in contrast to `objectUrl`, which
      // is the object URL managed by the parent component.
      myObjectUrl: undefined as string | undefined
    };
  },

  computed: {
    assetKey(): string {
      return `${this.assetId}|${this.imageSize ?? "undefined"}`;
    },
    assetObjectUrl(): string | undefined {
      return this.objectUrl ?? this.myObjectUrl;
    },
    alt(): string {
      const asset = venueStoreService.getAssetById(this.assetId);
      return fromLocalizedText(asset?.alts ?? [], asset?.displayName ?? "");
    }
  },

  watch: {
    // react if either assetId or imageSize changes, which are both part of the asset key
    assetKey: {
      immediate: true,
      async handler() {
        await this.acquireAsset(this.assetId, this.imageSize);
      }
    }
  },

  beforeUnmount() {
    this.fetchAssetContext.releaseAssets();
  },

  methods: {
    async acquireAsset(assetId: string, imageSize: ImageSizes | undefined) {
      this.fetchAssetContext.updateAsset(assetId, imageSize);
      const objectUrl = await this.fetchAssetContext.getObjectUrl();
      if (objectUrl instanceof Error) {
        this.handleError(assetId, imageSize, objectUrl);
      } else {
        this.myObjectUrl = objectUrl;
        this.$emit("load");
      }
    },

    handleError(assetId: string, imageSize: ImageSizes | undefined, error: Error) {
      this.$emit("error", error);
      console.error(error);
    }
  }
});
