import AbstractStoreService from "@/store/framework/AbstractStoreService";
import NonConcurrentAsyncAction from "@/utility/non-concurrent-async-action";
import { VenueVersion } from "@/services/backend/generated/model/venue-version";
import { LoginConfiguration } from "@/services/backend/generated/model/login-configuration";
import { ErrorCode, ErrorState, PublishState, UIConfigState, UiState } from "@/store/modules/ui/UiState";
import { RootState } from "@/store/RootState";
import { UiGetters } from "@/store/modules/ui/uiGetters";
import { UiAction } from "@/store/modules/ui/uiActions";
import { VvenueOpMode } from "@/services/backend/generated/model/vvenue-op-mode";

export class UiStoreService extends AbstractStoreService<UiState, RootState, UiGetters> {
  private readonly loadVenueVersionWrapper = new NonConcurrentAsyncAction(() =>
    this.dispatch(UiAction.loadVenueVersion)
  );

  /**
   * Gets the current error state object.
   *
   * It's best to inspect this object's "hasErrors" property directly - this will allow tsc to infer the appropriate
   * type for you (hence allowing access to "code" and "message" appropriately).
   */
  getErrorState(): ErrorState {
    return this._get("getErrorState");
  }

  /**
   * Gets the version of the venue ui application.
   */
  getAppVersion(): string {
    return this._get("getAppVersion");
  }

  /**
   * Sets the given error code as well as an optional message.
   *
   * @param code The error code to set.
   * @param message Optional text message.
   */
  async setError(code: ErrorCode, message = ""): Promise<void> {
    await this.dispatch(UiAction.setError, { code, message });
  }

  /**
   * Clears the error state.
   */
  async clearError(): Promise<void> {
    await this.dispatch(UiAction.clearError);
  }

  /**
   * Gets header height
   */
  getHeaderHeight(): number {
    return this._get("getHeaderHeight");
  }

  /**
   * Sets the given error code as well as an optional message.
   *
   * @param code The error code to set.
   * @param message Optional text message.
   */
  async setHeaderHeight(height: number): Promise<void> {
    await this.dispatch(UiAction.setHeaderHeight, height);
  }

  /**
   * Suppresses the default error dialog from popping up automatically.
   *
   * This is useful for use-cases where you want to display API error messages inside of dedicated components and don't
   * want the default error popup to show. Please make sure to call {@link enableDefaultErrorDialog} when leaving that
   * section of the app again!
   */
  async suppressDefaultErrorDialog(): Promise<void> {
    await this.dispatch(UiAction.suppressDefaultErrorDialog);
  }

  /**
   * Re-enables the default error dialog again.
   */
  async enableDefaultErrorDialog(): Promise<void> {
    await this.dispatch(UiAction.enableDefaultErrorDialog);
  }

  /**
   * Returns if the default error dialog is currently suppressed.
   *
   * @return The UI config.
   */
  isDefaultErrorDialogSuppressed(): boolean {
    return this._get("isDefaultErrorDialogSuppressed");
  }

  /**
   * Returns the UI config written during deployment.
   *
   * @return The UI config.
   */
  getUiConfig(): UIConfigState | undefined {
    return this._get("getUiConfig");
  }

  /**
   * Loads the UI config from the static assets into the store.
   */
  loadUiConfig(): Promise<void> {
    return this.dispatch(UiAction.loadUiConfig);
  }

  /**
   * Returns the default locale.
   *
   * @return The default locale.
   */
  getDefaultLocale(): string | undefined {
    return this._get("getDefaultLocale");
  }

  /**
   * Returns the list of supported locales.
   *
   * @return The supported locales.
   */
  getSupportedLocales(): string[] | undefined {
    return this._get("getSupportedLocales");
  }

  /**
   * Sets the venue version to the given value.
   *
   * <b>This method should only be used for testing purposes!</b>
   *
   * @param venueVersion The venue version.
   */
  async setVenueVersion(venueVersion: VenueVersion): Promise<void> {
    await this.dispatch(UiAction.setVenueVersion, venueVersion);
  }

  /**
   * Returns the venue operation mode.
   */
  getOpMode(): VvenueOpMode | undefined {
    return this._get("getOpMode");
  }

  /**
   * Gets the id of the active architecture - before the whole venue is loaded.
   */
  getPublishedArchitectureId(): string | undefined {
    return this._get("getPublishedArchitectureId");
  }

  /**
   * Checks whether there is a published version of the venue.
   *
   * @return PUBLISHED if a published version exists; UNPUBLISHED otherwise;
   *          UNKNOWN if the information was not yet retrieved from the backend.
   */
  isPublished(): PublishState {
    return this._get("isPublished");
  }

  /**
   * Loads the latest version information from the backend into the store.
   */
  async loadVenueVersion(): Promise<void> {
    await this.loadVenueVersionWrapper.exec();
  }

  isLoginEnabled(): boolean | undefined {
    return this._get("isLoginEnabled");
  }

  async setLoginEnabled(loginEnabled: boolean): Promise<void> {
    await this.dispatch(UiAction.setLoginEnabled, loginEnabled);
  }

  getLoginConfigurations(): LoginConfiguration[] {
    return this._get("getLoginConfigurations");
  }

  async setLoginConfigurations(loginConfigurations: LoginConfiguration[]): Promise<void> {
    await this.dispatch(UiAction.setLoginConfigurations, loginConfigurations);
  }

  getLastAreaId(): string | undefined {
    return this._get("getLastAreaId");
  }

  async setLastAreaId(id: string): Promise<void> {
    await this.dispatch(UiAction.setLastAreaId, id);
  }

  getServerTime(): Date {
    return this._get("getServerTime");
  }

  async setServerTime(time: Date): Promise<void> {
    await this.dispatch(UiAction.setServerTime, time);
  }

  async serverTimeTick(clockTickInMs: number): Promise<void> {
    await this.dispatch(UiAction.serverTimeTick, clockTickInMs);
  }

  async addToActiveDialogStack(dialog: Record<string, unknown>): Promise<void> {
    await this.dispatch(UiAction.addToActiveDialogStack, dialog);
  }

  async removeFromActiveDialogStack(dialog: Record<string, unknown>): Promise<void> {
    await this.dispatch(UiAction.removeFromActiveDialogStack, dialog);
  }

  async popFromActiveDialogStack(): Promise<void> {
    await this.dispatch(UiAction.popFromActiveDialogStack);
  }

  isDialogActive(dialog: Record<string, unknown>): boolean {
    return this._get("isDialogActive")(dialog);
  }

  getLastAddedActiveDialog(): Record<string, unknown> | undefined {
    return this._get("getLastAddedActiveDialog");
  }

  async openChat(): Promise<void> {
    await this.dispatch(UiAction.openChat);
  }

  async closeChat(): Promise<void> {
    await this.dispatch(UiAction.closeChat);
  }

  async expandChat(): Promise<void> {
    await this.dispatch(UiAction.expandChat);
  }

  async minimizeChat(): Promise<void> {
    await this.dispatch(UiAction.minimizeChat);
  }

  async openChatWithRoom(room: string, chats: string[]): Promise<void> {
    await this.dispatch(UiAction.openChatWithRoom, { room, chats });
  }

  isChatOpen(): boolean {
    return this._get("isChatOpen");
  }

  isChatExpanded(): boolean {
    return this._get("isChatExpanded");
  }

  getRoomChats(): Record<string, string[]> {
    return this._get("getRoomChats");
  }

  getTabTitle(): string | undefined {
    return this._get("getTabTitle");
  }

  getFavicon(): string | undefined {
    return this._get("getFavicon");
  }

  hasNavigationGuards(): boolean {
    return this._get("hasNavigationGuards");
  }

  isTryingToNavigate(): boolean {
    return this._get("isTryingToNavigate");
  }

  async addNavigationGuard(widgetId: string): Promise<void> {
    await this.dispatch(UiAction.addNavigationGuard, widgetId);
  }

  async removeNavigationGuard(widgetId: string): Promise<void> {
    await this.dispatch(UiAction.removeNavigationGuard, widgetId);
  }

  /**
   * Navigate, taking into account any widgets that need time to unload themselves.
   *
   * Widgets can register themselves using `uiStoreService.addNavigationGuard(widgetId)`,
   * which means that page navigation will be blocked until they subsequently call
   * `uiStoreService.removeNavigationGuard(widgetId)`.
   * They can watch `uiStoreService.isTryingToNavigate()` to be notified when a navigation has been requested,
   * at which point they should unload themselves and then remove their navigation guard.
   */
  async performGuardedNavigation(): Promise<void> {
    if (this.hasNavigationGuards()) {
      await this.dispatch(UiAction.beginGuardedNavigation);

      // Wait for `hasNavigationGuards` to turn false
      await new Promise<void>((resolve) => {
        this.store.watch(
          () => this.hasNavigationGuards(),
          (hasNavigationGuards) => {
            if (!hasNavigationGuards) {
              resolve();
            }
          }
        );
      });
      await this.dispatch(UiAction.completeGuardedNavigation);
    }
  }

  getAttendeeToChatUid(): string | undefined {
    return this._get("getAttendeeToChatUid");
  }

  isRouterGuardCheckingNow(): boolean {
    return this._get("isRouterGuardCheckingNow");
  }

  async waitRouterGuards(): Promise<void> {
    await this.dispatch(UiAction.waitRouterGuards);
  }

  async routerGuardsFinished(): Promise<void> {
    await this.dispatch(UiAction.routerGuardsFinished);
  }

  async setAttendeeToChatUid(attendeeUid: string | undefined): Promise<void> {
    await this.dispatch(UiAction.setAttendeeToChatUid, attendeeUid);
  }
}
