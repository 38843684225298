/* tslint:disable */
/* eslint-disable */
/**
 * Magnid Chat Service API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ApiError } from '../model';
/**
 * ZoomClientControllerApi - axios parameter creator
 * @export
 */
export const ZoomClientControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Creates a participant signature that is necessary for the web SDK client to authenticate itself. 
         * @summary Creates a participant signature
         * @param {string} meetingNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSignature: async (meetingNumber: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'meetingNumber' is not null or undefined
            assertParamExists('getSignature', 'meetingNumber', meetingNumber)
            const localVarPath = `/zoom/signature/{meetingNumber}`
                .replace(`{${"meetingNumber"}}`, encodeURIComponent(String(meetingNumber)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ZoomClientControllerApi - functional programming interface
 * @export
 */
export const ZoomClientControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ZoomClientControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * Creates a participant signature that is necessary for the web SDK client to authenticate itself. 
         * @summary Creates a participant signature
         * @param {string} meetingNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSignature(meetingNumber: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSignature(meetingNumber, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ZoomClientControllerApi - factory interface
 * @export
 */
export const ZoomClientControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ZoomClientControllerApiFp(configuration)
    return {
        /**
         * Creates a participant signature that is necessary for the web SDK client to authenticate itself. 
         * @summary Creates a participant signature
         * @param {string} meetingNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSignature(meetingNumber: string, options?: any): AxiosPromise<string> {
            return localVarFp.getSignature(meetingNumber, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ZoomClientControllerApi - object-oriented interface
 * @export
 * @class ZoomClientControllerApi
 * @extends {BaseAPI}
 */
export class ZoomClientControllerApi extends BaseAPI {
    /**
     * Creates a participant signature that is necessary for the web SDK client to authenticate itself. 
     * @summary Creates a participant signature
     * @param {string} meetingNumber 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ZoomClientControllerApi
     */
    public getSignature(meetingNumber: string, options?: AxiosRequestConfig) {
        return ZoomClientControllerApiFp(this.configuration).getSignature(meetingNumber, options).then((request) => request(this.axios, this.basePath));
    }
}
