
import { defineComponent, PropType } from "vue";
import MobileAttendeeCard from "@/ui/components/presence-widget/MobileAttendeeCard.vue";
import { AttendeeRef } from "@/model/attendee-ref";

export default defineComponent({
  components: {
    MobileAttendeeCard
  },
  props: {
    attendee: {
      type: Object as PropType<AttendeeRef>,
      required: true
    }
  },
  methods: {
    unselectAttendee() {
      this.$emit("unselect-attendee");
    },
    shouldUnselectAttendee(e: MouseEvent) {
      // When clicking outside the floating attendee card, it should close in general. However, when we click on another
      // attendee card, the events have a conflict and instead of showing the other attendee, the card is closed.
      // To counteract, we prevent unselecting the attendee here if the user clicked on an attendee card.
      const clickedElement = e.target as HTMLElement;
      const attendeeCardParent = clickedElement.closest(".attendee-card");
      return attendeeCardParent === null;
    }
  }
});
