/**
 * Helper service to synchronize our local time with the server time. Venue content can be time restricted.
 * By using the server time we prevent a wrong client clock (on purpose or not) to effect what content is shown.
 * Filtering on server side would be even safer, but comes with a lot of complexity.
 *
 * Basic approach: On each successful API request, extract the server time from the HTTP header and store it. Start
 * an interval that increases the stored timestamp by one second every second. While there will be some drift,
 * we do not have the highest precision requirement (<5 sec diff is ok), and we re-sync with every API call.
 * This solution is easy and does not need additional API calls which would hurt server performance.
 *
 * Later we may implement a more server-side solution if more protection is required.
 */

import { AxiosResponse } from "axios";
import { uiStoreService } from "@/store/module-services";

const SERVER_TIME_TICK_IN_MS = 1_000;
let serverTimeIntervalId: number;

// no errors: synchronize our "server clock" using the response date header
// (see https://www.w3.org/Protocols/rfc2616/rfc2616-sec14.html#sec14.18)
export async function synchronizeServerClock(res: AxiosResponse<unknown>): Promise<AxiosResponse<unknown>> {
  const headers = res.headers as { date?: string; "cache-control"?: string };
  const cacheControl = headers["cache-control"];

  if (cacheControl == null || cacheControl?.toLocaleLowerCase("en-US").includes("no-cache")) {
    const date = headers.date;
    if (date != null) {
      await uiStoreService.setServerTime(new Date(date));
      if (serverTimeIntervalId) {
        clearInterval(serverTimeIntervalId);
      }
      serverTimeIntervalId = window.setInterval(() => serverTimeTick(), SERVER_TIME_TICK_IN_MS);
    }
  }

  return res;
}

function serverTimeTick() {
  uiStoreService.serverTimeTick(SERVER_TIME_TICK_IN_MS).catch((err) => console.error(err));
}
