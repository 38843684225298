import { createI18n } from "vue-i18n";
export { useI18n } from "vue-i18n";

const i18n = createI18n({
  legacy: false, // Vuetify does not support the legacy mode of vue-i18n
  locale: "en",
  fallbackLocale: "en",
  messages: loadLocaleMessages()
});

export function getI18n() {
  return i18n;
}

/**
 * Load locales messages from the JSON-files within the locales directory.
 *
 * @return Local messages from all JSON-files within the locales directory.
 */
function loadLocaleMessages(): { [key: string]: { [key: string]: string } } {
  const locales = require.context("@/i18n/locales", true, /[A-Za-z0-9-_,\s]+\.json$/i);
  const messages: { [key: string]: { [key: string]: string } } = {};
  locales.keys().forEach((key) => {
    const matched = RegExp(/([A-Za-z0-9-_]+)\./i).exec(key);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = locales(key);
    }
  });
  return messages;
}
