
import { defineComponent, PropType } from "vue";
import { AttendeeRef } from "@/model/attendee-ref";
import getInitials from "@/utility/get-initials";
import AttendeeContextMenuContent from "@/ui/components/presence-widget/AttendeeContextMenuContent.vue";
import BaseContextMenu from "@/ui/base/BaseContextMenu.vue";
import { MIN_ATTENDEE_CARD_HEIGHT, MIN_ATTENDEE_CARD_WIDTH } from "@/ui/components/presence-widget/constants";

export default defineComponent({
  components: { BaseContextMenu, AttendeeContextMenuContent },
  emits: ["open-profile-for-attendee", "start-chat", "close", "menu-toggled"],
  props: {
    attendee: {
      type: Object as PropType<AttendeeRef>,
      required: true
    },
    isLoadingAttendeeProfile: Boolean,
    attendeeIdToLoad: String,
    active: Boolean
  },
  data() {
    return {
      isMenuOpen: false,
      MIN_ATTENDEE_CARD_HEIGHT,
      MIN_ATTENDEE_CARD_WIDTH
    };
  },
  computed: {
    initials(): string {
      return getInitials(this.attendee.name);
    },
    isMenuOpenModel: {
      get(): boolean {
        // keep the menu open as long as the attendee profile is loading. This way we can show a loading indicator.
        // do not show the menu if this card is not active (i.e. not rendered by the dynamic scroller).
        return (
          this.active &&
          (this.isMenuOpen || (this.isLoadingAttendeeProfile && this.attendeeIdToLoad === this.attendee.uid))
        );
      },
      set(value: boolean) {
        this.isMenuOpen = value;
        this.$emit("menu-toggled", value);
      }
    }
  },
  methods: {
    openProfileForAttendee(attendee: AttendeeRef): void {
      this.$emit("open-profile-for-attendee", attendee);
    },
    startChat(attendee: AttendeeRef): void {
      this.$emit("start-chat", attendee);
    }
  }
});
