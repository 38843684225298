
import { defineComponent, PropType } from "vue";
import { AttendeeRef } from "@/model/attendee-ref";
import AttendeeSummary from "@/ui/components/presence-widget/AttendeeSummary.vue";

export default defineComponent({
  components: { AttendeeSummary },
  emits: ["open-profile-for-attendee", "start-chat", "close"],
  props: {
    attendee: {
      type: Object as PropType<AttendeeRef>,
      required: true
    },
    isLoadingAttendeeProfile: Boolean
  },
  methods: {
    openAttendeeProfile(): void {
      this.close();
      this.$emit("open-profile-for-attendee", this.attendee);
    },
    startChat(): void {
      this.close();
      this.$emit("start-chat", this.attendee);
    },
    close(): void {
      this.$emit("close");
    }
  }
});
