import { Store } from "vuex";

/**
 * Used to reduce the types for allowed keys based on the desired module type.
 */
export type ValidKey<State, Root> = {
  [K in keyof Root]: Root[K] extends State ? K : never;
}[keyof Root];

/**
 * Base class for all "store services".
 *
 * These "store services" basically provide a nicer wrapper around a specific module (e.g. "@/store/modules/login").
 * On a generic level, these services expose only the state and a properly namespaced dispatch function. There's no way
 * to do other things (e.g. commit mutations). Actual service classes derived from this base class may or may not
 * provide business-level methods to perform use-case-specific actions (i.e. a more use-case-related API for the rest of
 * the application).
 *
 * Note that a "service" here in the vuex store context is NOT the same as a "service" in the API context (e.g.
 * UserService).
 */
export default abstract class AbstractStoreService<State, Root, Getters> {
  constructor(protected store: Store<Root>, protected namespace: ValidKey<State, Root>) {}

  /**
   * Returns this module's state object.
   */
  get state(): State {
    return (this.store.state as never)[this.namespace];
  }

  /**
   * Dispatches the given action belonging to the appropriate module.
   *
   * @param action Name of the action to dispatch.
   * @param arg Argument to forward to vuex's dispatch function.
   */
  dispatch(action: string, arg?: unknown): Promise<void> {
    return this.store.dispatch(this.prefix(action), arg) as Promise<void>;
  }

  /**
   * Commits the given mutation belonging to the appropriate module.
   *
   * @param mutation Name of the mutation to commit.
   * @param payload Payload to forward to vuex's commit function.
   */
  commit(mutation: string, payload?: unknown): void {
    this.store.commit(this.prefix(mutation), payload);
  }

  protected _get<GetterName extends keyof Getters>(name: GetterName): Getters[GetterName] {
    return (this.store.getters as Record<string, Getters[GetterName]>)[this.prefix(name as string)];
  }

  private prefix(thing: string) {
    return `${this.namespace as string}/${thing}`;
  }
}
