import { RootState } from "@/store/RootState";
import { VenueApi } from "@/services/backend-service";
import { Venue } from "@/services/backend/generated/model/venue";
import { VenueState } from "@/store/modules/venue/VenueState";
import { VenueMutation } from "@/store/modules/venue/venueMutations";
import { TypedAction } from "@/store/framework/TypedAction";
import { FrontendCookieProvider } from "@/model/frontend-cookie-provider";

/**
 * Defines all action names in the venue store slice.
 */
export enum VenueAction {
  loadVenue = "loadVenue",
  setVenue = "setVenue",
  setAccepted = "setAccepted",
  setAllExternalMediaAccepted = "setAllExternalMediaAccepted",
  acceptAllProviders = "acceptAllProviders"
}

/**
 * Defines the action types in the venue store slice.
 */
interface VenueActions {
  [VenueAction.loadVenue]: string;
  [VenueAction.setVenue]: Venue;
  [VenueAction.setAccepted]: FrontendCookieProvider;
  [VenueAction.setAllExternalMediaAccepted]: boolean;
  [VenueAction.acceptAllProviders]: undefined;
}

/**
 * Defines types of all action functions in the venue store slice.
 */
type VenueActionFunctions = {
  [name in keyof VenueActions]: TypedAction<VenueState, RootState, void, VenueActions[name]>;
};

/**
 * Action method implementations in the venue store slice.
 */
export const venueActions: VenueActionFunctions = {
  async [VenueAction.loadVenue]({ commit }, architectureId?: string): Promise<void> {
    try {
      const venueResponse = await VenueApi.getVenue(architectureId);
      commit(VenueMutation.SET_VENUE, venueResponse.data as Venue);
    } catch (error) {
      console.log("There was an error when fetching the venue: ", error);
    }
  },

  [VenueAction.setVenue]({ commit }, venue: Venue | undefined): void {
    try {
      commit(VenueMutation.SET_VENUE, venue);
    } catch (error) {
      console.log("There was an error when setting the venue: ", error);
    }
  },

  [VenueAction.setAccepted]({ commit }, provider: FrontendCookieProvider): void {
    try {
      commit(VenueMutation.SET_ACCEPTED, provider);
    } catch (error) {
      console.log("There was an error when setting accepted: ", error);
    }
  },

  [VenueAction.setAllExternalMediaAccepted]({ commit }, value: boolean): void {
    try {
      commit(VenueMutation.SET_ALL_EXTERNAL_MEDIA_ACCEPTED, value);
    } catch (error) {
      console.log("There was an error when setting the accepted value of all external media cookie providers: ", error);
    }
  },

  [VenueAction.acceptAllProviders]({ commit }) {
    try {
      commit(VenueMutation.ACCEPT_ALL_PROVIDERS);
    } catch (error) {
      console.log("There was an error accepting all cookie providers: ", error);
    }
  }
};
