import { venueStoreService } from "@/store/module-services";
import loadVenue from "@/utility/load-venue";

/**
 * Ensures that a venue is loaded.
 *
 * @param {string} architectureId The architecture ID.
 */
export default async function ensureVenue(architectureId?: string): Promise<void> {
  // Ensure that the venue is loaded
  if (!venueStoreService.isLoaded()) {
    await loadVenue(architectureId);
  }
  if (!venueStoreService.isLoaded()) {
    // Venue could not be loaded => routing is stopped
    throw new Error("Cannot load venue");
  }
}
