/* Helper functions dealing with language */

import LocalStorageService from "@/services/local-storage-service";
import { getI18n } from "./getI18n";
const i18n = getI18n();

export interface LanguageName {
  locale: string;
  name: string;
}

export const languageNames: LanguageName[] = [
  { locale: "en", name: "English" },
  { locale: "de", name: "Deutsch" },
  { locale: "fr", name: "Français" },
  { locale: "it", name: "Italiano" },
  { locale: "es", name: "Español" },
  { locale: "ja", name: "日本語" },
  { locale: "ar", name: "العربية" },
  { locale: "bg", name: "български език" },
  { locale: "bn", name: "বাংলা" },
  { locale: "cs", name: "Čeština" },
  { locale: "da", name: "Dansk" },
  { locale: "el", name: "ελληνικά" },
  { locale: "et", name: "Eesti" },
  { locale: "fi", name: "Suomi" },
  { locale: "hi", name: "हिन्दी" },
  { locale: "hr", name: "Hrvatski jezik" },
  { locale: "hu", name: "Magyar" },
  { locale: "lt", name: "Lietuvių kalba" },
  { locale: "lv", name: "Latviešu valoda" },
  { locale: "mt", name: "Malti" },
  { locale: "nl", name: "Nederlands" },
  { locale: "pl", name: "Język polski" },
  { locale: "pt", name: "Português" },
  { locale: "pt-BR", name: "Português brasileiro" },
  { locale: "ro", name: "Română" },
  { locale: "ru", name: "Pусский" },
  { locale: "sk", name: "Slovenčina" },
  { locale: "sl", name: "Slovenščina" },
  { locale: "sv", name: "Svenska" },
  { locale: "ts", name: "Xitsonga" },
  { locale: "ur", name: "اردو" },
  { locale: "zh", name: "中文" }
];

/**
 * Selects and sets the initial language by descending priority:
 * URL lang parameter, local storage, browser preferences, fallback language.
 *
 * @param supportedLangs the supported languages in 2-character format (e.g. "en")
 * @param routeLangQueryParam the value of the route language query parameter (may be empty)
 * @param fallbackLang the fallback language in 2-character format (e.g. "en")
 */
export function initLanguage(supportedLangs: string[], routeLangQueryParam: string, fallbackLang: string): void {
  const routeLang = langCode(routeLangQueryParam);
  const localStorageLang = LocalStorageService.getLang();
  const browserLang = langCode(window.navigator.language);

  if (supportedLangs.includes(routeLang)) {
    setLanguage(routeLang);
  } else if (localStorageLang && supportedLangs.includes(localStorageLang)) {
    setLanguage(localStorageLang);
  } else if (supportedLangs.includes(browserLang)) {
    setLanguage(browserLang, false);
  } else {
    setLanguage(fallbackLang, false);
  }
}

/**
 * Sets the given language as active language in vue-i18n and stores it in the local storage.
 *
 * If the language was not explicitly chosen, do not save it. This means that, if a user changes their browser language,
 * but has not used the language picker on the site, the UI should also change language.
 *
 * @param language The language to set in 2-character format (e.g. "en").
 * @param keepInLocalStorage Whether to persist this language choice across refreshes.
 */
export function setLanguage(language: string, keepInLocalStorage = true): void {
  if (keepInLocalStorage) {
    LocalStorageService.setLang(language);
  }
  // change locale of the i18n plugin, in order to update all localized text snippets
  i18n.global.locale.value = language;
}

/**
 * Get the language code of the given locale.
 *
 * @param locale Locale code.
 * @return Language code of the locale.
 */
function langCode(locale: string): string {
  // if locale is empty or undefined
  if (!locale) {
    return "";
  }

  const numberOfLettersLocaleCountry = 2;
  return locale.substring(0, numberOfLettersLocaleCountry);
}
