/**
 * Can be used to do exhaustive enum checking in switch statements.
 *
 * Example:
 * <pre><code>
 *     switch (foo) {
 *         case Foo.A:
 *             doAStuff();
 *             break;
 *
 *         case Foo.B:
 *             doBStuff();
 *             break;
 *
 *         default: assertNever(foo);
 *     }
 * </code></pre>
 *
 * Will report an error if the enum Foo contains more possible values than just A and B which are handled in the switch.
 */
export function assertNever(x: never): never {
  throw new Error("Unexpected object: " + (x as string));
}
