/**
 * Defines the namespaces for all store modules.
 */
export enum StoreModuleName {
  ui = "ui",
  venue = "venue",
  user = "user",
  cookieBanner = "cookieBanner",
  presence = "presence",
  mediaBoardSocialContent = "mediaBoardSocialContent"
}
