import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx, vShow as _vShow, withDirectives as _withDirectives, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, renderList as _renderList } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_user_summary = _resolveComponent("user-summary")!
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_the_language_selection = _resolveComponent("the-language-selection")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_presence_widget_container = _resolveComponent("presence-widget-container")!
  const _component_v_list_item_title = _resolveComponent("v-list-item-title")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_logout_button = _resolveComponent("logout-button")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_v_list = _resolveComponent("v-list")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.currentUser && _ctx.currentAreaId)
      ? (_openBlock(), _createBlock(_component_user_summary, {
          key: 0,
          "area-id": _ctx.currentAreaId,
          user: _ctx.currentUser,
          nav: "",
          class: "py-0"
        }, null, 8, ["area-id", "user"]))
      : _createCommentVNode("", true),
    _createVNode(_component_v_list, {
      dense: "",
      nav: "",
      class: "py-0"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_v_divider),
        _createVNode(_component_v_list_item, null, {
          default: _withCtx(() => [
            _createVNode(_component_the_language_selection)
          ]),
          _: 1
        }),
        _createVNode(_component_v_divider),
        (_ctx.presenceEnabled)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              _withDirectives(_createVNode(_component_v_list_item, null, {
                default: _withCtx(() => [
                  _createVNode(_component_presence_widget_container, {
                    mobile: "",
                    ready: _ctx.presenceReady,
                    "onUpdate:ready": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.presenceReady) = $event)),
                    "selected-attendee-id": _ctx.selectedAttendeeId,
                    onSelectAttendee: _ctx.selectAttendee
                  }, null, 8, ["ready", "selected-attendee-id", "onSelectAttendee"])
                ]),
                _: 1
              }, 512), [
                [_vShow, _ctx.presenceReady]
              ]),
              (_ctx.presenceReady)
                ? (_openBlock(), _createBlock(_component_v_divider, { key: 0 }))
                : _createCommentVNode("", true)
            ], 64))
          : _createCommentVNode("", true),
        (_ctx.currentUser)
          ? (_openBlock(), _createBlock(_component_v_list_item, {
              key: 1,
              "data-testid": "user-profile-link",
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.openUserProfile()))
            }, {
              prepend: _withCtx(() => [
                _createVNode(_component_v_icon, null, {
                  default: _withCtx(() => [
                    _createTextVNode("mdi-cog")
                  ]),
                  _: 1
                })
              ]),
              default: _withCtx(() => [
                _createVNode(_component_v_list_item_title, null, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t("userProfile.link")), 1)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        (_ctx.isCookieBannerEnabled)
          ? (_openBlock(), _createBlock(_component_v_list_item, {
              key: 2,
              "data-testid": "privacy-preferences-link",
              onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.openCookieBanner()))
            }, {
              prepend: _withCtx(() => [
                _createVNode(_component_v_icon, null, {
                  default: _withCtx(() => [
                    _createTextVNode("mdi-incognito")
                  ]),
                  _: 1
                })
              ]),
              default: _withCtx(() => [
                _createVNode(_component_v_list_item_title, null, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t("privacyPreferences")), 1)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        _createVNode(_component_v_divider),
        (_ctx.showLogoutButton)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 3 }, [
              _createVNode(_component_v_list_item, null, {
                default: _withCtx(() => [
                  _createVNode(_component_logout_button, { onClick: _ctx.logOut }, null, 8, ["onClick"])
                ]),
                _: 1
              }),
              _createVNode(_component_v_divider)
            ], 64))
          : _createCommentVNode("", true),
        (_ctx.architectureId)
          ? (_openBlock(true), _createElementBlock(_Fragment, { key: 4 }, _renderList(_ctx.MandatoryPagesEnum, (value) => {
              return (_openBlock(), _createBlock(_component_v_list_item, { key: value }, {
                default: _withCtx(() => [
                  _createVNode(_component_router_link, {
                    to: {
              name: value,
              params: { architectureId: _ctx.architectureId }
            }
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t(value)), 1)
                    ]),
                    _: 2
                  }, 1032, ["to"])
                ]),
                _: 2
              }, 1024))
            }), 128))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ]))
}