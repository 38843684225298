
import { defineComponent } from "vue";
import { cookieBannerStoreService, venueStoreService } from "@/store/module-services";
import { CookieBannerType } from "@/services/backend/generated/model/cookie-banner-type";
import { MandatoryPagesEnum } from "@/model/mandatory-pages";
import { Style } from "@/services/backend/generated/model/style";

export default defineComponent({
  data() {
    return {
      CookieBannerType,
      MandatoryPagesEnum
    };
  },
  computed: {
    isMobile(): boolean {
      return this.$vuetify.display.smAndDown;
    },

    architectureId(): string | undefined {
      return venueStoreService.getArchitectureId();
    },

    showFooter(): boolean {
      return venueStoreService.isLoaded();
    },

    cookieBannerType(): CookieBannerType {
      return venueStoreService.getCookieBannerConfig()?.type ?? CookieBannerType.NONE;
    },

    style(): Style {
      return venueStoreService.getStyle();
    },

    appliedStyle(): { color: string } {
      return {
        color: this.style.text
      };
    }
  },
  methods: {
    async openCookieBanner(): Promise<void> {
      await cookieBannerStoreService.setVisible(true);
    }
  }
});
