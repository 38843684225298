/**
 *  Activates the Vuex store and defines all the state
 */

import { createStore } from "vuex";
import { storeOptions } from "@/store/storeOptions";
import { RootState } from "@/store/RootState";

// Create store instance
const store = createStore<RootState>({ ...storeOptions });

export default store;
