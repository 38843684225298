import { Asset } from "@/services/backend/generated/model/asset";
import { ImageSizes } from "@/model/image-sizes";
import { venueStoreService } from "@/store/module-services";
import axios, { AxiosRequestConfig } from "axios";
import { UI_ENDPOINTS_PATH, VenueApi } from "@/services/backend-service";
import { addAuthToken } from "@/services/axios/addAuthToken";
import { SignedUrl } from "./backend/generated/model/signed-url";

/**
 * Information about an asset that has been fetched.
 */
interface AssetInfo {
  assetId: string;
  generation: number;
}

/**
 * A service to retrieve assets with authorization and create object URLs from the asset data.
 */
export default class FetchAssetService {
  // Identifies generations of object URLs. If old generations of objects survive, then
  // this is a hint, that we have a memory leak or should make these objects cacheable.
  private static currentGeneration = 0;

  // Map<objectUrl, assetInfo>: stores all object URLs and information about their
  // corresponding source asset to identify the asset associated with an object URL.
  // Note: The map uses object URLs as keys and asset infos as values, because we
  // want fast insertion/retrieval/deletion of object URLs.
  private static readonly knownObjectUrls = new Map<string, AssetInfo>();

  // Map<assetId, objectUrl>: stores the object URLs of cacheable assets, which are
  // reused and automatically released, when the 'window.document' reference changes.
  // Note: The map uses asset IDs as keys and object URLs as values, because we
  // want fast retrieval of an existing object URL for a given asset ID.
  // Also note that the cache contains *Promises* for the object URLs! That way, the
  // first requester for a cached assets performs the actual load and all parallel
  // requesters get the same blob URL as soon as it is resolved.
  private static readonly assetCache = new Map<string, Promise<string> | undefined>();

  /**
   * Returns the object URL of the given asset.
   *
   * Each object URL retrieved with this function, must be released with `releaseAssetUrl`!
   *
   * @param {string|undefined} assetId The asset ID.
   * @param {ImageSizes|undefined} size The image size (optional).
   * @return {string} The object URL.
   */
  static async getAssetUrl(assetId: string | undefined, size?: ImageSizes): Promise<string> {
    if (!assetId) {
      return "";
    }

    // if the asset ID refers to an already loaded cacheable asset, then reuse the cached object URL
    let objectUrlPromise = this.assetCache.get(assetId);
    if (this.isCacheable(assetId, size) && objectUrlPromise !== undefined) {
      return objectUrlPromise;
    }

    // load the asset and create a new object URL
    objectUrlPromise = loadAsset(assetId, size);
    if (this.isCacheable(assetId, size)) {
      this.assetCache.set(assetId, objectUrlPromise);
    }

    // remember the allocated object URL
    const objectUrl: string = await objectUrlPromise;
    this.knownObjectUrls.set(objectUrl, {
      assetId,
      generation: this.currentGeneration
    });

    return objectUrlPromise;
  }

  /**
   * Releases the given object URL.
   *
   * @param {string|undefined} objectUrl The object URL.
   */
  static async releaseAssetUrl(objectUrl: string | undefined): Promise<void> {
    if (!objectUrl || !objectUrl.startsWith("blob:")) {
      return;
    }

    if (!(await this.isCachedAsset(objectUrl))) {
      this.knownObjectUrls.delete(objectUrl);
      URL.revokeObjectURL(objectUrl);
    }
  }

  /**
   * Registers the assets with the given IDs as cacheable.
   *
   * The first time, a cacheable asset is requested in its default size with `getAssetUrl`,
   * its object URL will be placed in an internal cache. All further requests for the
   * asset will be served from the cache.
   *
   * @param {string[]} assetIds The asset IDs.
   * @param {boolean} replace If `true`, then all currently registered cacheable assets, which
   *                          are not contained in the given list of asset IDs, will be released.
   */
  static async registerCacheableAssets(assetIds: string[], replace = false): Promise<void> {
    // ensure that all asset IDs exist in the asset cache
    assetIds.forEach((assetId) => {
      if (!this.assetCache.has(assetId)) {
        // the default value will be replaced with the real object URL
        // of the asset, when the asset is first loaded.
        this.assetCache.set(assetId, undefined);
      }
    });

    // if replace is enabled, then delete all other previously cached assets
    // from the asset cache and release their associated resources
    if (replace) {
      const assetIdsToDelete = new Set(this.assetCache.keys());
      assetIds.forEach((assetId) => assetIdsToDelete.delete(assetId));
      await this.removeFromCache(assetIdsToDelete);
    }
  }

  /**
   * Starts a new generation of assets to be fetched and logs all non-cacheable
   * assets from older generations to the given function.
   */
  static async startNextGeneration(): Promise<void> {
    // identify possible memory leaks:
    // currentGeneration + 1 => future assets.
    // currentGeneration     => current assets.
    // currentGeneration - 1 => possible memory leaks!
    await this.printKnownAssetsStatistic(this.currentGeneration - 1, console.debug);

    // the future starts now!
    this.currentGeneration += 1;
  }

  /**
   * Logs a statistic of the known assets to the given function.
   *
   * @param {number} generationLimit The generation limit below which to consider known assets as possible memory leaks.
   * @param logger E.g., `console.debug`.
   */
  private static async printKnownAssetsStatistic(
    generationLimit: number,
    logger: (message?: unknown, ...params: unknown[]) => void
  ) {
    let countCacheables = 0;
    let countNonCacheablesYoung = 0;
    let countNonCacheablesOld = 0;
    let logNonCacheablesOld = "";

    for (const [objectUrl, assetInfo] of this.knownObjectUrls) {
      if (await this.isCachedAsset(objectUrl)) {
        // case 1: it's a cached object
        countCacheables += 1;
      } else if (assetInfo.generation > generationLimit) {
        // case 2: it's a new, non-cached object
        countNonCacheablesYoung += 1;
      } else {
        // case 3: it's an old, non-cached object
        // => possible memory leak or a candidate for caching
        countNonCacheablesOld += 1;
        logNonCacheablesOld += `${objectUrl} => ${assetInfo.assetId} (generation: ${assetInfo.generation})\n`;
      }
    }

    logger(
      `-> current generation: ${this.currentGeneration}\n` +
        `-> cacheable assets in memory: ${countCacheables}\n` +
        `-> non-cacheable assets in memory (young): ${countNonCacheablesYoung}\n` +
        `-> non-cacheable assets in memory (old): ${countNonCacheablesOld}\n${logNonCacheablesOld}`
    );
  }

  /**
   * Checks whether the given asset ID and size refers to a cacheable asset in its default size.
   *
   * @param {string} assetId The asset ID.
   * @param {ImageSizes|undefined} size The image size.
   * @private
   */
  private static isCacheable(assetId: string, size?: ImageSizes): boolean {
    return this.assetCache.has(assetId) && size === undefined;
  }

  /**
   * Checks whether the given object URL refers to a cached asset.
   *
   * @param {string} objectUrl The object URL.
   * @private
   */
  private static async isCachedAsset(objectUrl: string): Promise<boolean> {
    const assetInfo = this.knownObjectUrls.get(objectUrl);
    if (assetInfo === undefined) {
      return false;
    }

    // note that the object URL may refer to the asset ID of a cacheable asset,
    // but to another size than the default size; therefore, check that the
    // object URL is exactly the cached object URL!
    const cachedObjectUrl = await this.assetCache.get(assetInfo.assetId);
    return cachedObjectUrl === objectUrl;
  }

  /**
   * Remove all given assets from the asset cache and release their resources.
   *
   * @param assetIds The asset IDs to remove.
   * @private
   */
  private static async removeFromCache(assetIds: Set<string>) {
    for (const assetId of assetIds) {
      const objectUrlPromise = this.assetCache.get(assetId);
      this.assetCache.delete(assetId);

      const objectUrl = await objectUrlPromise;
      if (objectUrl !== undefined) {
        this.knownObjectUrls.delete(objectUrl);
        URL.revokeObjectURL(objectUrl);
      }
    }
  }
}

/**
 * Loads the asset with the given asset ID and returns its object URL.
 *
 * @param {string} assetId The asset ID.
 * @param {ImageSizes|undefined} size The image size.
 * @return {string} The object URL.
 */
async function loadAsset(assetId: string, size?: ImageSizes): Promise<string> {
  // resolve asset ID to actual asset
  const asset = venueStoreService.getAssetById(assetId);
  if (!asset) {
    console.debug(`No asset exists with ID '${assetId}'.`);
    return "";
  }

  // If a URI is provided, and it is an absolute link, then it is an external resource to an image,
  // e.g. in firebase. Otherwise it is a relative path within the server.
  let assetUrl: string;
  if (asset.uri.startsWith("/backend-assets/live/") || asset.uri.startsWith("/backend-assets/preview/")) {
    assetUrl = await getSignedUrl(asset, size);
  } else {
    assetUrl = getRelativeAssetUrl(asset, size);
  }

  // fetch the asset
  const blob = await fetchAsset(assetUrl);
  return URL.createObjectURL(blob);
}

export async function getSignedUrl(asset: Asset, size?: ImageSizes): Promise<string> {
  const url = size ? addFilenameSuffix(asset.uri, size) : asset.uri;
  const response = await VenueApi.getSignedAssetUrl(window.location.origin + encodeURI(url));

  return (response.data as SignedUrl).signedUrl || "";
}

function getRelativeAssetUrl(asset: Asset, size?: ImageSizes): string {
  const architectureId = encodeURIComponent(venueStoreService.getArchitectureId() ?? "");
  const assetId = encodeURIComponent(asset.id);

  let url = `${UI_ENDPOINTS_PATH}/architecture/${architectureId}/assets/${assetId}?generation=${asset.generation}`;
  if (size) {
    url += `&suffix=${encodeURIComponent(size)}`;
  }
  return url;
}

/**
 * Fetches the given asset URL with Axios and returns the asset data as a blob.
 *
 * @param {string} assetUrl The asset URL.
 * @return {Blob} The asset blob.
 */
async function fetchAsset(assetUrl: string): Promise<Blob> {
  const config: AxiosRequestConfig = await addAuthToken({
    responseType: "blob"
  });

  const axiosResponse = await axios.get(assetUrl, config);
  return axiosResponse.data as Blob;
}

// IMPORTANT: This logic correlates to vvenue-services (VenueUiController) and magnid-cloud-function (ResizeFunction)
function addFilenameSuffix(uri: string, suffix: string) {
  const lastPathSepIndex = uri.lastIndexOf("/");

  // only search "." in the last path/folder of uri
  if (lastPathSepIndex > 0) {
    const fileName = uri.substring(lastPathSepIndex);
    const fileLastDotIndex = fileName.lastIndexOf(".");
    const fileNameSuffixed = fileName.substring(0, fileLastDotIndex) + suffix + fileName.substring(fileLastDotIndex);
    return uri.substring(0, lastPathSepIndex) + fileNameSuffixed;
  }

  // file to suffix is on root
  const lastDotIndex = uri.lastIndexOf(".");
  return uri.substring(0, lastDotIndex) + suffix + uri.substring(lastDotIndex);
}
