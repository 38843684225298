import { RootState } from "@/store/RootState";
import { VvenueOpMode } from "@/services/backend/generated/model/vvenue-op-mode";
import { LoginConfiguration } from "@/services/backend/generated/model/login-configuration";
import { ErrorState, PublishState, UIConfigState, UiState } from "@/store/modules/ui/UiState";
import { TypedGetterFunctions } from "@/store/framework/TypedGetterFunctions";

/**
 * Defines all properties on the getters object that can be read from when working with the ui store slice.
 */
export interface UiGetters {
  readonly getAppVersion: string;
  readonly getDefaultLocale: string | undefined;
  readonly getHeaderHeight: number;
  readonly getErrorState: ErrorState;
  readonly getLastAddedActiveDialog: Record<string, unknown> | undefined;
  readonly getLastAreaId: string | undefined;
  readonly getLoginConfigurations: LoginConfiguration[];
  readonly getOpMode: VvenueOpMode | undefined;
  readonly getPublishedArchitectureId: string | undefined;
  readonly getRoomChats: Record<string, string[]>;
  readonly getServerTime: Date;
  readonly getSupportedLocales: string[] | undefined;
  readonly getUiConfig: UIConfigState | undefined;
  readonly isChatOpen: boolean;
  readonly isChatExpanded: boolean;
  readonly isDefaultErrorDialogSuppressed: boolean;
  readonly isDialogActive: (dialog: Record<string, unknown>) => boolean;
  readonly isLoginEnabled: boolean | undefined;
  readonly isPublished: PublishState;
  readonly getTabTitle: string | undefined;
  readonly getFavicon: string | undefined;
  readonly hasNavigationGuards: boolean;
  readonly isTryingToNavigate: boolean;
  readonly getAttendeeToChatUid: string | undefined;
  readonly isRouterGuardCheckingNow: boolean;
}

/**
 * Getter method implementations in the ui store slice.
 */
export const uiGetters: TypedGetterFunctions<UiState, RootState, UiGetters> = {
  getErrorState: (state): ErrorState => {
    return state.error;
  },

  getAppVersion: (state): string => {
    return state.appVersion;
  },

  isDefaultErrorDialogSuppressed: (state): boolean => {
    return state.error.suppressDefaultErrorDialog;
  },

  getUiConfig: (state): UIConfigState | undefined => {
    return state.uiConfig;
  },

  getDefaultLocale: (state): string | undefined => {
    return state.uiConfig?.i18n?.defaultLocale;
  },

  getHeaderHeight: (state): number => {
    return state.headerHeight;
  },

  getSupportedLocales: (state): string[] | undefined => {
    return state.uiConfig?.i18n?.supportedLocales;
  },

  getOpMode: (state): VvenueOpMode | undefined => {
    return state.venueVersion?.mode;
  },

  getPublishedArchitectureId: (state): string | undefined => {
    return state.venueVersion?.publishedArchitectureId;
  },

  /**
   * Checks whether the current environment is published, i.e., visible to an authorized user.
   *
   * @param state The current state of the store.
   * @return The publish state.
   */
  isPublished: (state): PublishState => {
    // the venue version may not yet have been loaded from the backend
    if (state.venueVersion === undefined) {
      return PublishState.UNKNOWN;
    }

    // the stage environment is always "published", i.e., visible to the authorized user;
    // the live environment must be actively published from within the designer app
    const venueVersion = state.venueVersion;
    if (venueVersion.mode === VvenueOpMode.LIVE && venueVersion.publishedVersion === -1) {
      return PublishState.UNPUBLISHED;
    }

    return PublishState.PUBLISHED;
  },

  isLoginEnabled: (state): boolean | undefined => {
    return state.loginEnabled;
  },

  getLoginConfigurations: (state): LoginConfiguration[] => {
    return state.loginConfigurations ?? [];
  },

  getLastAreaId: (state): string | undefined => {
    return state.lastAreaId;
  },

  getServerTime: (state): Date => {
    return state.serverTime;
  },

  isDialogActive:
    (state) =>
    (dialog: Record<string, unknown>): boolean => {
      return state.activeDialogStack.includes(dialog);
    },

  getLastAddedActiveDialog: (state): Record<string, unknown> | undefined => {
    return state.activeDialogStack[state.activeDialogStack.length - 1];
  },

  isChatOpen: (state): boolean => {
    return state.chat.opened;
  },

  isChatExpanded: (state): boolean => {
    return state.chat.expanded;
  },

  getRoomChats: (state): Record<string, string[]> => {
    return state.chat.roomChats;
  },

  getTabTitle: (state, getters: UiGetters): string | undefined => getters.getUiConfig?.tabTitle,

  getFavicon: (state, getters: UiGetters): string | undefined => getters.getUiConfig?.favicon,

  hasNavigationGuards: (state): boolean => state.widgetsWithNavigationGuards.length > 0,

  isTryingToNavigate: (state): boolean => state.wantToNavigate,

  getAttendeeToChatUid: (state): string | undefined => state.chat.attendeeToChatUid,

  isRouterGuardCheckingNow: (state): boolean => state.routerGuardsChecking
};
