import { Module } from "vuex";
import { RootState } from "@/store/RootState";
import { NoProfile, ProfileStateType, UserState } from "@/store/modules/user/UserState";
import { userMutations } from "@/store/modules/user/userMutations";
import { userActions } from "@/store/modules/user/userActions";
import { userGetters } from "@/store/modules/user/userGetters";

function initialUserState() {
  return {
    isLoggedIn: false,
    user: undefined,
    authToken: undefined,
    trackingConsentGiven: undefined,
    localStorageCookieProviders: [],
    attendeeProfileToShow: undefined,
    profileState: { type: ProfileStateType.NoProfile } as NoProfile
  };
}

const userModule: Module<UserState, RootState> = {
  namespaced: true,
  state: initialUserState(),
  mutations: userMutations,
  actions: userActions,
  getters: userGetters
};

export default userModule;
