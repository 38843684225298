
import { defineComponent } from "vue";

export default defineComponent({
  computed: {
    visible() {
      return this.$vuetify.display.smAndDown && this.$route.name !== "AreaWherebyMeeting";
    },
    iconSize() {
      switch (this.$vuetify.display.name) {
        case "xs":
          return 80;
        case "sm":
          return 120;
        default:
          return 0;
      }
    }
  }
});
