import { RootState } from "@/store/RootState";
import LocalStorageService, { CookiesLevel } from "@/services/local-storage-service";
import { ProfileStateType, UserState } from "@/store/modules/user/UserState";
import { UserMutation } from "@/store/modules/user/userMutations";
import { TypedAction } from "@/store/framework/TypedAction";
import { LocalStorageCookieProvider } from "@/model/local-storage-cookie-provider";
import { UserGetters } from "@/store/modules/user/userGetters";
import { UserInfo } from "@/model/user-info";
import { UserProfileApi } from "@/services/backend-service";
import { TypeImprovedAttendeeProfile } from "@/model/type-improved-attendee-profile";

/**
 * Defines all action names in the user store slice.
 */
export enum UserAction {
  setLoggedInAs = "setLoggedInAs",
  setLoggedOut = "setLoggedOut",
  setAuthToken = "setAuthToken",
  setTrackingConsentGiven = "setTrackingConsentGiven",
  setLocalStorageCookieProvider = "setLocalStorageCookieProvider",
  setUser = "setUser",
  updateUser = "updateUser",
  openProfileForCurrentUser = "openProfileForCurrentUser",
  openProfileForAttendee = "openProfileForAttendee",
  closeProfile = "closeProfile"
}

/**
 * Defines the action types in the user store slice.
 */
interface UserActions {
  [UserAction.setLoggedInAs]: UserInfo;
  [UserAction.setLoggedOut]: void;
  [UserAction.setAuthToken]: string | undefined;
  [UserAction.setTrackingConsentGiven]: boolean;
  [UserAction.setLocalStorageCookieProvider]: LocalStorageCookieProvider;
  [UserAction.setUser]: UserInfo;
  [UserAction.updateUser]: { architectureId: string; userInfo: UserInfo };
  [UserAction.openProfileForCurrentUser]: void;
  [UserAction.openProfileForAttendee]: {
    architectureId: string;
    userId: string;
  };
  [UserAction.closeProfile]: void;
}

/**
 * Defines types of all action functions in the user store slice.
 */
type UserActionFunctions = {
  [name in keyof UserActions]: TypedAction<UserState, RootState, UserGetters, UserActions[name]>;
};

/**
 * Action method implementations in the user store slice.
 */
export const userActions: UserActionFunctions = {
  [UserAction.setLoggedInAs]({ commit }, userInfo: UserInfo): void {
    try {
      commit(UserMutation.SET_LOGGED_IN);
      commit(UserMutation.SET_USER, userInfo);
    } catch (error) {
      console.error("There was an error while saving the login: ", error);
    }
  },

  [UserAction.setLoggedOut]({ commit }): void {
    try {
      commit(UserMutation.SET_LOGGED_OUT);
      commit(UserMutation.SET_USER, undefined);
    } catch (error) {
      console.error("There was an error while saving the logout: ", error);
    }
  },

  [UserAction.setAuthToken]({ commit }, authToken: string | undefined): void {
    try {
      commit(UserMutation.SET_AUTH_TOKEN, authToken);
    } catch (error) {
      console.error("There was an error while saving the auth token: ", error);
    }
  },

  [UserAction.setTrackingConsentGiven]({ commit }, consent: boolean): void {
    try {
      // the tracking consent is stored in the store as well as in the local storage,
      // therefore we set both in this action
      commit(UserMutation.SET_TRACKING_CONSENT_GIVEN, consent);
      LocalStorageService.setCookiesPermission(CookiesLevel.TRACKING, consent);
    } catch (error) {
      console.error("There was an error while setting the given tracking consent: ", consent);
    }
  },

  [UserAction.setLocalStorageCookieProvider]({ commit }, provider: LocalStorageCookieProvider): void {
    try {
      // the cookie provider permission is stored in the store as well as in the local storage,
      // therefore we set both in this action
      commit(UserMutation.SET_LOCAL_STORAGE_COOKIE_PROVIDER, provider);
      LocalStorageService.setCookiesPermission(provider.id, provider.accepted);
    } catch (error) {
      console.error("There was an error while setting a local storage cookie provider: ", provider);
    }
  },

  [UserAction.setUser]({ commit }, userInfo: UserInfo): void {
    try {
      commit(UserMutation.SET_USER, userInfo);
    } catch (error) {
      console.error("There was an error while setting the user: ", error);
    }
  },

  async [UserAction.updateUser](
    { commit },
    architectureUserInfo: { architectureId: string; userInfo: UserInfo }
  ): Promise<void> {
    try {
      await UserProfileApi.setProfile(architectureUserInfo.architectureId, architectureUserInfo.userInfo);
      commit(UserMutation.SET_USER, architectureUserInfo.userInfo);
    } catch (error) {
      console.error("There was an error while updating the user: ", error);
    }
  },

  [UserAction.openProfileForCurrentUser]({ commit }): void {
    try {
      commit(UserMutation.SET_PROFILE_STATE, {
        type: ProfileStateType.ShowOwnProfile
      });
    } catch (error) {
      console.error("There was an error while opening user profile: ", error);
    }
  },

  async [UserAction.openProfileForAttendee]({ commit }, { architectureId, userId }): Promise<void> {
    try {
      commit(UserMutation.SET_PROFILE_STATE, {
        type: ProfileStateType.LoadingAttendeeProfile,
        userId
      });
      const profile = (await UserProfileApi.getProfile(architectureId, userId)).data as TypeImprovedAttendeeProfile;
      commit(UserMutation.SET_PROFILE_STATE, {
        type: ProfileStateType.ShowAttendeeProfile,
        profile
      });
    } catch (error) {
      console.error("There was an error while opening attendee profile: ", error);
      commit(UserMutation.SET_PROFILE_STATE, {
        type: ProfileStateType.NoProfile
      });
    }
  },

  [UserAction.closeProfile]({ commit }): void {
    try {
      commit(UserMutation.SET_PROFILE_STATE, {
        type: ProfileStateType.NoProfile
      });
    } catch (error) {
      console.error("There was an error while closing profile: ", error);
    }
  }
};
