
import { defineComponent, PropType } from "vue";
import { UserInfo } from "@/model/user-info";
import getInitials from "@/utility/get-initials";
import UserContextMenuContent from "@/ui/components/user-preview/UserContextMenuContent.vue";
import BaseContextMenu from "@/ui/base/BaseContextMenu.vue";

export default defineComponent({
  components: { BaseContextMenu, UserContextMenuContent },
  props: {
    user: {
      type: Object as PropType<UserInfo>,
      required: true
    },
    areaId: {
      type: String,
      required: true
    },
    cookieBannerEnabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isMenuOpen: false
    };
  },
  computed: {
    initials(): string {
      return getInitials(this.user.attributes?.name);
    }
  }
});
