
import { defineComponent, PropType, StyleValue } from "vue";
import { Style } from "@/services/backend/generated/model/style";
import BaseImage from "@/ui/base/BaseImage.vue";
import { SourceImageSize } from "@/model/source-image-size";

export default defineComponent({
  components: { BaseImage },
  props: {
    architectureStyle: Object as PropType<Style>
  },
  data() {
    return {
      aspectRatio: 1
    };
  },
  computed: {
    logoAssetId(): string | undefined {
      return this.architectureStyle?.logoAssetId;
    },
    iconStyle(): StyleValue {
      return {
        aspectRatio: this.aspectRatio,
        position: "relative"
      };
    }
  },
  methods: {
    applyResizing(imageSize: SourceImageSize): void {
      this.aspectRatio = imageSize.aspectRatio;
    }
  }
});
