import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_header = _resolveComponent("app-header")!
  const _component_app_main = _resolveComponent("app-main")!
  const _component_app_footer = _resolveComponent("app-footer")!
  const _component_v_app = _resolveComponent("v-app")!

  return (_openBlock(), _createBlock(_component_v_app, { style: {"width":"100vw","height":"100vh"} }, {
    default: _withCtx(() => [
      (_ctx.needShowHeader)
        ? (_openBlock(), _createBlock(_component_app_header, { key: 0 }))
        : _createCommentVNode("", true),
      _createVNode(_component_app_main, { "show-background-image": _ctx.isLoginView }, null, 8, ["show-background-image"]),
      (_ctx.needShowFooter)
        ? (_openBlock(), _createBlock(_component_app_footer, { key: 1 }))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}