import { Style } from "@/services/backend/generated/model/style";
import colors from "@/utility/colors";
import { lightenColor } from "./utility/lighten-color";

const MAGNID_NIGHT_BLUE = "#000A33";
const MAGNID_GLOWING_PINK = "#F70041";
const MAGNID_NIGHT_GREY = "#373F60";

export const defaultDarkTheme = {
  // region Vuetify default theme keys
  primary: MAGNID_GLOWING_PINK,
  secondary: MAGNID_NIGHT_GREY,
  accent: colors.blue.accent1,
  error: colors.red.lighten3,
  info: colors.blue.base,
  success: colors.green.lighten1,
  warning: colors.amber.base,
  surface: MAGNID_NIGHT_BLUE,
  // endregion

  // region VVenue custom theme keys
  text: colors.shades.white,
  background: MAGNID_NIGHT_BLUE,
  background_lighten_2: lightenColor(MAGNID_NIGHT_BLUE, 10),
  background_mandatory_pages: colors.shades.white,
  font_mandatory_pages: "#5b677d",
  media_library_asset_background: colors.grey.lighten1,
  media_library_default_download_button: colors.blueGrey.base
  // endregion
};

export const defaultLightTheme = {
  // region Vuetify default theme keys
  primary: MAGNID_GLOWING_PINK,
  secondary: MAGNID_NIGHT_GREY
};

export const defaultDarkStyle: Style = {
  ...defaultDarkTheme
};
