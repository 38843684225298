import { Architecture } from "@/services/backend/generated/model/architecture";
import { Area } from "@/services/backend/generated/model/area";
import { ViewPoint } from "@/services/backend/generated/model/view-point";
import { Asset } from "@/services/backend/generated/model/asset";
import { Icon } from "@/services/backend/generated/model/icon";
import { GlobalWidget } from "@/services/backend/generated/model/global-widget";
import { LocalizedText } from "@/services/backend/generated/model/localized-text";
import { Floorplan } from "@/services/backend/generated/model/floorplan";
import { RootState } from "@/store/RootState";
import { CventEvent } from "@/services/backend/generated/model/cvent-event";
import { VenueState } from "@/store/modules/venue/VenueState";
import { TypedGetterFunctions } from "@/store/framework/TypedGetterFunctions";
import { CookieBannerConfig } from "@/services/backend/generated/model/cookie-banner-config";
import { FrontendCookieProvider } from "@/model/frontend-cookie-provider";
import { CookieProviderType } from "@/services/backend/generated/model/cookie-provider-type";
import { ScheduledContentInstance } from "@/services/content-time-filter-service";
import { Style } from "@/services/backend/generated/model/style";
import { UserProfileAttribute } from "@/services/backend/generated/model/user-profile-attribute";
import { CookieBannerType } from "@/services/backend/generated/model/cookie-banner-type";
import { BackgroundVideo } from "@/services/backend/generated/model/background-video";
import { DEFAULT_DACAST_VIDEO_COOKIE_PROVIDER, DEFAULT_VIDEO_COOKIE_PROVIDER } from "@/model/video-constants";
import { isDaCastUrl } from "@/utility/dacast";
import { ViewMode } from "@/services/backend/generated/model/view-mode";

/**
 * Defines all properties on the getters object that can be read from when working with the venue store slice.
 */
export interface VenueGetters {
  getArchitecture: Architecture | undefined;
  getArchitectureId: string | undefined;
  getDefaultArea: Area | undefined;
  getDefaultViewPointByAreaId(id: string): ViewPoint | undefined;
  getAreaById(id: string): Area | undefined;
  getAssetById(id: string): Asset | undefined;
  getIconByType(type: string): Icon | undefined;
  getIcons: Icon[];
  getGlobalWidgets: GlobalWidget[] | undefined;
  getImprint: LocalizedText[] | undefined;
  getPrivacyNotice: LocalizedText[] | undefined;
  getFloorplan: Floorplan | undefined;
  getCventLoginEnabled: boolean | undefined;
  getCventEvent: CventEvent | undefined;
  isLoaded: boolean;
  getPublishedVersion: number;
  getCookieBannerConfig: CookieBannerConfig | undefined;
  isCookieBannerEnabled: boolean;
  isPersonalizedTrackingEnabled: boolean;
  getCookieProviders: FrontendCookieProvider[];
  getVideoBackgroundCookieIds: string[];
  isAllExternalMediaAccepted: boolean;
  getNotAcceptedCookies: string[];
  getStyle: Style | undefined;
  getUserProfileAttributes: Array<UserProfileAttribute> | undefined;
}

/**
 * Getter method implementations in the venue store slice.
 */
export const venueGetters: TypedGetterFunctions<VenueState, RootState, VenueGetters> = {
  isLoaded: (state): boolean => !!state.venue && !!state.selectedArchitectureId,

  getArchitecture: (state): Architecture | undefined =>
    state.venue?.architectures.find((architecture) => architecture.id === state.selectedArchitectureId),

  getArchitectureId: (state, getters): string | undefined => getters.getArchitecture?.id,

  getDefaultArea: (state, getters: VenueGetters): Area | undefined => {
    let defaultArea;
    const defaultAreaId = getters.getArchitecture?.defaultAreaId;
    if (defaultAreaId) {
      defaultArea = getters.getAreaById(defaultAreaId);
    }
    // if default area is not set/found, use fallback
    return defaultArea || getters.getArchitecture?.areas[0];
  },

  getDefaultViewPointByAreaId:
    (state, getters: VenueGetters) =>
    (id: string): ViewPoint | undefined => {
      let defaultViewPoint;
      const area = getters.getAreaById(id);
      const defaultViewPointId = area?.defaultViewpointId;
      if (area && defaultViewPointId) {
        defaultViewPoint = area.viewPoints.find((viewPoint) => defaultViewPointId === viewPoint.id);
      }
      // if default view point is not set/found, use fallback
      return defaultViewPoint || area?.viewPoints[0];
    },

  getAreaById:
    (state, getters: VenueGetters) =>
    (id: string): Area | undefined => {
      return getters.getArchitecture?.areas.find((area) => id === area.id);
    },

  getAssetById:
    (state) =>
    (id: string): Asset | undefined => {
      return state?.venue?.assets.find((asset) => id === asset.id);
    },

  getIconByType:
    (state, getters: VenueGetters) =>
    (type: string): Icon | undefined => {
      return getters.getArchitecture?.icons.find((icon) => type === icon.type);
    },

  getIcons: (state: VenueState, getters: VenueGetters): Icon[] => getters.getArchitecture?.icons ?? [],

  getGlobalWidgets: (state, getters: VenueGetters): GlobalWidget[] | undefined =>
    getters.getArchitecture?.globalWidgets,

  getImprint: (state: VenueState, getters: VenueGetters): LocalizedText[] | undefined =>
    getters.getArchitecture?.impress,

  getPrivacyNotice: (state: VenueState, getters: VenueGetters): LocalizedText[] | undefined =>
    getters.getArchitecture?.privacyNotice,

  getFloorplan: (state: VenueState, getters: VenueGetters): Floorplan | undefined => getters.getArchitecture?.floorplan,

  getCventLoginEnabled: (state: VenueState, getters: VenueGetters): boolean | undefined =>
    getters.getArchitecture?.cventLoginEnabled,

  getCventEvent: (state: VenueState, getters: VenueGetters): CventEvent | undefined =>
    getters.getArchitecture?.cventEvent,

  getPublishedVersion: (state: VenueState): number => {
    const publishedVersion = state?.venue?.publishInfo?.publishedVersion;
    return publishedVersion ?? -1;
  },

  getCookieBannerConfig: (state, getters: VenueGetters): CookieBannerConfig | undefined =>
    getters.getArchitecture?.cookieBannerConfig,

  isCookieBannerEnabled: (state, getters: VenueGetters): boolean => {
    const bannerType = getters.getArchitecture?.cookieBannerConfig.type;
    return !(bannerType === undefined || bannerType === CookieBannerType.NONE);
  },

  isPersonalizedTrackingEnabled: (state, getters: VenueGetters): boolean =>
    getters.getArchitecture?.personalizedTrackingEnabled || false,

  getCookieProviders: (state: VenueState, getters: VenueGetters): FrontendCookieProvider[] => {
    // get the ids of all providers that are in use
    const widgetCookieProviders = (
      (getters.getArchitecture?.areas
        .flatMap((a) => a.contentWidgets)
        .flatMap((w) => w.configurations) as ScheduledContentInstance[]) ?? []
    )
      .filter((c) => c.cookieProviders.length >= 1)
      .flatMap((c) => c.cookieProviders);
    const providersInUse = new Set([...widgetCookieProviders, ...getters.getVideoBackgroundCookieIds]);
    // return all providers that are either technically required or are in use,
    // because these are the ones that are important for the user
    return state.cookieProviders.filter(
      (p) => p.type === CookieProviderType.TECHNICALLY_REQUIRED || providersInUse.has(p.id ?? "")
    );
  },

  isAllExternalMediaAccepted: (state: VenueState, getters: VenueGetters): boolean =>
    !getters.getCookieProviders.some((cp) => cp.type === CookieProviderType.EXTERNAL_MEDIA && !cp.accepted),

  getNotAcceptedCookies: (state, getters: VenueGetters): string[] => {
    return getters.getCookieProviders
      .filter((cp) => !cp.accepted)
      .filter((cp) => cp.id !== undefined)
      .map((cp) => cp.id);
  },

  getStyle: (state: VenueState, getters: VenueGetters): Style | undefined => getters.getArchitecture?.style,

  getUserProfileAttributes: (state: VenueState, getters: VenueGetters): Array<UserProfileAttribute> | undefined =>
    getters.getArchitecture?.userProfileAttributes,

  getVideoBackgroundCookieIds: (state: VenueState, getters: VenueGetters): string[] => {
    return [
      ...new Set(
        getters.getArchitecture?.areas
          .filter((area) => area.viewMode === ViewMode._2D)
          .flatMap((area) => area.viewPoints)
          .filter((vp) => vp.background.type === "BackgroundVideo")
          .map((vp) => vp.background as BackgroundVideo)
          .filter((background) => background.playerActive)
          .filter((background) => background.videoUrl)
          .map((background) => background.videoUrl)
          .map((url) => (isDaCastUrl(url) ? DEFAULT_DACAST_VIDEO_COOKIE_PROVIDER : DEFAULT_VIDEO_COOKIE_PROVIDER)) ?? []
      )
    ];
  }
};
