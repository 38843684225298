import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, mergeProps as _mergeProps, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_btn = _resolveComponent("v-btn")!

  return (_openBlock(), _createBlock(_component_v_btn, _mergeProps({
    color: _ctx.active ? 'primary' : 'text',
    variant: "tonal",
    elevation: "2",
    class: "text-transform-none"
  }, _ctx.$attrs, {
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.active = !_ctx.active))
  }), {
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ]),
    _: 3
  }, 16, ["color"]))
}