import { Module } from "vuex";
import { RootState } from "@/store/RootState";
import { VenueState } from "@/store/modules/venue/VenueState";
import { venueGetters } from "@/store/modules/venue/venueGetters";
import { venueActions } from "@/store/modules/venue/venueActions";
import { venueMutations } from "@/store/modules/venue/venueMutations";

const venueModule: Module<VenueState, RootState> = {
  namespaced: true,
  mutations: venueMutations,
  actions: venueActions,
  getters: venueGetters,
  state: {
    venue: undefined,
    selectedArchitectureId: "",
    cookieProviders: []
  }
};

export default venueModule;
