import theStore from "@/store";
import { StoreModuleName } from "@/store/StoreModuleName";
import { Store } from "vuex";
import { RootState } from "@/store/RootState";
import { UiStoreService } from "@/store/modules/ui/UiStoreService";
import { UserStoreService } from "@/store/modules/user/UserStoreService";
import { VenueStoreService } from "@/store/modules/venue/VenueStoreService";
import { CookieBannerStoreService } from "@/store/modules/cookieBanner/cookieBannerStoreService";
import { PresenceStoreService } from "@/store/modules/presence/PresenceStoreService";
import { MediaBoardSocialContentStoreService } from "@/store/modules/mediaBoardSocialContent/mediaBoardSocialContentStoreService";

/*
 * Convenience factory functions for creating store module service instances.
 */

export function getUiStoreService(storeObject: Store<RootState>): UiStoreService {
  return new UiStoreService(storeObject, StoreModuleName.ui);
}

export function getUserStoreService(storeObject: Store<RootState>): UserStoreService {
  return new UserStoreService(storeObject, StoreModuleName.user);
}

export function getVenueStoreService(storeObject: Store<RootState>): VenueStoreService {
  return new VenueStoreService(storeObject, StoreModuleName.venue);
}

export function getCookieBannerStoreService(storeObject: Store<RootState>): CookieBannerStoreService {
  return new CookieBannerStoreService(storeObject, StoreModuleName.cookieBanner);
}

export function getPresenceStoreService(storeObject: Store<RootState>): PresenceStoreService {
  return new PresenceStoreService(storeObject, StoreModuleName.presence);
}

export function getMediaBoardSocialContentStoreService(
  storeObject: Store<RootState>
): MediaBoardSocialContentStoreService {
  return new MediaBoardSocialContentStoreService(storeObject, StoreModuleName.mediaBoardSocialContent);
}

/*
 * Ready-to-use service instances for the different modules.
 *
 * We're providing the namespace name from here since (technically) the module itself doesn't know it. Note that the
 * namespaces are automatically matched against the appropriate keys in the RootState (i.e. can't create an instance of
 * the ErrorModuleService with the namespace of "venue").
 */
export const uiStoreService = getUiStoreService(theStore);
export const venueStoreService = getVenueStoreService(theStore);
export const userStoreService = getUserStoreService(theStore);
export const cookieBannerStoreService = getCookieBannerStoreService(theStore);
export const presenceStoreService = getPresenceStoreService(theStore);
export const mediaBoardSocialContentStoreService = getMediaBoardSocialContentStoreService(theStore);
export const store = theStore;
