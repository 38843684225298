
import { defineComponent } from "vue";
import MobileIndicator from "@/ui/components/MobileIndicator.vue";
import TheErrorDialog from "@/components/TheErrorDialog.vue";
import { uiStoreService, venueStoreService } from "@/store/module-services";
import { Style } from "@/services/backend/generated/model/style";

type BackgroundStyle = {
  backgroundImage?: string;
  backgroundColor: string;
};

export default defineComponent({
  components: {
    MobileIndicator,
    TheErrorDialog
  },
  props: {
    showBackgroundImage: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    style(): BackgroundStyle {
      const styleResult: BackgroundStyle = {
        backgroundColor: this.architectureStyle.background
      };

      if (this.showBackgroundImage) {
        const uiConfig = uiStoreService.getUiConfig();
        const image = uiConfig?.assets.TheLoginPage.background;
        if (image) {
          styleResult.backgroundImage = `url(${image})`;
        }
      }

      return styleResult;
    },

    architectureStyle(): Style {
      return venueStoreService.getStyle();
    },
    isRouterGuardCheckingNow(): boolean {
      return uiStoreService.isRouterGuardCheckingNow();
    },
    isLoginView(): boolean {
      return this.$route.name === "login";
    }
  }
});
