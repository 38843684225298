
import BaseListItemAvatar from "@/ui/base/BaseListItemAvatar.vue";
import getInitials from "@/utility/get-initials";
import { defineComponent, PropType } from "vue";
import { UserInfo } from "@/model/user-info";
import { getAreaName } from "@/utility/get-area-name";

export default defineComponent({
  components: { BaseListItemAvatar },
  props: {
    areaId: { type: String, required: true },
    user: { type: Object as PropType<UserInfo>, required: true }
  },
  computed: {
    initials(): string {
      return getInitials(this.user.attributes.name);
    }
  },
  methods: {
    getAreaName
  }
});
