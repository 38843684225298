import { VenueVersion } from "@/services/backend/generated/model/venue-version";
import { LoginConfiguration } from "@/services/backend/generated/model/login-configuration";

/**
 * The configuration object of a Firebase project.
 * Necessary for connecting to the real time database.
 * See https://firebase.google.com/docs/web/setup#config-object
 */
export type FirebaseConfig = {
  // The project ID of the Google Cloud project.
  projectId: string;
  // The application ID of the Firebase application.
  appId: string;
  // The key required to access the Firebase REST API.
  apiKey: string;
  // The authorized domain used for logins via Firebase.
  authDomain: string | undefined;
  // The URL of the Firebase realtime database (RTDB).
  databaseURL: string | undefined;
  // The default Cloud Storage for Firebase storage bucket name.
  storageBucket: string | undefined;
  // The sender ID for use with Firebase Cloud Messaging.
  messagingSenderId: string | undefined;
};

/**
 * The type of object stored in ui-config.json next to the application's index.html.
 */
export type UIConfigState = {
  // A list of features to enable/disable
  features?: {
    // Whether or not the user presence feature should be activated.
    userPresence?: boolean;
  };
  // The Firebase project configuration.
  firebase: FirebaseConfig;
  // A list of assets configurable during deployment.
  assets: {
    // The assets used by TheWorkInProgressPage.
    TheWorkInProgressPage: {
      // The URL of the background image.
      background: string;
    };
    // The assets used by TheLoginPage.
    TheLoginPage: {
      // The URL of the logo image.
      logo: string;
      // The URL of the background image.
      background: string;
    };
  };
  // The i18n configuration.
  i18n: {
    // The default locale.
    defaultLocale: string;
    // The list of supported locales.
    supportedLocales: Array<string>;
  };
  // The API key used for video chat.
  vonageApiKey: string;
  // The API publish key used for chat.
  pubnubApiPublishKey: string;
  // The API subscribe key used for chat.
  pubnubApiSubscribeKey: string;
  // The API key used for zoom meetings.
  zoomApiKey: string;
  // the favicon of the tab
  favicon: string;
  // the title of the tab
  tabTitle: string;
};
type BlankError = {
  hasErrors: false;
};

export enum ErrorCode {
  FORBIDDEN,
  NOT_REACHABLE,
  PROCESSING_ERROR,
  UNAUTHORIZED,
  FUNCTION_UNSUPPORTED,
  WIDGET_ERROR
}

type ExistingError = {
  hasErrors: true;
  code: ErrorCode;
  message: string;
};
export type ErrorState = {
  globalError: BlankError | ExistingError;
  suppressDefaultErrorDialog: boolean;
};

export interface UiState {
  error: ErrorState;

  // The version of the venue ui application.
  appVersion: string;

  // Header height
  headerHeight: number;

  // The UI config that is written during the deployment.
  uiConfig: UIConfigState | undefined;

  // The currently published version of the UI.
  venueVersion: VenueVersion | undefined;

  loginEnabled: boolean | undefined;

  loginConfigurations: LoginConfiguration[];

  lastAreaId: string | undefined;

  // A clock synchronized with the server to prevent easy client clock manipulation (see server-time-service.ts)
  serverTime: Date;

  // The stack of active dialogs for the usage of a single global dialog close button
  activeDialogStack: Record<string, unknown>[];

  // Whether a widget has got an active navigation guard
  widgetsWithNavigationGuards: string[];
  wantToNavigate: boolean;

  // Handle the chat widget
  chat: {
    // Whether it has been opened yet
    opened: boolean;

    // Whether the chat window is expanded
    expanded: boolean;

    // Which room-specific chats to show for each room
    roomChats: Record<string, string[]>;

    attendeeToChatUid: string | undefined;
  };

  routerGuardsChecking: boolean;
}

export enum PublishState {
  UNKNOWN,
  UNPUBLISHED,
  PUBLISHED
}
