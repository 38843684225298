import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0439f63d"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "d-flex flex-column-reverse" }
const _hoisted_2 = {
  key: 0,
  class: "loading"
}
const _hoisted_3 = { class: "loading-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_mobile_indicator = _resolveComponent("mobile-indicator")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_v_progress_circular = _resolveComponent("v-progress-circular")!
  const _component_the_error_dialog = _resolveComponent("the-error-dialog")!
  const _component_v_main = _resolveComponent("v-main")!

  return (_openBlock(), _createBlock(_component_v_main, {
    id: "app-main",
    style: _normalizeStyle(_ctx.style)
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_mobile_indicator),
        _createVNode(_component_router_view),
        (_ctx.isRouterGuardCheckingNow && !_ctx.isLoginView)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createVNode(_component_v_progress_circular, {
                class: "progress",
                indeterminate: ""
              }),
              _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t("globalWidget.loadingText")), 1)
            ]))
          : _createCommentVNode("", true),
        _createVNode(_component_the_error_dialog)
      ])
    ]),
    _: 1
  }, 8, ["style"]))
}