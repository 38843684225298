/**
 * A wrapper that protects an asynchronous action against concurrent execution.
 */
export default class NonConcurrentAsyncAction<T> {
  private readonly action: () => Promise<T>;
  private activePromise: Promise<T> | null = null;

  /**
   * Constructor.
   *
   * @param action The action.
   */
  constructor(action: () => Promise<T>) {
    this.action = action;
  }

  /**
   * Executes the wrapped action only if it is not already executing.
   * Otherwise, returns a promise for the already executing action.
   *
   * @return The result of the action.
   */
  exec(): Promise<T> {
    // if the action is already busy, then return the active promise
    if (this.activePromise) {
      return this.activePromise;
    }

    // otherwise execute the action and remember its promise; other callers may want to attach to it, too
    this.activePromise = this.action().finally(() => {
      this.activePromise = null;
    });

    return this.activePromise;
  }
}
