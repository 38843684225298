import { NavigationGuardNext, RouteLocationNormalized } from "vue-router";
import { cookieBannerStoreService } from "@/store/module-services";
import LogCapturingService from "@/services/log-capturing-service";

/**
 * Displays the cookie banner if the route ends with `#privacy-preferences`
 * For example the route `.../login#privacy-preferences` will do the login (see other route guards) and after that
 * display the cookie banner.
 *
 * @param to The route being navigated to.
 * @param from The route being navigated away from.
 * @param next The function called to resolve the hook.
 */
export async function cookieBannerGuard(
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
): Promise<void> {
  if (to.hash === "#privacy-preferences") {
    try {
      await cookieBannerStoreService.setVisible(true);
    } catch (e) {
      const error = e as Error;
      console.error("Could not open the cookie banner. Continue navigation.", error);
      await LogCapturingService.captureError(error.message, error.stack);
    }
  }
  next();
}
