import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_user_summary = _resolveComponent("user-summary")!
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_v_list_item_title = _resolveComponent("v-list-item-title")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_list = _resolveComponent("v-list")!
  const _component_v_card = _resolveComponent("v-card")!

  return (_openBlock(), _createBlock(_component_v_card, {
    color: "background",
    "data-testid": "user-context-menu-content",
    class: "mg-user-context"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_user_summary, {
        "area-id": _ctx.areaId,
        user: _ctx.user,
        nav: ""
      }, null, 8, ["area-id", "user"]),
      _createVNode(_component_v_divider),
      _createVNode(_component_v_list, {
        dense: "",
        color: "background"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_list_item, {
            "data-testid": "user-profile-link",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.openUserProfile()))
          }, {
            append: _withCtx(() => [
              _createVNode(_component_v_icon, { class: "text--text" }, {
                default: _withCtx(() => [
                  _createTextVNode("mdi-cog")
                ]),
                _: 1
              })
            ]),
            default: _withCtx(() => [
              _createVNode(_component_v_list_item_title, { class: "text--text" }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t("userProfile.link")), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          (_ctx.cookieBannerEnabled)
            ? (_openBlock(), _createBlock(_component_v_list_item, {
                key: 0,
                "data-testid": "privacy-preferences-link",
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.openPrivacyPreferences()))
              }, {
                append: _withCtx(() => [
                  _createVNode(_component_v_icon, { class: "text--text" }, {
                    default: _withCtx(() => [
                      _createTextVNode("mdi-incognito")
                    ]),
                    _: 1
                  })
                ]),
                default: _withCtx(() => [
                  _createVNode(_component_v_list_item_title, { class: "text--text" }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t("privacyPreferences")), 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          _createVNode(_component_v_list_item, {
            "data-testid": "logout",
            onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('logout')))
          }, {
            append: _withCtx(() => [
              _createVNode(_component_v_icon, { class: "text--text" }, {
                default: _withCtx(() => [
                  _createTextVNode("mdi-logout")
                ]),
                _: 1
              })
            ]),
            default: _withCtx(() => [
              _createVNode(_component_v_list_item_title, { class: "text--text" }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t("login.logout")), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}