
import { defineComponent } from "vue";
import { initLanguage, LanguageName, languageNames, setLanguage } from "@/i18n/lang";
import { uiStoreService, venueStoreService } from "@/store/module-services";
import { Style } from "@/services/backend/generated/model/style";
import LogCapturingService from "@/services/log-capturing-service";

export default defineComponent({
  computed: {
    /**
     * Returns the intersection of languages, that are supported by the venue (within the configuration data) and
     * defined in the application.
     *
     * @return Available languages within venue and application.
     */
    availableLanguages(): LanguageName[] {
      return languageNames.filter((language) => this.supportedLocales?.includes(language.locale));
    },

    defaultLocale(): string | undefined {
      return uiStoreService.getDefaultLocale();
    },

    supportedLocales(): string[] | undefined {
      return uiStoreService.getSupportedLocales();
    },

    architectureStyle(): Style {
      return venueStoreService.getStyle();
    },
    currentLanguageTitle(): string | undefined {
      return this.availableLanguages.find((lang) => lang.locale === this.$i18n.locale)?.name;
    }
  },

  mounted() {
    if (this.defaultLocale !== undefined && this.supportedLocales !== undefined) {
      initLanguage(this.supportedLocales, this.$route.query.lang as string, this.defaultLocale);
    } else {
      LogCapturingService.captureError(
        "Config Error",
        "Tried to mount language selection with no languages present."
      ).catch((reason) => console.error(reason));
    }
  },

  methods: {
    setLanguage
  }
});
