import { VenueVersion } from "@/services/backend/generated/model/venue-version";
import { LoginConfiguration } from "@/services/backend/generated/model/login-configuration";
import { ErrorCode, UIConfigState, UiState } from "@/store/modules/ui/UiState";
import { TypedMutation } from "@/store/framework/TypedMutation";
/**
 * Defines all mutation names in the ui store slice.
 */
export enum UiMutation {
  SET_UI_CONFIG = "SET_UI_CONFIG",
  SET_VENUE_VERSION = "SET_VENUE_VERSION",
  SET_LOGIN_ENABLED = "SET_LOGIN_ENABLED",
  SET_LOGIN_PROVIDERS = "SET_LOGIN_PROVIDERS",
  SET_LAST_AREA_ID = "SET_LAST_AREA_ID",
  CLEAR_ERROR = "CLEAR_ERROR",
  SET_ERROR = "SET_ERROR",
  SET_HEADER_HEIGHT = "SET_HEADER_HEIGHT",
  SET_DEFAULT_DIALOG_SUPPRESSION = "SET_DEFAULT_DIALOG_SUPPRESSION",
  SET_SERVER_TIME = "SET_SERVER_TIME",
  ADD_TO_ACTIVE_DIALOG_STACK = "ADD_TO_ACTIVE_DIALOG_STACK",
  REMOVE_FROM_ACTIVE_DIALOG_STACK = "REMOVE_FROM_ACTIVE_DIALOG_STACK",
  POP_FROM_ACTIVE_DIALOG_STACK = "POP_FROM_ACTIVE_DIALOG_STACK",
  OPEN_CHAT = "OPEN_CHAT",
  CLOSE_CHAT = "CLOSE_CHAT",
  EXPAND_CHAT = "EXPAND_CHAT",
  MINIMIZE_CHAT = "MINIMIZE_CHAT",
  SET_ROOM_CHATS = "SET_ROOM_CHATS",
  ADD_NAVIGATION_GUARD = "ADD_NAVIGATION_GUARD",
  REMOVE_NAVIGATION_GUARD = "REMOVE_NAVIGATION_GUARD",
  BEGIN_GUARDED_NAVIGATION = "BEGIN_GUARDED_NAVIGATION",
  COMPLETE_GUARDED_NAVIGATION = "COMPLETE_GUARDED_NAVIGATION",
  SET_ATTENDEE_TO_CHAT_UID = "SET_ATTENDEE_TO_CHAT_UID",
  SET_MEDIA_BORDER_WIDGET = "SET_MEDIA_BORDER_WIDGET",
  WAIT_ROUTER_GUARDS = "WAIT_ROUTER_GUARDS",
  ROUTER_GUARDS_FINISHED = "ROUTER_GUARDS_FINISHED"
}
/**
 * Defines the mutation types in the ui store slice.
 */
interface UiMutations {
  [UiMutation.SET_UI_CONFIG]: UIConfigState;
  [UiMutation.SET_VENUE_VERSION]: VenueVersion;
  [UiMutation.SET_LOGIN_ENABLED]: boolean;
  [UiMutation.SET_LOGIN_PROVIDERS]: LoginConfiguration[];
  [UiMutation.SET_LAST_AREA_ID]: string;
  [UiMutation.SET_HEADER_HEIGHT]: number;
  [UiMutation.CLEAR_ERROR]: void;
  [UiMutation.SET_ERROR]: { code: ErrorCode; message: string };
  [UiMutation.SET_DEFAULT_DIALOG_SUPPRESSION]: boolean;
  [UiMutation.SET_SERVER_TIME]: Date;
  [UiMutation.ADD_TO_ACTIVE_DIALOG_STACK]: Record<string, unknown>;
  [UiMutation.REMOVE_FROM_ACTIVE_DIALOG_STACK]: Record<string, unknown>;
  [UiMutation.POP_FROM_ACTIVE_DIALOG_STACK]: Record<string, unknown>;
  [UiMutation.OPEN_CHAT]: void;
  [UiMutation.CLOSE_CHAT]: void;
  [UiMutation.EXPAND_CHAT]: void;
  [UiMutation.MINIMIZE_CHAT]: void;
  [UiMutation.SET_ROOM_CHATS]: { room: string; chats: string[] };
  [UiMutation.ADD_NAVIGATION_GUARD]: string;
  [UiMutation.REMOVE_NAVIGATION_GUARD]: string;
  [UiMutation.BEGIN_GUARDED_NAVIGATION]: void;
  [UiMutation.COMPLETE_GUARDED_NAVIGATION]: void;
  [UiMutation.SET_ATTENDEE_TO_CHAT_UID]: string | undefined;
  [UiMutation.WAIT_ROUTER_GUARDS]: void;
  [UiMutation.ROUTER_GUARDS_FINISHED]: void;
}
/**
 * Defines types of all mutation functions in the ui store slice.
 */
type UiMutationFunctions = {
  [name in keyof UiMutations]: TypedMutation<UiState, UiMutations[name]>;
};
/**
 * Mutation method implementations in the ui store slice.
 */
export const uiMutations: UiMutationFunctions = {
  [UiMutation.SET_UI_CONFIG](state, uiConfig: UIConfigState): void {
    state.uiConfig = uiConfig;
  },
  [UiMutation.SET_VENUE_VERSION](state, venueVersion: VenueVersion): void {
    state.venueVersion = venueVersion;
  },
  [UiMutation.SET_LOGIN_ENABLED](state, loginEnabled: boolean) {
    state.loginEnabled = loginEnabled;
  },
  [UiMutation.SET_LOGIN_PROVIDERS](state, loginConfigurations: LoginConfiguration[]) {
    state.loginConfigurations = loginConfigurations;
  },
  [UiMutation.SET_LAST_AREA_ID](state, areaId: string) {
    state.lastAreaId = areaId;
  },
  [UiMutation.SET_HEADER_HEIGHT](state, height: number) {
    state.headerHeight = height;
  },
  [UiMutation.CLEAR_ERROR](state) {
    state.error.globalError = { hasErrors: false };
  },
  [UiMutation.SET_ERROR](state, { code, message }: { code: ErrorCode; message: string }) {
    state.error.globalError = {
      hasErrors: true,
      code,
      message
    };
  },
  [UiMutation.SET_DEFAULT_DIALOG_SUPPRESSION](state, suppress: boolean) {
    state.error.suppressDefaultErrorDialog = suppress;
  },
  [UiMutation.SET_SERVER_TIME](state, serverTime: Date): void {
    state.serverTime = serverTime;
  },
  [UiMutation.ADD_TO_ACTIVE_DIALOG_STACK](state, dialog: Record<string, unknown>): void {
    state.activeDialogStack.push(dialog);
  },
  [UiMutation.REMOVE_FROM_ACTIVE_DIALOG_STACK](state, dialog: Record<string, unknown>): void {
    const dialogIndex = state.activeDialogStack.indexOf(dialog);
    if (dialogIndex > -1) {
      state.activeDialogStack.splice(state.activeDialogStack.indexOf(dialog), 1);
    }
  },
  [UiMutation.POP_FROM_ACTIVE_DIALOG_STACK](state): void {
    state.activeDialogStack.pop();
  },
  [UiMutation.OPEN_CHAT](state) {
    state.chat.opened = true;
  },
  [UiMutation.CLOSE_CHAT](state) {
    state.chat.opened = false;
  },
  [UiMutation.EXPAND_CHAT](state) {
    state.chat.expanded = true;
  },
  [UiMutation.MINIMIZE_CHAT](state) {
    state.chat.expanded = false;
  },
  [UiMutation.SET_ROOM_CHATS](state, { room, chats }: { room: string; chats: string[] }) {
    state.chat.roomChats[room] = chats;
  },
  [UiMutation.ADD_NAVIGATION_GUARD](state: UiState, widgetId) {
    if (!state.widgetsWithNavigationGuards.includes(widgetId)) {
      state.widgetsWithNavigationGuards.push(widgetId);
    }
  },
  [UiMutation.REMOVE_NAVIGATION_GUARD](state: UiState, widgetId) {
    if (state.widgetsWithNavigationGuards.includes(widgetId)) {
      const index = state.widgetsWithNavigationGuards.indexOf(widgetId);
      if (index > -1) {
        state.widgetsWithNavigationGuards.splice(index, 1);
      }
    }
  },
  [UiMutation.BEGIN_GUARDED_NAVIGATION](state: UiState) {
    state.wantToNavigate = true;
  },
  [UiMutation.COMPLETE_GUARDED_NAVIGATION](state: UiState) {
    state.wantToNavigate = false;
  },
  [UiMutation.SET_ATTENDEE_TO_CHAT_UID](state: UiState, attendeeUid: string | undefined) {
    state.chat.attendeeToChatUid = attendeeUid;
  },
  [UiMutation.WAIT_ROUTER_GUARDS](state: UiState) {
    state.routerGuardsChecking = true;
  },
  [UiMutation.ROUTER_GUARDS_FINISHED](state: UiState) {
    state.routerGuardsChecking = false;
  }
};
