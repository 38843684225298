import { RootState } from "@/store/RootState";
import { ActionTree, Module } from "vuex";
import { mediaBoardSocialContentActions } from "./mediaBoardSocialContentActions";
import { mediaBoardSocialContentGetters } from "./mediaBoardSocialContentGetters";
import { mediaBoardSocialContentMutations } from "./mediaBoardSocialContentMutations";
import { MediaBoardSocialContentState } from "./mediaBoardSocialContentState";

function initialMediaBoardSocialContentState(): MediaBoardSocialContentState {
  return {
    myLikes: [],
    mediaBoardRecommendations: {}
  };
}

const mediaBoardSocialContentModule: Module<MediaBoardSocialContentState, RootState> = {
  namespaced: true,
  state: initialMediaBoardSocialContentState(),
  actions: mediaBoardSocialContentActions as ActionTree<MediaBoardSocialContentState, RootState>,
  getters: mediaBoardSocialContentGetters,
  mutations: mediaBoardSocialContentMutations
};

export default mediaBoardSocialContentModule;
