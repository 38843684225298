import { AttendeeRef } from "@/model/attendee-ref";
import { TypedMutation } from "@/store/framework/TypedMutation";
import { PresenceState } from "./PresenceState";

export enum PresenceMutation {
  SET_ATTENDEES = "SET_ATTENDEES"
}

interface PresenceMutations {
  [PresenceMutation.SET_ATTENDEES]: AttendeeRef[];
}

/**
 * Defines types of all mutation functions in the venue store slice.
 */
type PresenceMutationFunctions = {
  [name in keyof PresenceMutations]: TypedMutation<PresenceState, PresenceMutations[name]>;
};

/**
 * Mutation method implementations in the venue store slice.
 */
export const presenceMutations: PresenceMutationFunctions = {
  [PresenceMutation.SET_ATTENDEES](state: PresenceState, attendees: AttendeeRef[]) {
    state.attendees = attendees;
  }
};
