import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!

  return (_openBlock(), _createBlock(_component_v_btn, {
    color: _ctx.style.secondary,
    "data-testid": "logout-button",
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('click')))
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_icon, {
        left: "",
        style: _normalizeStyle(`color: ${_ctx.style.text} !important`)
      }, {
        default: _withCtx(() => [
          _createTextVNode("mdi-logout")
        ]),
        _: 1
      }, 8, ["style"]),
      _createElementVNode("span", {
        style: _normalizeStyle(`color: ${_ctx.style.text}`)
      }, _toDisplayString(_ctx.$t("login.logout")), 5)
    ]),
    _: 1
  }, 8, ["color"]))
}