/**
 * This class holds some value of type T that might be updated very frequently. It is responsible for debouncing this
 * update, i.e. awaiting multiple changes in a specified interval and only forwarding a single change after some time.
 *
 * Debouncing mechanism:
 *  - a single update will be propagated immediately.
 *  - following updates during {@link minUpdateInterval} will be ignored.
 *  - when the interval expires and there were updates in between, the last update will then be propagated and the
 *    interval will be reset
 *
 * Delaying updates:
 *  - by calling {@link resetNoUpdateTimeout}, the next update can be delayed further.
 *
 * Preventing updates:
 *  - as long as an update preventer is set ({@link addUpdatePreventer}), no update will take place.
 *  - when the last update preventer is removed ({@link removeUpdatePreventer}), an update will be triggered (behaving
 *    accordingly to above rules).
 */
export class DebouncedValue<T> {
  /**
   * The value from the last received update.
   * @private
   */
  private updatedValue: T;

  /**
   * The minimum amount of milliseconds that have to pass between updates.
   * @private
   */
  private readonly minUpdateInterval: number;

  /**
   * Listeners can register functions that are called on update propagation. They are stored here.
   * @private
   */
  private readonly onUpdateListeners: ((value: T) => void)[] = [];

  /**
   * The number of *things?* that prevent an update. When this number is above 0, no updates occur.
   * @private
   */
  private updatePreventerCount = 0;

  /**
   * After an update, no update will be propagated during a timespan of {@link minUpdateInterval} ms. If an updated
   * value was received in this timespan, updatedValueExists is set to true.
   * @private
   */
  private updatedValueExists = false;

  /**
   * The current timeout representing a timespan of no update.
   * @private
   */
  private noUpdateTimeout: number | null = null;

  constructor(currentValue: T, minUpdateInterval: number) {
    this.updatedValue = currentValue;
    this.minUpdateInterval = minUpdateInterval;
  }

  /**
   * The given function will be called with the most recent value. Updates will not occur more frequent than
   * {@link minUpdateInterval}.
   * @param onUpdate
   */
  public addOnUpdateListener(onUpdate: (value: T) => void): void {
    this.onUpdateListeners.push(onUpdate);
    this.tryTriggerUpdate();
  }

  /**
   * Adds an update preventer. No updates will occur until {@link removeUpdatePreventer} is called again.
   */
  public addUpdatePreventer(): void {
    this.updatePreventerCount++;
  }

  public removeUpdatePreventer(): void {
    if (this.updatePreventerCount > 0) {
      this.updatePreventerCount--;
    }
    if (this.updatePreventerCount === 0) {
      this.tryTriggerUpdate();
    }
  }

  /**
   * Sets a more recent version of the value to debounce. This value might either be propagated instantly or when
   * updates are possible again.
   * @param newValue
   */
  public setUpdatedValue(newValue: T): void {
    this.updatedValue = newValue;
    this.tryTriggerUpdate();
  }

  /**
   * Resets the current no update timeout. By calling this, propagation of the most recent value is delayed for another
   * {@link minUpdateInterval} ms.
   */
  public resetNoUpdateTimeout(): void {
    // stop previous update
    if (this.noUpdateTimeout !== null) {
      window.clearTimeout(this.noUpdateTimeout);
    }

    // schedule new update
    this.noUpdateTimeout = window.setTimeout(() => {
      // when an update should occur...
      // remove this timeout
      this.noUpdateTimeout = null;

      // if during the timeout an update has occured
      if (this.updatedValueExists) {
        // since tryTriggerUpdate() might call resetNoUpdateTimeout(), this is sort of recursive. But since this call to
        // tryTriggerUpdate() is in a setTimeout() handler, stack overflow will not occur.
        this.tryTriggerUpdate();
      }
    }, this.minUpdateInterval);
  }

  /**
   * Clears any timeouts set by this object.
   */
  public destroy(): void {
    if (this.noUpdateTimeout !== null) {
      window.clearTimeout(this.noUpdateTimeout);
    }
  }

  /**
   * Whether an update should be prevented due to registered update preventers.
   * @private
   */
  private get shouldPreventUpdate(): boolean {
    return this.updatePreventerCount !== 0;
  }

  /**
   * Triggers an update unless...
   *  - an update preventer exists
   *  - the no update timeout currently is running
   * @private
   */
  private tryTriggerUpdate(): void {
    if (this.shouldPreventUpdate) {
      this.updatedValueExists = true;
      return;
    }

    if (this.noUpdateTimeout !== null) {
      this.updatedValueExists = true;
      return;
    }

    this.triggerUpdate();
  }

  /**
   * Triggers an update. Will call all {@link onUpdateListeners} with the updated value.
   * @private
   */
  private triggerUpdate(): void {
    this.onUpdateListeners.forEach((listener) => listener(this.updatedValue));
    this.updatedValueExists = false;
    this.resetNoUpdateTimeout();
  }
}
