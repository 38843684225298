import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "text--text text-h5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_avatar = _resolveComponent("v-avatar")!
  const _component_v_badge = _resolveComponent("v-badge")!

  return (_openBlock(), _createBlock(_component_v_badge, {
    class: "pr-3",
    dot: "",
    bottom: "",
    bordered: "",
    color: "success",
    "offset-y": "32"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_avatar, {
        size: "40",
        color: "primary",
        class: "cursor-pointer",
        "data-testid": "user-preview"
      }, {
        default: _withCtx(() => [
          _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.initials), 1)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}