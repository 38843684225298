import { VenueState } from "@/store/modules/venue/VenueState";
import { FrontendCookieProvider } from "@/model/frontend-cookie-provider";

/**
 * Consolidates utility methods used in venue mutations
 */

/**
 * Sets accepted with value for all cookieProviders that return true for the predicate
 *
 * @param state     the state
 * @param predicate the predicate
 * @param value     the new accepted value
 */
export function setAccepted(
  state: VenueState,
  predicate: (cp: FrontendCookieProvider) => boolean,
  value: boolean
): void {
  state.cookieProviders.filter((cp) => predicate(cp)).forEach((cp) => (cp.accepted = value));
}
