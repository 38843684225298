
import { defineComponent } from "vue";
import BaseToggleButton from "@/ui/base/BaseToggleButton.vue";
import TrackingService from "@/services/tracking-service";
import { userStoreService } from "@/store/module-services";

export default defineComponent({
  components: { BaseToggleButton },
  props: {
    areaId: {
      type: String
    },
    searchTerm: {
      type: String
    },
    areaFilter: {
      type: Boolean,
      required: true
    },
    companyFilter: {
      type: Boolean,
      required: true
    }
  },
  emits: ["update:areaFilter", "update:companyFilter", "update:searchTerm"],
  computed: {
    hasCompany() {
      return !!userStoreService.getUser()?.attributes.company;
    },
    areaFilterCp: {
      get(): boolean {
        return this.areaFilter;
      },
      set(value: boolean): void {
        this.$emit("update:areaFilter", value);
        this.trackFilterByArea();
      }
    },
    companyFilterCp: {
      get(): boolean {
        return this.companyFilter;
      },
      set(value: boolean): void {
        this.$emit("update:companyFilter", value);
        this.trackFilterByCompany();
      }
    },
    searchTermCp: {
      get(): string | undefined {
        return this.searchTerm;
      },
      set(value: string | null): void {
        this.$emit("update:searchTerm", value ?? "");
      }
    }
  },
  methods: {
    trackFilterByText() {
      TrackingService.trackPresenceEvent({
        action: "Filter by Text",
        areaId: this.areaId ?? "undefined",
        widgetName: "Presence",
        widgetType: "Presence"
      });
    },
    trackFilterByArea() {
      TrackingService.trackPresenceEvent({
        action: "Filter by Area",
        areaId: this.areaId ?? "undefined",
        widgetName: "Presence",
        widgetType: "Presence"
      });
    },
    trackFilterByCompany() {
      TrackingService.trackPresenceEvent({
        action: "Filter by Company",
        areaId: this.areaId ?? "undefined",
        widgetName: "Presence",
        widgetType: "Presence"
      });
    }
  }
});
