/**
 * Defines the minimum width of an attendee card. It is necessary for nice looks and needed by the dynamic scroller
 * component for optimal rendering.
 */
export const MIN_ATTENDEE_CARD_WIDTH = 175; /* px */

/**
 * Defines the minimum height of an attendee card. It is necessary s.t. all cards have the same height.
 */
export const MIN_ATTENDEE_CARD_HEIGHT = 60; /* px */

/**
 * Defines the height of the app bar needed to properly display the presence widget.
 */
export const APP_BAR_HEIGHT_FOR_PRESENCE = 102; /* px */

/**
 * Update attendees no more than every x miliseconds.
 */
export const MIN_ATTENDEE_UPDATE_INTERVAL = 5000; /* ms */
