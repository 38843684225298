import { MediaBoardLikeVuexRef } from "@/model/media-board-like-ref";
import { TypedGetterFunctions } from "@/store/framework/TypedGetterFunctions";
import { RootState } from "@/store/RootState";
import { MediaBoardSocialContentState } from "./mediaBoardSocialContentState";

/**
 * Defines all properties on the getters object that can be read from when working with the media board social content store slice.
 */
export interface MediaBoardSocialContentGetters {
  readonly getMyLikes: MediaBoardLikeVuexRef[];
  readonly getRecommended: Record<string, string[]>;
}

export const mediaBoardSocialContentGetters: TypedGetterFunctions<
  MediaBoardSocialContentState,
  RootState,
  MediaBoardSocialContentGetters
> = {
  getMyLikes: (state: MediaBoardSocialContentState): MediaBoardLikeVuexRef[] => state.myLikes,
  getRecommended: (state: MediaBoardSocialContentState): Record<string, string[]> => state.mediaBoardRecommendations
};
