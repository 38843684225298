import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, withModifiers as _withModifiers, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-506f9bf4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "mb-1" }
const _hoisted_2 = { class: "mx-3" }
const _hoisted_3 = { class: "mx-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_attendee_summary = _resolveComponent("attendee-summary")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_card = _resolveComponent("v-card")!

  return (_openBlock(), _createBlock(_component_v_card, {
    color: "background",
    width: "min-content",
    "data-testid": "mobile-attendee-card"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_attendee_summary, {
        attendee: _ctx.attendee,
        class: "pb-0 mb-0"
      }, null, 8, ["attendee"]),
      _createVNode(_component_v_card_actions, { class: "pt-0 flex-column align-stretch" }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_v_btn, {
              variant: "flat",
              color: "secondary",
              class: "mg-button",
              "data-testid": "open-attendee-profile",
              onClick: _withModifiers(_ctx.openAttendeeProfile, ["stop"])
            }, {
              default: _withCtx(() => [
                _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.$t("presence.contextMenu.actions.goToProfile")), 1),
                _createVNode(_component_v_spacer),
                _createVNode(_component_v_icon, { right: "" }, {
                  default: _withCtx(() => [
                    _createTextVNode("mdi-cog")
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["onClick"])
          ]),
          _createElementVNode("div", null, [
            _createVNode(_component_v_btn, {
              variant: "flat",
              color: "secondary",
              class: "mg-button",
              "data-testid": "open-chat",
              onClick: _ctx.openChat
            }, {
              default: _withCtx(() => [
                _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.$t("presence.contextMenu.actions.startChat")), 1),
                _createVNode(_component_v_spacer),
                _createVNode(_component_v_icon, { right: "" }, {
                  default: _withCtx(() => [
                    _createTextVNode("mdi-chat")
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["onClick"])
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}