import { venueStoreService } from "@/store/module-services";
import FetchAssetService from "@/services/fetch-asset-service";

/**
 * Loads a venue.
 *
 * @param {string} architectureId The architecture ID.
 */
export default async function loadVenue(architectureId?: string): Promise<void> {
  // Load the venue
  await venueStoreService.loadVenue(architectureId);

  // Register all icons of the architecture as cacheable assets
  const cacheableAssetIds = venueStoreService.getIcons().map((icon) => icon.assetId);
  await FetchAssetService.registerCacheableAssets(cacheableAssetIds, true);
}
