/* tslint:disable */
/* eslint-disable */
/**
 * VVenue Services API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 53
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CookiePreferencesMessage } from '../model';
// @ts-ignore
import { FrontendMessage } from '../model';
/**
 * FrontendLoggingControllerApi - axios parameter creator
 * @export
 */
export const FrontendLoggingControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * To avoid exposing the log capturing endpoint in anonymous venues,this endpoint offers a way to capture anonymous attendees accessing the venue.
         * @summary Logs an login in a venue allowing anonymous attendees.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        captureAnonymousLogin: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/capture/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This endpoint gets called by the UI each time cookie preferences are changed. It saves the time, user id and the selected preferences.
         * @summary Logs the cookie preferences of the user.
         * @param {CookiePreferencesMessage} cookiePreferencesMessage 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        captureCookiePreferences: async (cookiePreferencesMessage: CookiePreferencesMessage, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'cookiePreferencesMessage' is not null or undefined
            assertParamExists('captureCookiePreferences', 'cookiePreferencesMessage', cookiePreferencesMessage)
            const localVarPath = `/capture/cookiePreferences`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(cookiePreferencesMessage, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Due to clientside frontend, errors occurring in the frontend will not be logged.This endpoint may be used to capture errors and other messages from the frontend and log them for further ingestion.
         * @summary Logs a message from the frontend
         * @param {FrontendMessage} frontendMessage 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        captureLog: async (frontendMessage: FrontendMessage, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'frontendMessage' is not null or undefined
            assertParamExists('captureLog', 'frontendMessage', frontendMessage)
            const localVarPath = `/capture/log`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(frontendMessage, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FrontendLoggingControllerApi - functional programming interface
 * @export
 */
export const FrontendLoggingControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FrontendLoggingControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * To avoid exposing the log capturing endpoint in anonymous venues,this endpoint offers a way to capture anonymous attendees accessing the venue.
         * @summary Logs an login in a venue allowing anonymous attendees.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async captureAnonymousLogin(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.captureAnonymousLogin(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This endpoint gets called by the UI each time cookie preferences are changed. It saves the time, user id and the selected preferences.
         * @summary Logs the cookie preferences of the user.
         * @param {CookiePreferencesMessage} cookiePreferencesMessage 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async captureCookiePreferences(cookiePreferencesMessage: CookiePreferencesMessage, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.captureCookiePreferences(cookiePreferencesMessage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Due to clientside frontend, errors occurring in the frontend will not be logged.This endpoint may be used to capture errors and other messages from the frontend and log them for further ingestion.
         * @summary Logs a message from the frontend
         * @param {FrontendMessage} frontendMessage 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async captureLog(frontendMessage: FrontendMessage, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.captureLog(frontendMessage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FrontendLoggingControllerApi - factory interface
 * @export
 */
export const FrontendLoggingControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FrontendLoggingControllerApiFp(configuration)
    return {
        /**
         * To avoid exposing the log capturing endpoint in anonymous venues,this endpoint offers a way to capture anonymous attendees accessing the venue.
         * @summary Logs an login in a venue allowing anonymous attendees.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        captureAnonymousLogin(options?: any): AxiosPromise<void> {
            return localVarFp.captureAnonymousLogin(options).then((request) => request(axios, basePath));
        },
        /**
         * This endpoint gets called by the UI each time cookie preferences are changed. It saves the time, user id and the selected preferences.
         * @summary Logs the cookie preferences of the user.
         * @param {CookiePreferencesMessage} cookiePreferencesMessage 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        captureCookiePreferences(cookiePreferencesMessage: CookiePreferencesMessage, options?: any): AxiosPromise<void> {
            return localVarFp.captureCookiePreferences(cookiePreferencesMessage, options).then((request) => request(axios, basePath));
        },
        /**
         * Due to clientside frontend, errors occurring in the frontend will not be logged.This endpoint may be used to capture errors and other messages from the frontend and log them for further ingestion.
         * @summary Logs a message from the frontend
         * @param {FrontendMessage} frontendMessage 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        captureLog(frontendMessage: FrontendMessage, options?: any): AxiosPromise<void> {
            return localVarFp.captureLog(frontendMessage, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FrontendLoggingControllerApi - object-oriented interface
 * @export
 * @class FrontendLoggingControllerApi
 * @extends {BaseAPI}
 */
export class FrontendLoggingControllerApi extends BaseAPI {
    /**
     * To avoid exposing the log capturing endpoint in anonymous venues,this endpoint offers a way to capture anonymous attendees accessing the venue.
     * @summary Logs an login in a venue allowing anonymous attendees.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FrontendLoggingControllerApi
     */
    public captureAnonymousLogin(options?: AxiosRequestConfig) {
        return FrontendLoggingControllerApiFp(this.configuration).captureAnonymousLogin(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This endpoint gets called by the UI each time cookie preferences are changed. It saves the time, user id and the selected preferences.
     * @summary Logs the cookie preferences of the user.
     * @param {CookiePreferencesMessage} cookiePreferencesMessage 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FrontendLoggingControllerApi
     */
    public captureCookiePreferences(cookiePreferencesMessage: CookiePreferencesMessage, options?: AxiosRequestConfig) {
        return FrontendLoggingControllerApiFp(this.configuration).captureCookiePreferences(cookiePreferencesMessage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Due to clientside frontend, errors occurring in the frontend will not be logged.This endpoint may be used to capture errors and other messages from the frontend and log them for further ingestion.
     * @summary Logs a message from the frontend
     * @param {FrontendMessage} frontendMessage 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FrontendLoggingControllerApi
     */
    public captureLog(frontendMessage: FrontendMessage, options?: AxiosRequestConfig) {
        return FrontendLoggingControllerApiFp(this.configuration).captureLog(frontendMessage, options).then((request) => request(this.axios, this.basePath));
    }
}
