import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_footer = _resolveComponent("v-footer")!

  return (_ctx.showFooter && !_ctx.isMobile)
    ? (_openBlock(), _createBlock(_component_v_footer, {
        key: 0,
        app: "",
        fixed: "",
        color: "background",
        "data-testid": "venue-footer"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_row, {
            "no-gutters": "",
            class: "pa-1"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_col, {
                class: "mr-4",
                cols: "auto"
              }, {
                default: _withCtx(() => [
                  (_ctx.architectureId)
                    ? (_openBlock(), _createBlock(_component_router_link, {
                        key: 0,
                        to: {
            name: _ctx.MandatoryPagesEnum.PRIVACY_POLICY,
            params: { architectureId: _ctx.architectureId }
          },
                        style: _normalizeStyle(_ctx.appliedStyle)
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t("privacyPolicy")), 1)
                        ]),
                        _: 1
                      }, 8, ["to", "style"]))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }),
              _createVNode(_component_v_col, {
                class: "mx-4",
                cols: "auto"
              }, {
                default: _withCtx(() => [
                  (_ctx.architectureId)
                    ? (_openBlock(), _createBlock(_component_router_link, {
                        key: 0,
                        to: {
            name: _ctx.MandatoryPagesEnum.IMPRINT,
            params: { architectureId: _ctx.architectureId }
          },
                        style: _normalizeStyle(_ctx.appliedStyle)
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t("imprint")), 1)
                        ]),
                        _: 1
                      }, 8, ["to", "style"]))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }),
              (_ctx.cookieBannerType !== _ctx.CookieBannerType.NONE)
                ? (_openBlock(), _createBlock(_component_v_col, {
                    key: 0,
                    class: "mx-4",
                    cols: "auto"
                  }, {
                    default: _withCtx(() => [
                      (_ctx.architectureId)
                        ? (_openBlock(), _createElementBlock("button", {
                            key: 0,
                            style: _normalizeStyle(_ctx.appliedStyle),
                            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.openCookieBanner()))
                          }, _toDisplayString(_ctx.$t("privacyPreferences")), 5))
                        : _createCommentVNode("", true)
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true)
            ]),
            _: 1
          })
        ]),
        _: 1
      }))
    : _createCommentVNode("", true)
}