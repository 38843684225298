import { BindMyLikesOptions } from "@/model/bind-my-likes-options";
import { MediaBoardLikePayload } from "@/model/media-board-like-payload";
import { MediaBoardLikeVuexRef } from "@/model/media-board-like-ref";
import { MediaBoardUpdateRecommendationPayload } from "@/model/media-board-update-recommended-payload";
import AbstractStoreService from "@/store/framework/AbstractStoreService";
import { RootState } from "@/store/RootState";
import { MediaBoardSocialContentAction } from "./mediaBoardSocialContentActions";
import { MediaBoardSocialContentGetters } from "./mediaBoardSocialContentGetters";
import { MediaBoardSocialContentState } from "./mediaBoardSocialContentState";

export class MediaBoardSocialContentStoreService extends AbstractStoreService<
  MediaBoardSocialContentState,
  RootState,
  MediaBoardSocialContentGetters
> {
  /**
   * get likes of current user
   * @returns likes
   */
  getMyLikes(): MediaBoardLikeVuexRef[] {
    return this._get("getMyLikes");
  }

  /**
   * get recommended content pieces of some media board
   * @returns content pieces ids
   */
  getRecommended(mediaBoardId: string): string[] {
    return this._get("getRecommended")[mediaBoardId] || [];
  }

  /**
   * Add like to real time db
   * @param like
   */
  async addLike(like: MediaBoardLikePayload) {
    await this.dispatch(MediaBoardSocialContentAction.ADD_LIKE, like);
  }

  /**
   * Update likes of some user from realtime db for vuex store
   * @param userId
   */
  async bindMyLikes(options: BindMyLikesOptions) {
    await this.dispatch(MediaBoardSocialContentAction.BIND_MY_LIKES, options);
  }

  /**
   * Remove like from realtime db
   * @param like
   */
  async removeLike(like: MediaBoardLikePayload) {
    await this.dispatch(MediaBoardSocialContentAction.REMOVE_LIKE, like);
  }

  /**
   * Add recommendation updates from realtime db for vuex store
   */
  async updateRecommendations(payload: MediaBoardUpdateRecommendationPayload) {
    await this.dispatch(MediaBoardSocialContentAction.UPDATE_MEDIA_BOARD_RECOMMENDATION, payload);
  }
}
