import { resolveComponent as _resolveComponent, withKeys as _withKeys, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-beffe5bc"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  "data-testid": "mobile-presence-widget",
  class: "input-field-text-color"
}
const _hoisted_2 = { class: "my-2 search-button-container" }
const _hoisted_3 = { class: "flex-fill search-button-text" }
const _hoisted_4 = { class: "flex-fill search-button-text" }
const _hoisted_5 = { class: "mg-scroller-item-wrapper" }
const _hoisted_6 = { class: "d-flex flex-row align-center" }
const _hoisted_7 = { class: "text--text text-h6" }
const _hoisted_8 = { class: "ma-1 overflow-hidden attendee-card-content" }
const _hoisted_9 = { class: "text-truncate text-subtitle-2 font-weight-bold small-lines" }
const _hoisted_10 = {
  key: 0,
  class: "text-truncate text-caption small-lines font-weight-regular"
}
const _hoisted_11 = {
  key: 1,
  class: "text-truncate text-caption small-lines font-weight-regular"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_base_toggle_button = _resolveComponent("base-toggle-button")!
  const _component_v_avatar = _resolveComponent("v-avatar")!
  const _component_v_badge = _resolveComponent("v-badge")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_recycle_scroller = _resolveComponent("recycle-scroller")!
  const _component_v_sheet = _resolveComponent("v-sheet")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_v_text_field, {
      modelValue: _ctx.searchValue,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.searchValue) = $event)),
      color: "primary",
      class: "pb-0 text--text",
      outlined: "",
      dense: "",
      label: _ctx.$t('presence.widget.search'),
      clearable: "",
      "hide-details": "",
      "prepend-inner-icon": "mdi-magnify",
      "data-testid": "mobile-presence-widget-search",
      onBlur: _ctx.trackFilterByText,
      onKeydown: _withKeys(_ctx.blurInputOnMobile, ["enter"])
    }, null, 8, ["modelValue", "label", "onBlur", "onKeydown"]),
    _createElementVNode("div", _hoisted_2, [
      (_ctx.areaId)
        ? (_openBlock(), _createBlock(_component_base_toggle_button, {
            key: 0,
            modelValue: _ctx.filterArea,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.filterArea) = $event)),
            "data-testid": "button-filter-area",
            "x-small": "",
            class: "flex-fill",
            onClick: _ctx.trackFilterByArea
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_icon, { left: "" }, {
                default: _withCtx(() => [
                  _createTextVNode("mdi-filter")
                ]),
                _: 1
              }),
              _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.$t("presence.widget.filter.area")), 1)
            ]),
            _: 1
          }, 8, ["modelValue", "onClick"]))
        : _createCommentVNode("", true),
      (_ctx.hasCompany)
        ? (_openBlock(), _createBlock(_component_base_toggle_button, {
            key: 1,
            modelValue: _ctx.filterCompany,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.filterCompany) = $event)),
            "data-testid": "button-filter-company",
            "x-small": "",
            class: "flex-fill",
            onClick: _ctx.trackFilterByCompany
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_icon, { left: "" }, {
                default: _withCtx(() => [
                  _createTextVNode("mdi-filter")
                ]),
                _: 1
              }),
              _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.$t("presence.widget.filter.company")), 1)
            ]),
            _: 1
          }, 8, ["modelValue", "onClick"]))
        : _createCommentVNode("", true)
    ]),
    _createVNode(_component_v_sheet, {
      color: "background lighten-2",
      class: "py-1 mg-scroller-wrapper",
      "data-testid": "attendee-list"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_recycle_scroller, {
          items: _ctx.attendeeList,
          "key-field": "uid",
          "item-size": 70,
          direction: "vertical",
          class: "mg-scroller"
        }, {
          default: _withCtx(({ item }) => [
            _createElementVNode("div", _hoisted_5, [
              _createVNode(_component_v_card, {
                width: "100%",
                height: "60px",
                class: "attendee-card d-flex flex-row text--text",
                outlined: "",
                "data-testid": "attendee-card",
                color: _ctx.attendeeCardColor(item),
                onClick: ($event: any) => (_ctx.selectAttendee(item))
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_6, [
                    _createVNode(_component_v_badge, {
                      dot: "",
                      bottom: "",
                      bordered: "",
                      color: "success",
                      "offset-y": "23",
                      "offset-x": "0"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_avatar, {
                          size: "34",
                          color: "primary"
                        }, {
                          default: _withCtx(() => [
                            _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.getInitials(item.name)), 1)
                          ]),
                          _: 2
                        }, 1024)
                      ]),
                      _: 2
                    }, 1024)
                  ]),
                  _createElementVNode("div", _hoisted_8, [
                    _createElementVNode("div", _hoisted_9, _toDisplayString(item.name), 1),
                    (item.title)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_10, _toDisplayString(item.title), 1))
                      : _createCommentVNode("", true),
                    (item.company)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_11, _toDisplayString(item.company), 1))
                      : _createCommentVNode("", true)
                  ])
                ]),
                _: 2
              }, 1032, ["color", "onClick"])
            ])
          ]),
          _: 1
        }, 8, ["items"])
      ]),
      _: 1
    })
  ]))
}