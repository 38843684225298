import { MediaBoardCommentRef } from "@/model/media-board-comment-ref";
import { MediaBoardLikeRef } from "@/model/media-board-like-ref";
import { VvenueOpMode } from "@/services/backend/generated/model/vvenue-op-mode";
import { FirebaseService } from "@/services/firebase-service";
import { makeSafeFirebaseKey } from "./make-safe-firebase-key";

export type RefType = {
  on: (event: string, callback: (val: { val(): unknown; key: string | null }) => unknown) => unknown;
};

export enum ChangeEvents {
  FINISHED = "finished",
  UPDATE = "update",
  REMOVE = "remove"
}

/**
 * Subscribe to media board likes of Firebase real db
 * @param mediaBoardId: Media Board id
 * @param callback
 * @returns ref of subscription
 */
export function subscribeToMediaBoardLikesChanges(
  opMode: VvenueOpMode,
  mediaBoardId: string,
  callback: (event: ChangeEvents, value?: number, cpId?: string) => unknown
) {
  const ref = FirebaseService.rtdb().ref(createContentPieceLikesCountPath(opMode, mediaBoardId));

  subscribeToFirebaseRefs(ref as unknown as RefType, (event: ChangeEvents, value?: unknown, key?: string) =>
    callback(event, isNaN(Number(value)) ? undefined : Number(value), key)
  );

  return ref;
}

/**
 * Subscribe to content piece likes of Firebase real db
 * @param contentPieceId: Content piece id
 * @param callback
 * @returns ref of subscription
 */
export function subscribeToContentPieceLikesChanges(
  opMode: VvenueOpMode,
  contentPieceId: string,
  callback: (event: ChangeEvents, value?: MediaBoardLikeRef, key?: string) => unknown
) {
  const ref = FirebaseService.rtdb()
    .ref(createLikesPath(opMode))
    .orderByChild("contentPieceId")
    .equalTo(contentPieceId)
    .limitToLast(10_000_000);

  subscribeToFirebaseRefs(ref as unknown as RefType, (event: ChangeEvents, value?: unknown, key?: string) =>
    callback(event, value as MediaBoardLikeRef, key)
  );

  return ref;
}

/**
 * Subscribe to content piece comments of Firebase real db
 * @param contentPieceId: Content piece id
 * @param callback
 * @returns ref of subscription
 */
export function subscribeToContentPieceCommentsChanges(
  opMode: VvenueOpMode,
  contentPieceId: string,
  callback: (event: ChangeEvents, value?: MediaBoardCommentRef, key?: string) => unknown
) {
  const ref = FirebaseService.rtdb()
    .ref(createCommentsPath(opMode))
    .orderByChild("contentPieceId")
    .equalTo(contentPieceId)
    .limitToLast(10_000_000);

  subscribeToFirebaseRefs(ref as unknown as RefType, (event: ChangeEvents, value?: unknown, key?: string) =>
    callback(event, value as MediaBoardCommentRef, key)
  );

  return ref;
}

/**
 * Comment to put in Firebase realtime db
 * @param comment
 */
export async function putComment(opMode: VvenueOpMode, comment: MediaBoardCommentRef) {
  const commentRef = FirebaseService.rtdb().ref(createCommentPath(opMode, comment));
  await commentRef.set(comment);
}

/**
 * Comment to remove from Firebase real time d
 * @param comment
 */
export async function removeComment(opMode: VvenueOpMode, comment: MediaBoardCommentRef) {
  const commentRef = FirebaseService.rtdb().ref(createCommentPath(opMode, comment));
  await commentRef.remove();
}

export function createLikesPath(opMode: VvenueOpMode): string {
  return `contentPieces/opmode/${opMode}/likes`;
}

function createCommentsPath(opMode: VvenueOpMode): string {
  return `contentPieces/opmode/${opMode}/comments`;
}

function subscribeToFirebaseRefs(
  ref: RefType,
  callback: (event: ChangeEvents, value?: unknown, key?: string) => unknown
) {
  ref.on("child_added", (val) => callback(ChangeEvents.UPDATE, val.val(), val.key || undefined));
  ref.on("child_changed", (val) => callback(ChangeEvents.UPDATE, val.val(), val.key || undefined));
  ref.on("child_removed", (val) => callback(ChangeEvents.REMOVE, val.val(), val.key || undefined));
  ref.on("value", () => callback(ChangeEvents.FINISHED));
}

function createCommentPath(opMode: VvenueOpMode, comment: MediaBoardCommentRef) {
  if (!comment.uuid) {
    throw new Error("uuid must be set");
  }

  return `${createCommentsPath(opMode)}/${comment.uuid}`;
}

function createContentPieceLikesCountPath(opMode: VvenueOpMode, mediaBoardId: string): string {
  return `contentPieces/opmode/${opMode}/contentPieceLikesCount/${makeSafeFirebaseKey(mediaBoardId)}`;
}

export function createContentPieceLikeCountPath(opMode: VvenueOpMode, like: MediaBoardLikeRef) {
  return `${createContentPieceLikesCountPath(opMode, like.mediaBoardId)}/${makeSafeFirebaseKey(like.contentPieceId)}`;
}
