
import { defineComponent } from "vue";
import AppMain from "@/ui/components/AppMain.vue";
import AppHeader from "@/ui/components/AppHeader.vue";
import AppFooter from "@/ui/components/AppFooter.vue";
import { Style } from "@/services/backend/generated/model/style";
import { venueStoreService } from "@/store/module-services";
import { lightenColor } from "./utility/lighten-color";

export default defineComponent({
  name: "App",
  components: { AppFooter, AppHeader, AppMain },
  computed: {
    isLoginView(): boolean {
      return this.$route.name !== null && this.$route.name === "login";
    },
    needShowHeader(): boolean {
      return this.$route.name !== "WorkInProgress" && this.$route.name !== "AreaWherebyMeeting";
    },
    needShowFooter(): boolean {
      return !this.isLoginView && this.$route.name !== "AreaWherebyMeeting";
    },
    style(): Style {
      return venueStoreService.getStyle();
    }
  },
  watch: {
    /**
     * In order to update theme attributes like 'primary' we need to watch the computed property 'style' and apply
     * its values whenever it updates. Since this is a change that affects the whole application we do it here at
     * the root of the application.
     */
    style() {
      this.applyArchitectureStyle();
    }
  },
  methods: {
    applyArchitectureStyle(): void {
      this.$vuetify.theme.themes.dark.colors.primary = this.style.primary;
      this.$vuetify.theme.themes.dark.colors.secondary = this.style.secondary;
      this.$vuetify.theme.themes.dark.colors.background = this.style.background;
      this.$vuetify.theme.themes.dark.colors.background_lighten_2 = lightenColor(this.style.background, 10);
      this.$vuetify.theme.themes.dark.colors.text = this.style.text;
    }
  }
});
