import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withKeys as _withKeys, mergeProps as _mergeProps, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-40fe9498"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "align-self-center" }
const _hoisted_2 = { class: "text--text text-h6 font-weight-bold" }
const _hoisted_3 = {
  class: "ml-2 text--text",
  style: {"margin-top":"2px"}
}
const _hoisted_4 = {
  class: "text-subtitle-2 font-weight-bold small-lines text-truncate",
  style: {"max-width":"190px"}
}
const _hoisted_5 = { class: "text-caption small-lines font-weight-regular" }
const _hoisted_6 = {
  key: 0,
  class: "text-truncate",
  style: {"max-width":"190px"}
}
const _hoisted_7 = {
  key: 1,
  class: "text-truncate",
  style: {"max-width":"190px"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_avatar = _resolveComponent("v-avatar")!
  const _component_v_badge = _resolveComponent("v-badge")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_attendee_context_menu_content = _resolveComponent("attendee-context-menu-content")!
  const _component_base_context_menu = _resolveComponent("base-context-menu")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_base_context_menu, {
      modelValue: _ctx.isMenuOpenModel,
      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.isMenuOpenModel) = $event))
    }, {
      activator: _withCtx(({ props }) => [
        _createVNode(_component_v_card, _mergeProps({
          class: "px-2 py-1-5 attendee-card mx-1 d-flex flex-row align-stretch",
          "data-testid": `attendee-card[${_ctx.attendee.uid}]`
        }, props, {
          "min-height": _ctx.MIN_ATTENDEE_CARD_HEIGHT,
          "min-width": _ctx.MIN_ATTENDEE_CARD_WIDTH,
          tabindex: "0",
          onKeyup: _withKeys(props.onClick, ["enter"]),
          onBlur: _cache[0] || (_cache[0] = ($event: any) => {
            if (_ctx.isMenuOpen) {
              _ctx.isMenuOpen = false;
            }
          })
        }), {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
              _createVNode(_component_v_badge, {
                dot: "",
                bottom: "",
                bordered: "",
                color: "success",
                "offset-y": "32"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_avatar, {
                    size: "42",
                    class: "cursor-pointer",
                    color: "primary"
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.initials), 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.attendee.name), 1),
              _createElementVNode("div", _hoisted_5, [
                (_ctx.attendee.title)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString(_ctx.attendee.title), 1))
                  : _createCommentVNode("", true),
                (_ctx.attendee.company)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_7, _toDisplayString(_ctx.attendee.company), 1))
                  : _createCommentVNode("", true)
              ])
            ])
          ]),
          _: 2
        }, 1040, ["data-testid", "min-height", "min-width", "onKeyup"])
      ]),
      default: _withCtx(() => [
        _createVNode(_component_attendee_context_menu_content, {
          attendee: _ctx.attendee,
          "is-loading-attendee-profile": _ctx.isLoadingAttendeeProfile,
          onOpenProfileForAttendee: _ctx.openProfileForAttendee,
          onClose: _cache[1] || (_cache[1] = ($event: any) => (_ctx.isMenuOpen = false)),
          onStartChat: _ctx.startChat
        }, null, 8, ["attendee", "is-loading-attendee-profile", "onOpenProfileForAttendee", "onStartChat"])
      ]),
      _: 1
    }, 8, ["modelValue"])
  ]))
}