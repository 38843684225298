// Tracking client factory

import TrackingClient from "@/backend/tracking/tracking-client";
import MatomoTrackingClient from "@/backend/tracking/matomo-tracking-client";

/**
 * Factory for tracking clients.
 */
export default class TrackingClientFactory {
  /**
   * Returns a new instance of a tracking client.
   *
   * @return An tracking client instance
   */
  static createTrackingClient(): TrackingClient {
    return new MatomoTrackingClient();
  }
}
