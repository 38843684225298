
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    modelValue: { type: Boolean, default: false }
  },
  computed: {
    active: {
      get(): boolean {
        return this.modelValue;
      },
      set(value: boolean): void {
        this.$emit("update:modelValue", value);
      }
    }
  }
});
