import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";
import { uiStoreService } from "@/store/module-services";

/**
 * An exception thrown if Firebase could not be initialized.
 */
export class FirebaseNotAvailableError extends Error {
  constructor(m: string) {
    super(m);
    // Set the prototype explicitly.
    Object.setPrototypeOf(this, FirebaseNotAvailableError.prototype);
  }
}

/**
 * Provides the Firebase application singleton.
 */
export class FirebaseService {
  private static app: firebase.app.App;

  /**
   * Returns the Firebase default application.
   *
   * @return {firebase.app.App} The Firebase default application.
   */
  static getDefaultApp(): firebase.app.App {
    if (!this.app) {
      this.app = this.initDefaultApp();
    }

    return this.app;
  }

  /**
   * Returns the authentication service of the default application.
   *
   * @return {Auth} The authentication service.
   */
  static auth(): firebase.auth.Auth {
    return this.getDefaultApp().auth();
  }

  /**
   * Returns the realtime database service of the default application.
   *
   * @return {Auth} The realtime database service.
   */
  static rtdb(): firebase.database.Database {
    return this.getDefaultApp().database();
  }

  /**
   * Initializes the Firebase default application.
   *
   * @return {firebase.app.App} The Firebase default application.
   * @private
   */
  private static initDefaultApp(): firebase.app.App {
    const uiConfig = uiStoreService.getUiConfig();
    if (!uiConfig || !uiConfig.firebase) {
      throw new FirebaseNotAvailableError("Failed to retrieve Firebase app configuration");
    }

    return firebase.initializeApp(uiConfig.firebase);
  }
}
