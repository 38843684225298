import sha1 from "sha1";
import LocalStorageService from "@/services/local-storage-service";
import { VvenueOpMode } from "@/services/backend/generated/model/vvenue-op-mode";
import { Piwik } from "@/matomo";

/**
 * The URL to the original matomo.php.
 */
export const trackerUrl = "/analytics/mgnanap";

/**
 * The URL to the original matomo.js.
 */
export const trackerScriptUrl = "/analytics/mgnanaj";

/**
 * The site ID of the venue in Matomo.
 */
export const trackerSiteId = 1;

/**
 * Returns the unique visitor ID that is persisted in the browser's local storage.
 * Generates and persists a new visitor ID if necessary.
 *
 * @return {string} The visitor ID.
 */
export function getPersistentVisitorId(): string {
  let visitorId: string | null = LocalStorageService.getVisitorId();
  if (visitorId === null) {
    visitorId = generateVisitorId();
    LocalStorageService.setVisitorId(visitorId);
  }

  return visitorId;
}

/**
 * Returns a unique visitor ID that will change with every call to this function!
 *
 * @param {string} userId The user ID (optional).
 * @return {string} The visitor ID.
 */
export function generateVisitorId(userId = "[anonymous]"): string {
  const args: string[] = [userId, navigator.userAgent ?? "", new Date().getTime().toString(), Math.random().toString()];
  return sha1(args.join(";"), {}).slice(0, 16);
}

/**
 * A type-guard for an initialized Matomo instance.
 *
 * @param piwik The window.Piwik instance.
 */
export function isInitialized(piwik: Piwik | undefined): boolean {
  return piwik !== undefined && piwik.initialized;
}

/**
 * Returns the personalized tracking URL for a successful login of a user.
 *
 * Note that we craft this tracking URL manually and explicitly do NOT use the standard
 * tracking service of Magnid, because we want to make this a single-use tracking URL,
 * that is not related to the remaining tracking requests. Otherwise, we can't ensure
 * that this personalized tracking request is not somehow relatable to the remaining
 * tracking requests which may be anonymized, if the user has not given consent to
 * personalized tracking.
 *
 * @param {string} loginCategory The category name for the login tracking event.
 * @param {string} loginAction The action name for the login tracking event.
 * @param {string} userId The matomo user ID, i.e., the email address.
 * @return {string} The personalized tracking URL for the user's successful login.
 */
export function getLoginTrackingUrl(loginCategory: string, loginAction: string, userId: string): string {
  const now = new Date();
  const visitorId = generateVisitorId(userId);
  const parameters: string = [
    // required
    `idsite=${trackerSiteId}`,
    "rec=1",
    // recommended
    `url=${encodeURIComponent(window.location.href)}`,
    `_id=${visitorId}`,
    `rand=${Math.random() * Number.MAX_SAFE_INTEGER}`,
    "apiv=1",
    // optional user info
    `urlref=${encodeURIComponent(window.document.referrer)}`,
    `uid=${encodeURIComponent(userId)}`,
    `h=${now.getHours()}`,
    `m=${now.getMinutes()}`,
    `s=${now.getSeconds()}`,
    // optional action info
    "ca=1",
    // optional event tracking info
    `e_c=${encodeURIComponent(loginCategory)}`,
    `e_a=${encodeURIComponent(loginAction)}`,
    // other parameters
    "send_image=0"
  ].join("&");

  return `${trackerUrl}?${parameters}`;
}

/**
 * Returns the category name used for the login tracking event.
 *
 * @param {VvenueOpMode|undefined} opMode The op-mode of the venue.
 * @return {string} The category name.
 */
export function getLoginCategory(opMode: VvenueOpMode | undefined): string {
  let category = "";
  switch (opMode) {
    case VvenueOpMode.STAGE:
      category += "Stage venue";
      break;
    case VvenueOpMode.LIVE:
      category += "Live venue";
      break;
    default:
      // includes undefined
      category += "Magnid venue";
      break;
  }
  return category;
}
