
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    modelValue: Boolean
  },
  computed: {
    needsTooltip(): boolean {
      return !!this.$slots.tooltip;
    }
  }
});
