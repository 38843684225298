import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-660c03f2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "d-flex flex-column mr-1 mg-search-bar" }
const _hoisted_2 = { class: "search-button-container flex-fill align-end" }
const _hoisted_3 = { class: "flex-fill search-button-text" }
const _hoisted_4 = { class: "flex-fill search-button-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_base_toggle_button = _resolveComponent("base-toggle-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_v_text_field, {
      modelValue: _ctx.searchTermCp,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.searchTermCp) = $event)),
      color: "primary",
      variant: "underlined",
      dense: "",
      clearable: "",
      class: "text--text flex-grow-0 elevation-2 mb-1",
      label: _ctx.$t('presence.widget.search'),
      "hide-details": "",
      "prepend-inner-icon": "mdi-magnify",
      onBlur: _ctx.trackFilterByText
    }, null, 8, ["modelValue", "label", "onBlur"]),
    _createElementVNode("div", _hoisted_2, [
      (_ctx.areaId)
        ? (_openBlock(), _createBlock(_component_base_toggle_button, {
            key: 0,
            modelValue: _ctx.areaFilterCp,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.areaFilterCp) = $event)),
            "data-testid": "button-filter-area",
            class: "flex-fill",
            size: "x-small"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_icon, { left: "" }, {
                default: _withCtx(() => [
                  _createTextVNode("mdi-filter")
                ]),
                _: 1
              }),
              _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.$t("presence.widget.filter.area")), 1)
            ]),
            _: 1
          }, 8, ["modelValue"]))
        : _createCommentVNode("", true),
      (_ctx.hasCompany)
        ? (_openBlock(), _createBlock(_component_base_toggle_button, {
            key: 1,
            modelValue: _ctx.companyFilterCp,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.companyFilterCp) = $event)),
            "data-testid": "button-filter-company",
            class: "flex-fill",
            size: "x-small",
            onClick: _ctx.trackFilterByCompany
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_icon, { left: "" }, {
                default: _withCtx(() => [
                  _createTextVNode("mdi-filter")
                ]),
                _: 1
              }),
              _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.$t("presence.widget.filter.company")), 1)
            ]),
            _: 1
          }, 8, ["modelValue", "onClick"]))
        : _createCommentVNode("", true)
    ])
  ]))
}