import { normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_tooltip = _resolveComponent("v-tooltip")!
  const _component_v_menu = _resolveComponent("v-menu")!

  return (_openBlock(), _createBlock(_component_v_menu, {
    "model-value": _ctx.modelValue,
    "offset-y": "",
    transition: "slide-y-transition",
    "close-on-content-click": false
  }, {
    activator: _withCtx(({ props: menu }) => [
      _createVNode(_component_v_tooltip, {
        location: "bottom",
        disabled: !_ctx.needsTooltip
      }, {
        activator: _withCtx(({ props: tooltip }) => [
          _renderSlot(_ctx.$slots, "activator", _normalizeProps(_guardReactiveProps({ props: { ...menu, ...tooltip } })))
        ]),
        default: _withCtx((scope) => [
          _renderSlot(_ctx.$slots, "tooltip", _normalizeProps(_guardReactiveProps(scope)))
        ]),
        _: 2
      }, 1032, ["disabled"])
    ]),
    default: _withCtx((scope) => [
      _renderSlot(_ctx.$slots, "default", _normalizeProps(_guardReactiveProps(scope)))
    ]),
    _: 3
  }, 8, ["model-value"]))
}