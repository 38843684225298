import LocalStorageService from "@/services/local-storage-service";
import { userStoreService, venueStoreService } from "@/store/module-services";
import { CookieBannerType } from "@/services/backend/generated/model/cookie-banner-type";

export async function copyLocalStorageCookieProviders(): Promise<void> {
  const noBanner = venueStoreService.getCookieBannerConfig()?.type === CookieBannerType.NONE;

  // check for every provider if there is already a permission value stored in the local storage, if so,
  // use it to set the value in the store and in the data-pendant of the local storage cookies
  for (const cp of venueStoreService.getCookieProviders()) {
    const permission = LocalStorageService.getRawCookiePermission(cp.id);
    if (permission) {
      // ensure that we set all providers to accepted, if we have no banner
      const accepted = noBanner || permission === "true";
      await userStoreService.setLocalStorageCookieProvider({
        id: cp.id,
        accepted
      });
      await venueStoreService.setAccepted({ ...cp, accepted });
    }
  }
  await userStoreService.readStoredConsents();
}
