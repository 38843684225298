// Matomo tracking client

import TrackingClient from "@/backend/tracking/tracking-client";
import { Matomo, Piwik, TrackEventResponse } from "@/matomo";
import delay from "@/utility/delay";
import axios, { AxiosInstance } from "axios";
import { isInitialized, getLoginTrackingUrl, getLoginCategory } from "@/backend/tracking/matomo-tracking-utils";
import { VvenueOpMode } from "@/services/backend/generated/model/vvenue-op-mode";
import { backOff } from "exponential-backoff";
import { getConfig, attach } from "retry-axios";

/**
 * The action name used for the login tracking event.
 */
const LOGIN_ACTION = "Login";
const RETRY_ATTEMPTS = 10;
const RETRY_DELAY_MS = 2000;

/**
 *
 */
export default class MatomoTrackingClient implements TrackingClient {
  /**
   * Return the internal tracker instance of the Matomo plugin.
   *
   * @return Matomo tracker instance
   */
  private static async getTracker(): Promise<Matomo> {
    let piwik: Piwik | undefined = window.Piwik;

    const interval = 100;
    while (!isInitialized(piwik)) {
      await delay(interval);
      piwik = window.Piwik;
    }

    return piwik.getAsyncTracker();
  }

  /**
   * Executes the given function with the internal tracker instance of the Matomo plugin.
   *
   * @param fn The function to execute.
   * @return {T} The return value of the function.
   * @private
   */
  private static async withTracker<T>(fn: (tracker: Matomo) => T): Promise<T> {
    return MatomoTrackingClient.getTracker()?.then(fn);
  }

  /**
   * Tracks an event.
   *
   * @param category The category of the event
   * @param action The action of the event
   * @param name The name of the event
   * @param value The value of the event
   */
  async trackEvent(category: string, action: string, name?: string, value?: number): Promise<void> {
    MatomoTrackingClient.logInfo("trackEvent", {
      category,
      action,
      name,
      value
    });
    await MatomoTrackingClient.withTracker(async (tracker) => {
      await backOff(
        () => {
          return new Promise<TrackEventResponse | undefined>((resolve, reject) => {
            tracker.trackEvent(category, action, name, value, undefined, (response?: TrackEventResponse) => {
              if (response?.success === false) {
                reject(new Error("Track event failed"));
              }
              resolve(response);
            });
          });
        },
        {
          retry: (err: string, attempt: number) => {
            MatomoTrackingClient.logInfo("retrying trackEvent", {
              category,
              action,
              name,
              value,
              attempt
            });
            return !!err;
          },
          numOfAttempts: RETRY_ATTEMPTS,
          startingDelay: RETRY_DELAY_MS
        }
      ).catch(() => {
        console.error(`Track event failed after ${RETRY_ATTEMPTS} retries`);
      });
    });
  }

  private static logInfo(
    message: string,
    info: {
      category: string;
      action: string;
      name?: string;
      value?: number;
      userId?: string;
      attempt?: number;
    }
  ) {
    console.info(message, info);
  }

  /**
   * Tracks a successful login.
   *
   * @param opMode The venue op mode.
   * @param {string|undefined} emailAddress The email address for a personalized login or
   *            <pre>undefined</pre> for an anonymous login.
   */
  async trackLogin(opMode: VvenueOpMode | undefined, emailAddress: string | undefined): Promise<void> {
    const loginCategory = getLoginCategory(opMode);
    if (emailAddress !== undefined) {
      // if a user has opted out of personalized tracking, then we want to ensure that the personalized
      // tracking request of the login call can not be related to the future anonymous tracking requests
      // inside the venue; therefore, we use a manually crafted tracking URL
      const trackLoginUrl = getLoginTrackingUrl(loginCategory, LOGIN_ACTION, emailAddress);
      MatomoTrackingClient.logInfo("trackLogin", {
        category: loginCategory,
        action: LOGIN_ACTION,
        userId: emailAddress
      });
      // Wait for Matomo to be initialized first
      await MatomoTrackingClient.getTracker();
      await this.requestWithRetry(loginCategory, LOGIN_ACTION).get(trackLoginUrl);
    } else {
      // for anonymous venues, there is no personalized tracking, i.e., we can execute the tracking
      // request of the login call via the regular framework; we use Matomo's visitor ID as the
      // user ID, because it's a random non-personal ID that is persisted and somewhat reliably
      // allows identifying the logins of returning visitors
      await MatomoTrackingClient.withTracker((tracker) => {
        const visitorId = tracker.getVisitorId();
        MatomoTrackingClient.logInfo("trackLogin", {
          category: loginCategory,
          action: LOGIN_ACTION,
          userId: visitorId
        });
        tracker.setUserId(visitorId);
        tracker.trackEvent(loginCategory, LOGIN_ACTION);
      });
    }
  }

  /**
   * Resets the currently set user id.
   *
   * @return string The current user id
   */
  async resetUserId(): Promise<void> {
    await MatomoTrackingClient.withTracker((tracker) => tracker.resetUserId());
  }

  /**
   * Sets the matomo user id to given string, typically the email address
   *
   * @param userId The new user id to set
   */
  async setUserId(userId: string): Promise<void> {
    await MatomoTrackingClient.withTracker((tracker) => tracker.setUserId(userId));
  }

  async getUserId(): Promise<string> {
    return MatomoTrackingClient.withTracker((tracker) => tracker.getUserId());
  }

  async getVisitorId(): Promise<string> {
    return MatomoTrackingClient.withTracker((tracker) => tracker.getVisitorId());
  }

  private requestWithRetry(category: string, action: string): AxiosInstance {
    // Use a new axios instance to avoid triggering global error interceptor
    // and configure retry capability
    const axiosInstance = axios.create();
    axiosInstance.defaults.raxConfig = {
      instance: axiosInstance,
      retry: RETRY_ATTEMPTS,
      retryDelay: RETRY_DELAY_MS,
      onRetryAttempt: (err) => {
        const cfg = getConfig(err);
        const attempt = cfg?.currentRetryAttempt;
        MatomoTrackingClient.logInfo("retrying trackLogin", {
          category,
          action,
          attempt
        });
      }
    };
    attach(axiosInstance);
    return axiosInstance;
  }
}
