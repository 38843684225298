import { RootState } from "@/store/RootState";
import { ProfileStateType, UserState } from "@/store/modules/user/UserState";
import { TypedGetterFunctions } from "@/store/framework/TypedGetterFunctions";
import { LocalStorageCookieProvider } from "@/model/local-storage-cookie-provider";
import { UserInfo } from "@/model/user-info";
import { TypeImprovedAttendeeProfile } from "@/model/type-improved-attendee-profile";

/**
 * Defines all properties on the getters object that can be read from when working with the user store slice.
 */
export interface UserGetters {
  isLoggedIn: boolean;
  getUser: UserInfo | undefined;
  getAuthToken: string | undefined;
  isTrackingConsentGiven: boolean;
  getLocalStorageCookieProviders: LocalStorageCookieProvider[];
  isShowingOwnProfile: boolean;
  isLoadingAttendeeProfile: boolean;
  getAttendeeIdToLoad: string | undefined;
  isShowingAttendeeProfile: boolean;
  getAttendeeProfileToShow: TypeImprovedAttendeeProfile | undefined;
}

/**
 * Getter method implementations in the user store slice.
 */
export const userGetters: TypedGetterFunctions<UserState, RootState, UserGetters> = {
  isLoggedIn: (state): boolean => state.isLoggedIn,

  getUser: (state): UserInfo | undefined => state.user,

  getAuthToken: (state): string | undefined => state.authToken,

  isTrackingConsentGiven: (state): boolean => state.trackingConsentGiven ?? false,

  getLocalStorageCookieProviders: (state): LocalStorageCookieProvider[] => state.localStorageCookieProviders,

  isShowingOwnProfile: (state): boolean => state.profileState.type === ProfileStateType.ShowOwnProfile,

  isLoadingAttendeeProfile: (state): boolean => state.profileState.type === ProfileStateType.LoadingAttendeeProfile,

  getAttendeeIdToLoad: (state): string | undefined =>
    state.profileState.type === ProfileStateType.LoadingAttendeeProfile ? state.profileState.userId : undefined,

  isShowingAttendeeProfile: (state): boolean => state.profileState.type === ProfileStateType.ShowAttendeeProfile,

  getAttendeeProfileToShow: (state): TypeImprovedAttendeeProfile | undefined =>
    state.profileState.type === ProfileStateType.ShowAttendeeProfile ? state.profileState.profile : undefined
};
