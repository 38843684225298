import { TypedAction } from "@/store/framework/TypedAction";
import { CookieBannerState } from "@/store/modules/cookieBanner/cookieBannerState";
import { RootState } from "@/store/RootState";
import { CookieBannerMutation } from "@/store/modules/cookieBanner/cookieBannerMutations";

export enum CookieBannerAction {
  setVisible = "setVisible",
  setNotificationVisible = "setNotificationVisible"
}

interface CookieBannerActions {
  [CookieBannerAction.setVisible]: boolean;
  [CookieBannerAction.setNotificationVisible]: boolean;
}

type CookieBannerActionFunctions = {
  [name in keyof CookieBannerActions]: TypedAction<CookieBannerState, RootState, void, CookieBannerActions[name]>;
};

export const cookieBannerActions: CookieBannerActionFunctions = {
  [CookieBannerAction.setVisible]({ commit }, value: boolean): void {
    commit(CookieBannerMutation.SET_VISIBLE, value);
  },

  [CookieBannerAction.setNotificationVisible]({ commit }, value: boolean): void {
    commit(CookieBannerMutation.SET_NOTIFICATION_VISIBLE, value);
  }
};
