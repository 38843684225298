
import { defineComponent, PropType } from "vue";
import AttendeeList from "@/ui/components/presence-widget/AttendeeList.vue";
import { AttendeeRef } from "@/model/attendee-ref";
import SearchBar from "@/ui/components/presence-widget/SearchBar.vue";
import { DebouncedValue } from "@/utility/debounced-value";

export default defineComponent({
  components: { SearchBar, AttendeeList },
  emits: ["update:areaFilter", "update:companyFilter", "update:searchTerm", "open-profile-for-attendee", "start-chat"],
  props: {
    attendeeList: {
      type: Array as PropType<Array<AttendeeRef>>
    },
    areaFilter: {
      type: Boolean,
      required: true
    },
    companyFilter: {
      type: Boolean,
      required: true
    },
    areaId: {
      type: String
    },
    searchTerm: {
      type: String
    },
    isLoadingAttendeeProfile: Boolean,
    attendeeIdToLoad: String,
    attendeeUpdateDebouncer: {
      type: Object as PropType<DebouncedValue<AttendeeRef[]>>,
      required: true
    }
  }
});
