
import { defineComponent } from "vue";
import TheLanguageSelection from "@/components/TheLanguageSelection.vue";
import { UserService } from "@/services/user-service";
import { uiStoreService, userStoreService, venueStoreService } from "@/store/module-services";
import HomeButton from "@/ui/components/HomeButton.vue";
import { Style } from "@/services/backend/generated/model/style";
import AppDrawer from "@/ui/components/AppDrawer.vue";
import PresenceWidgetContainer from "@/ui/components/presence-widget/PresenceWidgetContainer.vue";
import UserPreviewContainer from "@/ui/components/user-preview/UserPreviewContainer.vue";
import { APP_BAR_HEIGHT_FOR_PRESENCE } from "@/ui/components/presence-widget/constants";
import { APP_BAR_HEIGHT } from "@/ui/components/constants";
import { MandatoryPagesEnum } from "@/model/mandatory-pages";

export default defineComponent({
  components: {
    UserPreviewContainer,
    AppDrawer,
    PresenceWidgetContainer,
    HomeButton,
    TheLanguageSelection
  },
  data() {
    return {
      APP_BAR_HEIGHT,
      APP_BAR_HEIGHT_FOR_PRESENCE,
      drawer: false,
      presenceReady: false
    };
  },
  computed: {
    isMobile(): boolean {
      return this.$vuetify.display.mobile;
    },
    showLogoutButton(): boolean {
      return !!userStoreService.isLoggedIn();
    },
    architectureId(): string | undefined {
      return venueStoreService.getArchitectureId();
    },
    architectureStyle(): Style {
      return venueStoreService.getStyle();
    },
    isLoggedIn(): boolean {
      return userStoreService.isLoggedIn();
    },
    prominentAppBar(): boolean {
      // only presence widget needs large app bar
      return this.presenceReady && !this.isMobile;
    },
    backgroundColor(): string {
      // we need a non-transparent header background when displaying the privacy policy
      if (
        this.$route.name?.toString().includes(MandatoryPagesEnum.PRIVACY_POLICY) ||
        this.$route.name?.toString().includes(MandatoryPagesEnum.IMPRINT)
      ) {
        return "background";
      }
      return "transparent";
    },
    heightValue(): number {
      return this.prominentAppBar ? APP_BAR_HEIGHT_FOR_PRESENCE : APP_BAR_HEIGHT;
    }
  },
  mounted(): void {
    changeAppHeaderHeight(this.heightValue);
  },
  methods: {
    async logOut() {
      await UserService.logOut();
    }
  },
  watch: {
    heightValue(value: number) {
      changeAppHeaderHeight(value);
    }
  }
});

function changeAppHeaderHeight(value: number): void {
  uiStoreService.setHeaderHeight(value);
}
