export function lightenColor(color: string, percent: number): string {
  const parsedColor = color.replace("#", "");
  let r = "";
  let g = "";
  let b = "";

  const coef = percent / 100;

  if (parsedColor.length === 3) {
    r = parsedColor[0] + parsedColor[0];
    g = parsedColor[1] + parsedColor[1];
    b = parsedColor[2] + parsedColor[2];
  } else if (parsedColor.length === 6) {
    r = parsedColor[0] + parsedColor[1];
    g = parsedColor[2] + parsedColor[3];
    b = parsedColor[4] + parsedColor[5];
  } else {
    throw Error("Length of hex color string can be only 4 or 7 symbols");
  }

  const rLightenInt = Math.round(parseInt(r, 16) + 255 * coef);
  const gLightenInt = Math.round(parseInt(g, 16) + 255 * coef);
  const bLightenInt = Math.round(parseInt(b, 16) + 255 * coef);

  return `#${toHex(rLightenInt)}${toHex(gLightenInt)}${toHex(bLightenInt)}`;
}

function toHex(intColorNumber: number): string {
  const colorNumberString = `${intColorNumber > 255 ? "ff" : intColorNumber.toString(16)}`;

  return colorNumberString.length === 2 ? colorNumberString : `0${colorNumberString}`;
}
