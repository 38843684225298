import { LocalizedText } from "@/services/backend/generated/model/localized-text";
import { venueStoreService } from "@/store/module-services";
import { fromLocalizedText } from "@/i18n/localized-string";

/**
 * Returns the area name of the given area ID.
 *
 * @param {string | undefined} areaId The area ID.
 * @return {string} The area name in the default locale.
 * @private
 */
export function getAreaName(areaId: string | undefined): string {
  if (areaId === undefined) {
    return "";
  } else {
    const areaNames: LocalizedText[] = venueStoreService.getAreaById(areaId)?.names ?? [];
    return fromLocalizedText(areaNames); // return area name in default locale!!
  }
}
