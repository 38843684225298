import { ProfileState, UserState } from "@/store/modules/user/UserState";
import { TypedMutation } from "@/store/framework/TypedMutation";
import { LocalStorageCookieProvider } from "@/model/local-storage-cookie-provider";
import { UserInfo } from "@/model/user-info";

/**
 * Defines all mutation names in the user store slice.
 */
export enum UserMutation {
  SET_LOGGED_IN = "SET_LOGGED_IN",
  SET_LOGGED_OUT = "SET_LOGGED_OUT",
  SET_USER = "SET_USER",
  SET_AUTH_TOKEN = "SET_AUTH_TOKEN",
  SET_TRACKING_CONSENT_GIVEN = "SET_TRACKING_CONSENT_GIVEN",
  SET_LOCAL_STORAGE_COOKIE_PROVIDER = "SET_LOCAL_STORAGE_COOKIE_PROVIDER",
  SET_PROFILE_STATE = "SET_PROFILE_STATE"
}
/**
 * Defines the mutation types in the user store slice.
 */
interface UserMutations {
  [UserMutation.SET_LOGGED_IN]: void;
  [UserMutation.SET_LOGGED_OUT]: void;
  [UserMutation.SET_USER]: UserInfo | undefined;
  [UserMutation.SET_AUTH_TOKEN]: string | undefined;
  [UserMutation.SET_TRACKING_CONSENT_GIVEN]: boolean;
  [UserMutation.SET_LOCAL_STORAGE_COOKIE_PROVIDER]: LocalStorageCookieProvider;
  [UserMutation.SET_PROFILE_STATE]: ProfileState;
}
/**
 * Defines types of all mutation functions in the user store slice.
 */
type UserMutationFunctions = {
  [name in keyof UserMutations]: TypedMutation<UserState, UserMutations[name]>;
};
/**
 * Mutation method implementations in the user store slice.
 */
export const userMutations: UserMutationFunctions = {
  [UserMutation.SET_LOGGED_IN](state): void {
    state.isLoggedIn = true;
  },
  [UserMutation.SET_LOGGED_OUT](state): void {
    state.isLoggedIn = false;
  },
  [UserMutation.SET_USER](state, user: UserInfo | undefined): void {
    state.user = user;
  },
  [UserMutation.SET_AUTH_TOKEN](state, authToken: string | undefined): void {
    state.authToken = authToken;
  },
  [UserMutation.SET_TRACKING_CONSENT_GIVEN](state, consent: boolean): void {
    state.trackingConsentGiven = consent;
  },
  [UserMutation.SET_LOCAL_STORAGE_COOKIE_PROVIDER](state, provider: LocalStorageCookieProvider): void {
    // Setting a local storage cookie provider means that we check if it is already registered in the store-pendant
    // of the local storage cookies, if so, we update the value, if not, we push a new entry for it.
    const index = state.localStorageCookieProviders.findIndex((c) => c.id === provider.id);
    if (index === -1) {
      state.localStorageCookieProviders.push(provider);
    } else {
      state.localStorageCookieProviders[index].accepted = provider.accepted;
    }
  },
  [UserMutation.SET_PROFILE_STATE](state, profileState: ProfileState): void {
    state.profileState = profileState;
  }
};
