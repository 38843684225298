import capitalize from "lodash/capitalize";

export function normalizeVimeoUrl(url: string): string {
  const vimeoUrlRegex = /^(http[s]?:\/\/)?vimeo\.com\//;
  return url.replace(vimeoUrlRegex, "https://player.vimeo.com/video/");
}

export function generateUserNameFromEmail(userEmail: string): string {
  return (
    userEmail
      // Discard everything after the at sign, as well as any non-alphanumeric and underscores
      ?.split(/@.+|[\W_]+/u)
      // Remove any empty strings
      .filter(Boolean)
      // Capitalize each word
      .map(capitalize)
      // Join the words with spaces
      .join(" ")
  );
}
