import { Venue } from "@/services/backend/generated/model/venue";
import { VenueState } from "@/store/modules/venue/VenueState";
import { TypedMutation } from "@/store/framework/TypedMutation";
import { FrontendCookieProvider, toFrontendCookieProvider } from "@/model/frontend-cookie-provider";
import { CookieProviderType } from "@/services/backend/generated/model/cookie-provider-type";
import { setAccepted } from "@/store/modules/venue/venueMutationUtilities";
import { CookieBannerType } from "@/services/backend/generated/model/cookie-banner-type";

/**
 * Defines all mutation names in the venue store slice.
 */
export enum VenueMutation {
  SET_VENUE = "SET_VENUE",
  SET_ACCEPTED = "SET_ACCEPTED",
  SET_ALL_EXTERNAL_MEDIA_ACCEPTED = "SET_ALL_EXTERNAL_MEDIA_ACCEPTED",
  ACCEPT_ALL_PROVIDERS = "ACCEPT_ALL_PROVIDERS"
}

/**
 * Defines the mutation types in the venue store slice.
 */
interface VenueMutations {
  [VenueMutation.SET_VENUE]: Venue;
  [VenueMutation.SET_ACCEPTED]: FrontendCookieProvider;
  [VenueMutation.SET_ALL_EXTERNAL_MEDIA_ACCEPTED]: boolean;
  [VenueMutation.ACCEPT_ALL_PROVIDERS]: undefined;
}

/**
 * Defines types of all mutation functions in the venue store slice.
 */
type VenueMutationFunctions = {
  [name in keyof VenueMutations]: TypedMutation<VenueState, VenueMutations[name]>;
};

/**
 * Mutation method implementations in the venue store slice.
 */
export const venueMutations: VenueMutationFunctions = {
  [VenueMutation.SET_VENUE](state: VenueState, venue: Venue | undefined) {
    state.venue = venue;

    if (venue) {
      if (venue.architectures.length !== 1) {
        throw new Error("The venue must include exactly one architecture");
      }
      state.selectedArchitectureId = venue.architectures[0].id;

      // if the banner type is NONE, we want all providers to be accepted by default,
      // otherwise they should not be accepted
      const noBanner = venue.architectures[0]?.cookieBannerConfig?.type === CookieBannerType.NONE;
      state.cookieProviders = venue.architectures[0].cookieProviders.map((cp) =>
        toFrontendCookieProvider(cp, noBanner)
      );
      // sort cookies backwards by type so that technically required ones are at the top
      state.cookieProviders.sort((a, b) => (a.type > b.type ? -1 : 1));
    } else {
      state.selectedArchitectureId = undefined;
    }
  },

  [VenueMutation.SET_ACCEPTED](state: VenueState, provider: FrontendCookieProvider) {
    setAccepted(state, (cp) => cp.id === provider.id, provider.accepted);
  },

  [VenueMutation.SET_ALL_EXTERNAL_MEDIA_ACCEPTED](state: VenueState, value: boolean) {
    setAccepted(state, (cp) => cp.type === CookieProviderType.EXTERNAL_MEDIA, value);
  },

  [VenueMutation.ACCEPT_ALL_PROVIDERS](state: VenueState) {
    setAccepted(state, () => true, true);
  }
};
