/**
 * Extracts the initials out of a name. E.g. Rick Sanchez -> RS. It also handles middle names by ignoring them.
 * E.g. Mortimer Mathias Bixby -> MB
 *
 * @param name the original name
 * @return a string only containing the initials
 */
export default function getInitials(name: string | undefined): string {
  // return an empty string if no value is given
  if (!name) {
    return "";
  }

  // else split the name, and take the characters of the first and last name
  const names = name.split(" ");
  let initials = names[0].substring(0, 1).toUpperCase();

  if (names.length > 1) {
    initials += names[names.length - 1].substring(0, 1).toUpperCase();
  }
  return initials;
}
