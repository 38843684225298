import { CookieProvider } from "@/services/backend/generated/model/cookie-provider";
import { CookieProviderType } from "@/services/backend/generated/model/cookie-provider-type";
import LocalStorageService from "@/services/local-storage-service";

/**
 * Frontend version of the {@link CookieProvider}. It holds the additional info if a provider is accepted
 */
export interface FrontendCookieProvider extends CookieProvider {
  accepted: boolean;
}

/**
 * Converts a {@link CookieProvider} to a {@link FrontendCookieProvider}, by adding the necessary accepted field
 * @param provider          the provider
 * @param defaultPermission the default permission
 */
export function toFrontendCookieProvider(provider: CookieProvider, defaultPermission = false): FrontendCookieProvider {
  return {
    // by default all technically required providers are accepted
    // for other providers we check if we already have an value for this provider in the local storage,
    // if not, we take the defaultPermission
    accepted:
      provider.type === CookieProviderType.TECHNICALLY_REQUIRED ||
      LocalStorageService.getRawCookiePermission(provider.id) === "true" ||
      defaultPermission,
    ...provider
  };
}
