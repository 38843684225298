/* tslint:disable */
/* eslint-disable */
/**
 * VVenue Services API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 53
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */

export enum ViewMode {
    _2D = 'VIEW_2D',
    _360D = 'VIEW_360D'
}


