/* tslint:disable */
/* eslint-disable */
/**
 * VVenue Services API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 53
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ApiError } from '../model';
// @ts-ignore
import { AttendeeProfile } from '../model';
/**
 * UserProfileControllerApi - axios parameter creator
 * @export
 */
export const UserProfileControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Fetches a UserProfile with attributes filtered by the currently logged-in user\'s access privileges.
         * @summary Returns the userProfile for the given userId
         * @param {string} architectureId 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProfile: async (architectureId: string, userId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'architectureId' is not null or undefined
            assertParamExists('getProfile', 'architectureId', architectureId)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getProfile', 'userId', userId)
            const localVarPath = `/userProfile/architecture/{architectureId}/userProfile/{userId}`
                .replace(`{${"architectureId"}}`, encodeURIComponent(String(architectureId)))
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Overrides the given userProfile attributes if the UserProfile exists and the currently logged in user has enough access privileges. Returns the updated userProfile with attributes filtered by the currently logged in users access priviledges.
         * @summary Update userProfile
         * @param {string} architectureId 
         * @param {AttendeeProfile} attendeeProfile 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setProfile: async (architectureId: string, attendeeProfile: AttendeeProfile, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'architectureId' is not null or undefined
            assertParamExists('setProfile', 'architectureId', architectureId)
            // verify required parameter 'attendeeProfile' is not null or undefined
            assertParamExists('setProfile', 'attendeeProfile', attendeeProfile)
            const localVarPath = `/userProfile/architecture/{architectureId}/userProfile`
                .replace(`{${"architectureId"}}`, encodeURIComponent(String(architectureId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(attendeeProfile, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserProfileControllerApi - functional programming interface
 * @export
 */
export const UserProfileControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserProfileControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * Fetches a UserProfile with attributes filtered by the currently logged-in user\'s access privileges.
         * @summary Returns the userProfile for the given userId
         * @param {string} architectureId 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProfile(architectureId: string, userId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AttendeeProfile>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProfile(architectureId, userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Overrides the given userProfile attributes if the UserProfile exists and the currently logged in user has enough access privileges. Returns the updated userProfile with attributes filtered by the currently logged in users access priviledges.
         * @summary Update userProfile
         * @param {string} architectureId 
         * @param {AttendeeProfile} attendeeProfile 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setProfile(architectureId: string, attendeeProfile: AttendeeProfile, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AttendeeProfile>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setProfile(architectureId, attendeeProfile, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserProfileControllerApi - factory interface
 * @export
 */
export const UserProfileControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserProfileControllerApiFp(configuration)
    return {
        /**
         * Fetches a UserProfile with attributes filtered by the currently logged-in user\'s access privileges.
         * @summary Returns the userProfile for the given userId
         * @param {string} architectureId 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProfile(architectureId: string, userId: string, options?: any): AxiosPromise<AttendeeProfile> {
            return localVarFp.getProfile(architectureId, userId, options).then((request) => request(axios, basePath));
        },
        /**
         * Overrides the given userProfile attributes if the UserProfile exists and the currently logged in user has enough access privileges. Returns the updated userProfile with attributes filtered by the currently logged in users access priviledges.
         * @summary Update userProfile
         * @param {string} architectureId 
         * @param {AttendeeProfile} attendeeProfile 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setProfile(architectureId: string, attendeeProfile: AttendeeProfile, options?: any): AxiosPromise<AttendeeProfile> {
            return localVarFp.setProfile(architectureId, attendeeProfile, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserProfileControllerApi - object-oriented interface
 * @export
 * @class UserProfileControllerApi
 * @extends {BaseAPI}
 */
export class UserProfileControllerApi extends BaseAPI {
    /**
     * Fetches a UserProfile with attributes filtered by the currently logged-in user\'s access privileges.
     * @summary Returns the userProfile for the given userId
     * @param {string} architectureId 
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserProfileControllerApi
     */
    public getProfile(architectureId: string, userId: string, options?: AxiosRequestConfig) {
        return UserProfileControllerApiFp(this.configuration).getProfile(architectureId, userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Overrides the given userProfile attributes if the UserProfile exists and the currently logged in user has enough access privileges. Returns the updated userProfile with attributes filtered by the currently logged in users access priviledges.
     * @summary Update userProfile
     * @param {string} architectureId 
     * @param {AttendeeProfile} attendeeProfile 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserProfileControllerApi
     */
    public setProfile(architectureId: string, attendeeProfile: AttendeeProfile, options?: AxiosRequestConfig) {
        return UserProfileControllerApiFp(this.configuration).setProfile(architectureId, attendeeProfile, options).then((request) => request(this.axios, this.basePath));
    }
}
