import { RootState } from "@/store/RootState";
import axios from "axios";
import { VersionApi } from "@/services/backend-service";
import { VenueVersion } from "@/services/backend/generated/model/venue-version";
import { LoginConfiguration } from "@/services/backend/generated/model/login-configuration";
import { ErrorCode, UIConfigState, UiState } from "@/store/modules/ui/UiState";
import { UiMutation } from "@/store/modules/ui/uiMutations";
import { TypedAction } from "@/store/framework/TypedAction";
import { UiGetters } from "@/store/modules/ui/uiGetters";

/**
 * Defines all action names in the ui store slice.
 */
export enum UiAction {
  loadUiConfig = "loadUiConfig",
  setUiConfig = "setUiConfig",
  loadVenueVersion = "loadVenueVersion",
  setVenueVersion = "setVenueVersion",
  setLoginEnabled = "setLoginEnabled",
  setLoginConfigurations = "setLoginConfigurations",
  setHeaderHeight = "setHeaderHeight",
  setLastAreaId = "setLastAreaId",
  clearError = "clearError",
  setError = "setError",
  suppressDefaultErrorDialog = "suppressDefaultErrorDialog",
  enableDefaultErrorDialog = "enableDefaultErrorDialog",
  setServerTime = "setServerTime",
  serverTimeTick = "serverTimeTick",
  addToActiveDialogStack = "addToActiveDialogStack",
  removeFromActiveDialogStack = "removeFromActiveDialogStack",
  popFromActiveDialogStack = "popFromActiveDialogStack",
  openChat = "openChat",
  closeChat = "closeChat",
  expandChat = "expandChat",
  minimizeChat = "minimizeChat",
  openChatWithRoom = "openChatWithRoom",
  addNavigationGuard = "addNavigationGuard",
  removeNavigationGuard = "removeNavigationGuard",
  beginGuardedNavigation = "beginGuardedNavigation",
  completeGuardedNavigation = "completeGuardedNavigation",
  setAttendeeToChatUid = "setAttendeeToChatUid",
  waitRouterGuards = "waitRouterGuards",
  routerGuardsFinished = "routerGuardsFinished"
}

/**
 * Defines the action types in the ui store slice.
 */
interface UiActions {
  [UiAction.loadUiConfig]: void;
  [UiAction.setUiConfig]: UIConfigState;
  [UiAction.loadVenueVersion]: void;
  [UiAction.setVenueVersion]: VenueVersion;
  [UiAction.setLoginEnabled]: boolean;
  [UiAction.setLoginConfigurations]: LoginConfiguration[];
  [UiAction.setHeaderHeight]: number;
  [UiAction.setLastAreaId]: string;
  [UiAction.clearError]: void;
  [UiAction.setError]: { code: ErrorCode; message: string };
  [UiAction.suppressDefaultErrorDialog]: void;
  [UiAction.enableDefaultErrorDialog]: void;
  [UiAction.setServerTime]: Date;
  [UiAction.serverTimeTick]: number;
  [UiAction.addToActiveDialogStack]: Record<string, unknown>;
  [UiAction.removeFromActiveDialogStack]: Record<string, unknown>;
  [UiAction.popFromActiveDialogStack]: void;
  [UiAction.openChat]: void;
  [UiAction.closeChat]: void;
  [UiAction.expandChat]: void;
  [UiAction.minimizeChat]: void;
  [UiAction.openChatWithRoom]: { room: string; chats: string[] };
  [UiAction.addNavigationGuard]: string;
  [UiAction.removeNavigationGuard]: string;
  [UiAction.beginGuardedNavigation]: void;
  [UiAction.completeGuardedNavigation]: void;
  [UiAction.setAttendeeToChatUid]: string | undefined;
  [UiAction.waitRouterGuards]: void;
  [UiAction.routerGuardsFinished]: void;
}

/**
 * Defines types of all action functions in the ui store slice.
 */
type UiActionFunctions = {
  [name in keyof UiActions]: TypedAction<UiState, RootState, UiGetters, UiActions[name]>;
};

/**
 * Action method implementations in the ui store slice.
 */
export const uiActions: UiActionFunctions = {
  /**
   * Loads the ui-config.json from the static assets.
   *
   * @param commit The commit function.
   */
  async [UiAction.loadUiConfig]({ commit }): Promise<void> {
    const UI_CONFIG_FILE = "/ui-config.json";

    // load UI config from the static assets
    let uiConfig: UIConfigState;
    try {
      const axiosResponse = await axios.get(UI_CONFIG_FILE);
      uiConfig = axiosResponse.data as UIConfigState;
    } catch (error) {
      console.error(`Could not load ${UI_CONFIG_FILE}. It must be placed next to index.html`);
      throw error;
    }

    // commit loaded UI config to store
    commit(UiMutation.SET_UI_CONFIG, uiConfig);
  },

  [UiAction.setUiConfig]({ commit }, uiConfig: UIConfigState): void {
    try {
      commit(UiMutation.SET_UI_CONFIG, uiConfig);
    } catch (error) {
      console.error("There was an error while setting the UI config: ", error);
    }
  },

  /**
   * Updates the published venue version in the store to the current version in the backend.
   *
   * @param commit The commit function.
   */
  async [UiAction.loadVenueVersion]({ commit }): Promise<void> {
    try {
      const versionResponse = await VersionApi.getVenueVersion();
      commit(UiMutation.SET_VENUE_VERSION, versionResponse.data);
    } catch (error) {
      console.error("There was an error while loading the venue version: ", error);
    }
  },

  [UiAction.setVenueVersion]({ commit }, venueVersion: VenueVersion): void {
    try {
      commit(UiMutation.SET_VENUE_VERSION, venueVersion);
    } catch (error) {
      console.error("There was an error while setting the venue version: ", error);
    }
  },

  [UiAction.setLoginEnabled]({ commit }, loginEnabled: boolean): void {
    try {
      commit(UiMutation.SET_LOGIN_ENABLED, loginEnabled);
    } catch (error) {
      console.error("There was an error while enabling the login: ", error);
    }
  },

  [UiAction.setLoginConfigurations]({ commit }, loginConfigurations: LoginConfiguration[]): void {
    try {
      commit(UiMutation.SET_LOGIN_PROVIDERS, loginConfigurations);
    } catch (error) {
      console.error("There was an error while setting the login options: ", error);
    }
  },

  [UiAction.setHeaderHeight]({ commit }, height: number): void {
    try {
      commit(UiMutation.SET_HEADER_HEIGHT, height);
    } catch (error) {
      console.error("There was an error while setting header height: ", error);
    }
  },

  [UiAction.setLastAreaId]({ commit }, areaId: string): void {
    try {
      commit(UiMutation.SET_LAST_AREA_ID, areaId);
    } catch (error) {
      console.error("There was an error while setting last area id: ", error);
    }
  },

  [UiAction.clearError]({ commit }): void {
    try {
      commit(UiMutation.CLEAR_ERROR);
    } catch (error) {
      console.error("There was an error while clearing errors: ", error);
    }
  },

  [UiAction.setError]({ commit }, { code, message }: { code: ErrorCode; message: string }): void {
    try {
      commit(UiMutation.SET_ERROR, { code, message });
    } catch (error) {
      console.error("There was an error while setting an error: ", error);
    }
  },

  [UiAction.suppressDefaultErrorDialog]({ commit }): void {
    try {
      commit(UiMutation.SET_DEFAULT_DIALOG_SUPPRESSION, true);
    } catch (error) {
      console.error("There was an error while suppressing the default error dialog: ", error);
    }
  },

  [UiAction.enableDefaultErrorDialog]({ commit }): void {
    try {
      commit(UiMutation.SET_DEFAULT_DIALOG_SUPPRESSION, false);
    } catch (error) {
      console.error("There was an error while suppressing the default error dialog: ", error);
    }
  },

  [UiAction.setServerTime]({ commit }, serverTime: Date): void {
    try {
      commit(UiMutation.SET_SERVER_TIME, serverTime);
    } catch (error) {
      console.error("There was an error while setting the server time: ", error);
    }
  },

  [UiAction.serverTimeTick]({ commit, state }, clockTickInMs: number): void {
    try {
      commit(UiMutation.SET_SERVER_TIME, new Date(state.serverTime.getTime() + clockTickInMs));
    } catch (error) {
      console.error("There was an error when ticking server time: ", error);
    }
  },

  [UiAction.addToActiveDialogStack]({ commit }, dialog: Record<string, unknown>): void {
    try {
      commit(UiMutation.ADD_TO_ACTIVE_DIALOG_STACK, dialog);
    } catch (error) {
      console.error("There was an error while adding a dialog to the active dialog stack: ", error);
    }
  },

  [UiAction.removeFromActiveDialogStack]({ commit }, dialog: Record<string, unknown>): void {
    try {
      commit(UiMutation.REMOVE_FROM_ACTIVE_DIALOG_STACK, dialog);
    } catch (error) {
      console.error("There was an error while removing a dialog from the active dialog stack: ", error);
    }
  },

  [UiAction.popFromActiveDialogStack]({ commit }): void {
    try {
      commit(UiMutation.POP_FROM_ACTIVE_DIALOG_STACK);
    } catch (error) {
      console.error("There was an error while popping the last dialog from the active dialog stack: ", error);
    }
  },

  [UiAction.openChat]({ commit }): void {
    try {
      commit(UiMutation.OPEN_CHAT);
    } catch (error) {
      console.error("There was an error while opening the chat: ", error);
    }
  },

  [UiAction.closeChat]({ commit }): void {
    try {
      commit(UiMutation.CLOSE_CHAT);
    } catch (error) {
      console.error("There was an error while closing the chat: ", error);
    }
  },

  [UiAction.expandChat]({ commit }): void {
    try {
      commit(UiMutation.EXPAND_CHAT);
    } catch (error) {
      console.error("There was an error while expanding the chat: ", error);
    }
  },

  [UiAction.minimizeChat]({ commit }): void {
    try {
      commit(UiMutation.MINIMIZE_CHAT);
    } catch (error) {
      console.error("There was an error while minimizing the chat: ", error);
    }
  },

  [UiAction.openChatWithRoom]({ commit }, roomChats: { room: string; chats: string[] }): void {
    try {
      commit(UiMutation.SET_ROOM_CHATS, roomChats);
      commit(UiMutation.OPEN_CHAT);
    } catch (error) {
      console.error("There was an error while setting the chats for the room: ", error);
    }
  },

  [UiAction.addNavigationGuard]({ commit }, widgetId): void {
    try {
      commit(UiMutation.ADD_NAVIGATION_GUARD, widgetId);
    } catch (error) {
      console.error(`There was an error adding a navigation guard for widget ${widgetId}: `, error);
    }
  },

  [UiAction.removeNavigationGuard]({ commit }, widgetId): void {
    try {
      commit(UiMutation.REMOVE_NAVIGATION_GUARD, widgetId);
    } catch (error) {
      console.error(`There was an error removing a navigation guard for widget ${widgetId}: `, error);
    }
  },

  [UiAction.beginGuardedNavigation]({ commit }): void {
    try {
      commit(UiMutation.BEGIN_GUARDED_NAVIGATION);
    } catch (error) {
      console.error("There was an error beginning guarded navigation: ", error);
    }
  },

  [UiAction.completeGuardedNavigation]({ commit }): void {
    try {
      commit(UiMutation.BEGIN_GUARDED_NAVIGATION);
    } catch (error) {
      console.error("There was an error completing guarded navigation: ", error);
    }
  },

  [UiAction.setAttendeeToChatUid]({ commit }, attendeeUid: string | undefined): void {
    try {
      commit(UiMutation.SET_ATTENDEE_TO_CHAT_UID, attendeeUid);
    } catch (error) {
      console.error("There was an error setting the attendee to chat ui: ", error);
    }
  },
  [UiAction.waitRouterGuards]({ commit }): void {
    commit(UiMutation.WAIT_ROUTER_GUARDS);
  },
  [UiAction.routerGuardsFinished]({ commit }): void {
    commit(UiMutation.ROUTER_GUARDS_FINISHED);
  }
};
