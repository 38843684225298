import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_presence_widget = _resolveComponent("presence-widget")!
  const _component_mobile_presence_widget = _resolveComponent("mobile-presence-widget")!
  const _component_v_spacer = _resolveComponent("v-spacer")!

  return (_ctx.ready && !_ctx.mobile)
    ? (_openBlock(), _createBlock(_component_presence_widget, {
        key: 0,
        "attendee-list": _ctx.attendees,
        "area-id": _ctx.currentAreaId,
        "search-term": _ctx.searchTermModel,
        "onUpdate:searchTerm": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.searchTermModel) = $event)),
        "area-filter": _ctx.areaFilter,
        "onUpdate:areaFilter": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.areaFilter) = $event)),
        "company-filter": _ctx.companyFilter,
        "onUpdate:companyFilter": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.companyFilter) = $event)),
        "attendee-update-debouncer": _ctx.attendeeUpdateDebouncer,
        "is-loading-attendee-profile": _ctx.isLoadingAttendeeProfile,
        "attendee-id-to-load": _ctx.attendeeIdToLoad,
        onOpenProfileForAttendee: _ctx.openProfileForAttendee,
        onStartChat: _ctx.startChat
      }, null, 8, ["attendee-list", "area-id", "search-term", "area-filter", "company-filter", "attendee-update-debouncer", "is-loading-attendee-profile", "attendee-id-to-load", "onOpenProfileForAttendee", "onStartChat"]))
    : (_ctx.ready && _ctx.mobile)
      ? (_openBlock(), _createBlock(_component_mobile_presence_widget, {
          key: 1,
          "attendee-list": _ctx.attendees,
          "area-id": _ctx.currentAreaId,
          "search-term": _ctx.searchTermModel,
          "onUpdate:searchTerm": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.searchTermModel) = $event)),
          "area-filter": _ctx.areaFilter,
          "onUpdate:areaFilter": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.areaFilter) = $event)),
          "company-filter": _ctx.companyFilter,
          "onUpdate:companyFilter": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.companyFilter) = $event)),
          "selected-attendee-id": _ctx.selectedAttendeeId,
          onSelectAttendee: _ctx.selectAttendee
        }, null, 8, ["attendee-list", "area-id", "search-term", "area-filter", "company-filter", "selected-attendee-id", "onSelectAttendee"]))
      : (_openBlock(), _createBlock(_component_v_spacer, { key: 2 }))
}